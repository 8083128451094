import React from "react";
import "./MedicineSelectedItem.css";
import MedicineGivenForm from "../MedicineGivenForm/MedicineGivenForm";
const MedicineSelectedItem = ({
    medicine,
    visitDate,
    handleCloseModal,
    isSlaughterBan,
    isMilkBan,
    hasDateLastGiven,
    amountLabel,
    visitDetailValues
}) => {
    return (
        <div className="medicine-selected-item-container">
            <div className="medicine-selected-item-header">
                <div className="medicine-selected-item-name">
                    <b>{medicine.name}</b>
                </div>
                <div>
                    <i>
                        Styrkur: {medicine.strength}
                        {", "}
                        {medicine.strengthTypeIsName}
                        {". "}
                        Eining: {medicine.packageUnit}
                        {", "}
                        {medicine.packageUnitTypeIsName}
                    </i>
                </div>
            </div>
            <div className="medicine-selected-item-inputs">
                <MedicineGivenForm
                    medicine={medicine}
                    visitDetailValues={visitDetailValues}
                    handleCloseModal={handleCloseModal}
                    isSlaughterBan={isSlaughterBan}
                    isMilkBan={isMilkBan}
                    hasDateLastGiven={hasDateLastGiven}
                    amountLabel={amountLabel}
                    visitDate={visitDate}
                />
            </div>
        </div>
    );
};

export default MedicineSelectedItem;
