import { useState, useEffect } from "react";
import horseService from "../services/horseService";

const useCreateHorseVisit = (cb) => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [horseVisits, setHorseVisits] = useState(null);

    useEffect(() => {
        if (horseVisits && !isProcessing) {
            setProcessing(true);
            horseService
                .createHorseVisit(horseVisits)
                .then(() => {
                    setError(null);
                    cb(horseVisits[0].visitId);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setHorseVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, horseVisits, cb]);
    const handleCreateHorseVisit = (visitId, incomingVisit, visit) => {
        if (!isProcessing) {
            var vacimList = [];
            incomingVisit.forEach((horseVisit) => {
                const horsesInput = visit.horseList.map((animal) => ({
                    ormerki: animal.ormerki,
                    gripur_numer: animal.gripur_numer
                }));
                const visitHorseInputModelList = {
                    visitId: visitId,
                    horses: horsesInput,
                    slaughterBanUntil: horseVisit.highestSlaughterBanDate,
                    diseaseId: horseVisit.disease.id,
                    medicines: prepareMedicines(horseVisit.medicineList),
                    veterinarianSSN: horseVisit.veterinarianSSN,
                    isRegistrationCompleted: false
                };
                vacimList.push(visitHorseInputModelList);
            });
            setHorseVisits(vacimList);
        }
    };

    const prepareMedicines = (incomingMed) => {
        var medList = [];
        for (var i = 0; i < incomingMed.length; i++) {
            medList.push({
                medicineId: incomingMed[i].id,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleCreateHorseVisit, isProcessing };
};
export default useCreateHorseVisit;
