import { useState, useEffect } from "react";
import porcineService from "../services/porcineService";

const useUpdatePorcineVisit = () => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [porcineVisits, setPorcineVisits] = useState(null);

    useEffect(() => {
        if (porcineVisits && !isProcessing) {
            setProcessing(true);
            porcineService
                .updatePorcineVisit(porcineVisits)
                .then(() => {
                    setError(null);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setPorcineVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, porcineVisits]);

    const handleUpdatePorcineVisit = (incomingVisit) => {
        if (!isProcessing) {
            const outgoingVisit = {
                id: incomingVisit.id,
                arrangementTypeId: parseInt(incomingVisit.arrangementTypeId),
                establishmentId: incomingVisit.establishmentId,
                establishmentName: incomingVisit.establishmentName,
                establishmentSSN: incomingVisit.establishmentSSN,
                animalTypeId: incomingVisit.animalTypeId,
                veterinarianId: incomingVisit.veterinarianId,
                veterinarianSSN: incomingVisit.veterinarianSSN,
                visitDate: incomingVisit.visitDate,
                porcineVisits: preparePorcineVisits(incomingVisit)
            };
            setPorcineVisits(outgoingVisit);
        }
    };

    const preparePorcineVisits = (incomingPorcineVisits) => {
        var vpList = [];
        incomingPorcineVisits.diagnosesList.forEach((porcine) => {
            const visitPorcineInputModelList = {
                id: porcine.id,
                visitId: porcine.visitId,
                diseaseId: porcine.diseaseId,
                veterinarianSSN: porcine.veterinarianSSN,
                medicines: prepareMedicines(porcine.medicineList)
            };
            vpList.push(visitPorcineInputModelList);
        });
        return vpList;
    };

    const prepareMedicines = (incomingMed) => {
        let medList = [];
        for (let i = 0; i < incomingMed.length; i++) {
            medList.push({
                id: incomingMed[i].id,
                medicineId: incomingMed[i].medicineId,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleUpdatePorcineVisit, isProcessing };
};
export default useUpdatePorcineVisit;
