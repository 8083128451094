import React from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress
} from "@material-ui/core";
import LiveStockEstablishmentsSelectionListItem from "../LiveStockEstablishmentsSelectionListItem/LiveStockEstablishmentsSelectionListItem";
import "./LiveStockEstablishmentsSelectionList.css";

const LiveStockEstablishmentsSelectionList = ({
    establishments,
    handleEstablishmentSelection,
    searchTerm
}) => {
    return (
        <div>
            {searchTerm.length > 1 ? (
                <div>
                    {Object.keys(establishments).length > 0 ? (
                        <Table
                            stickyHeader
                            aria-label="sticky table"
                            className="live-stock-establishments-selection-list"
                            variant="head"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <b>Bú</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>Búsnúmer</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>Póstnúmer</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {establishments.map((establishment) => (
                                    <LiveStockEstablishmentsSelectionListItem
                                        key={establishment.busnumer}
                                        establishment={establishment}
                                        handleEstablishmentSelection={
                                            handleEstablishmentSelection
                                        }
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div className="live-stock-establishment-waiting-container">
                            Sæki bú ...
                            <CircularProgress />
                        </div>
                    )}
                </div>
            ) : (
                <div className="live-stock-establishment-no-text">
                    <i>Skrifa þarf minnst tvo stafi</i>
                </div>
            )}
        </div>
    );
};

export default LiveStockEstablishmentsSelectionList;
