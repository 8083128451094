import { useState, useEffect } from "react";
import petService from "../services/petService";

const useGetAllPetSpecies = () => {
    const [petSpecies, setPetSpecies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        petService
            .getAllPetSpecies()
            .then((ps) => {
                setPetSpecies(ps);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { petSpecies, isLoading, error };
};
export default useGetAllPetSpecies;
