import { useState, useEffect } from "react";
import fisheryService from "../services/fisheryService";

const useGetAllFisheries = () => {
    const [fisheries, setFisheries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        fisheryService
            .getAllFisheries()
            .then((fisheries) => {
                fisheries.map(
                    (fisheries) =>
                        (fisheries.searchKey =
                            fisheries.companyIsName +
                            " " +
                            fisheries.registrationCode)
                );
                setFisheries(fisheries);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { fisheries, isLoading, error };
};

export default useGetAllFisheries;
