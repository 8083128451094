import React from "react";
import useGetAllMedicines from "../../../../hooks/useGetAllMedicines";
import useSearchBar from "../../../../hooks/useSearchBar";
import { Modal, CircularProgress, Paper } from "@material-ui/core";
import UpdateVisitButtons from "../UpdateVisitButtons/UpdateVisitButtons";
import SearchBar from "../../../SearchBar/SearchBar";
import MedicineSelectionList from "../../MedicineSelection/MedicineSelectionList/MedicineSelectionList";
import "./UpdateVisitDiagnosesMedicineModal.css";

const UpdateVisitDiagnosesMedicineModal = ({ open, handleClose, medicine }) => {
    const { medicines, isLoading, error } = useGetAllMedicines();
    const { searchResult, searchTerm, handleChange } = useSearchBar(
        medicines,
        "name"
    );
    const handleCancel = () => {
        handleClose();
    };
    const medicinesToShow = searchResult.slice(0, 5);
    const handleSelection = (e, selectedMedicine) => {
        medicine.medicineId = selectedMedicine.id;
        medicine.medicineName = selectedMedicine.name;
        medicine.unitType = selectedMedicine.unitTypeIsName;
        handleClose();
    };
    return (
        <Modal
            className="update-visit-diagnoses-medicine-modal"
            open={open}
            onClose={handleClose}
        >
            {isLoading ? (
                <CircularProgress />
            ) : error ? (
                <>Ekki tókst að sækja lyf</>
            ) : (
                <Paper className="update-visit-diagnoses-medicine-paper">
                    <div>
                        <SearchBar
                            searchTerm={searchTerm}
                            handleChange={handleChange}
                            placeHolder="Leita að lyfi"
                        />
                    </div>
                    <div className="update-visit-diagnoses-medicine-paper-medicine-list">
                        <MedicineSelectionList
                            medicines={medicinesToShow}
                            handleSelection={handleSelection}
                        />
                    </div>
                    <UpdateVisitButtons
                        handleClose={handleClose}
                        handleCancel={handleCancel}
                    />
                </Paper>
            )}
        </Modal>
    );
};
export default UpdateVisitDiagnosesMedicineModal;
