import { SET_LOGIN_USER, CHECK_USER_LOGIN, SET_LOGOUT_USER } from "../types";

export const setLoginUser = (user) => ({
    type: SET_LOGIN_USER,
    payload: user
});

export const updateLoggedInUser = (loggedInData) => ({
    type: CHECK_USER_LOGIN,
    payload: loggedInData
});

export const setLogoutUser = () => ({
    type: SET_LOGOUT_USER,
    payload: null
});
