import { useState, useEffect } from "react";
import userService from "../services/userService";
import { useDispatch } from "react-redux";
import { setLoginUser } from "../redux/actions/userActions";

const useLogin = (successLogin) => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [loginInput, setLoginInput] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (loginInput && !isProcessing) {
            setProcessing(true);
            userService
                .login(loginInput)
                .then((data) => {
                    data.loggedIn = true;
                    data.loggedInTimeStamp = Date.now();
                    dispatch(setLoginUser(data));
                    setError(null);
                    successLogin();
                })
                .catch((error) => setError(error.message))
                .finally(() => {
                    setLoginInput(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, loginInput, successLogin, dispatch]);

    const handleLogin = (incomingLogin) => {
        if (!isProcessing) {
            setLoginInput(incomingLogin);
        }
    };
    return { error, handleLogin, isProcessing };
};
export default useLogin;
