import React, { useState } from 'react';
import "./AdminMedicineSelectionListItem.css";
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import Modal from 'react-modal';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmationButton from '../../../../Visit/ConfirmationButton/ConfirmationButton';
import AdminMedicineInfo from "../AdminMedicineInfo/AdminMedicineInfo";
import AdminMedicineInfoModalText from "../AdminMedicineInfoModalText/AdminMedicineInfoModalText";
import useDeleteMedicineById from '../../../../../hooks/useDeleteMedicineById';
import useRestoreMedicineById from '../../../../../hooks/useRestoreMedicineById';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminMedicineSelectionListItem = ({ medicine, handleSelection }) => {

    const [showModal, setShowModal] = useState(false);
    
    const handleClose = () => {
        setShowModal(false);    
    };

    const handleOpen = () => {
        setShowModal(true);  
    };
    
    const toasterTriggerDelete = () => toast.success("Lyfið hefur verið afvirkjað");
    const toasterTriggerRestore = () => toast.success("Lyfið hefur verið virkjað");

    const { handleDelete } = useDeleteMedicineById()

    const handleDeleteClick = () => {
        handleDelete(medicine.id);
        setShowModal(false);
        toasterTriggerDelete();
    };

    const { handleRestore } = useRestoreMedicineById()

    const handleRestoreClick = () => {
        handleRestore(medicine.id);
        setShowModal(false);
        toasterTriggerRestore();
    };

    return (
        <div className="admin-medicine-selection-list-item-container">
            <div>
                <AdminMedicineInfo medicine={medicine}  />
            </div>
            <div className="admin-medicine-selection-list-item-button">
                {medicine.active ? 
                    <DeleteIcon className="admin-medicine-selection-list-item-icon-delete"
                        onClick={() => handleOpen()}
                        fontSize='large'>
                    </DeleteIcon>
                :
                    <UndoIcon className="admin-medicine-selection-list-item-icon-undo"
                        onClick={() => handleOpen()}
                        fontSize='large'>
                    </UndoIcon>
                } 
                <Modal 
                    isOpen={showModal}
                    contentLabel="onRequestClose Example"
                    onRequestClose={() => handleClose()}
                    shouldCloseOnOverlayClick={true}
                    ariaHideApp={false}
                >
                    <div className="admin-medicine-selection-list-item-modal">
                        <div className="admin-medicine-selection-list-item-modal-close">
                            <CloseIcon className="admin-medicine-selection-list-item-modal-close-icon" onClick={() => handleClose()} />
                        </div>
                        <div className="admin-medicine-selection-list-item-modal-confirm">
                            <AdminMedicineInfoModalText medicine={medicine} />
                            <div className="admin-medicine-selection-list-item-modal-confirm-button">
                                {medicine.active ?
                                    <ConfirmationButton 
                                        title={"Afvirkja lyf"}
                                        handleSubmit={handleDeleteClick}
                                    />
                                :
                                    <ConfirmationButton 
                                        title={"Virkja lyf"}
                                        handleSubmit={handleRestoreClick}  
                                    />
                                } 
                            </div>
                        </div>
                    </div>
                </Modal>
                <ToastContainer 
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                /> 
            </div>
        </div>
    );
};

export default AdminMedicineSelectionListItem;