import { useState, useEffect } from "react";
import userService from "../services/userService";
import { useDispatch } from "react-redux";
import { setLogoutUser } from "../redux/actions/userActions";

const useLogout = (succesfulLogout) => {
    const [error, setError] = useState(null);
    const [logout, setLogout] = useState(false);
    const [isProcessing, setProcessing] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isProcessing && logout) {
            setProcessing(true);
            userService
                .logout()
                .then(() => {
                    dispatch(setLogoutUser());
                    setError(null);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    succesfulLogout();
                    setProcessing(false);
                    setLogout(false);
                });
        }
    }, [isProcessing, dispatch, logout, succesfulLogout]);

    const handleLogout = () => {
        setLogout(true);
    };

    return { error, isProcessing, handleLogout };
};
export default useLogout;
