import { getMonths } from "./months";

export const formatDate = (date) => {
    const months = getMonths;
    const splitYearsAndMonths = date.split("-");
    const formattedDay = splitYearsAndMonths[2].split("T")[0] + ". ";
    const formattedMonth = months[splitYearsAndMonths[1] - 1].isName + " ";
    const formattedYear = splitYearsAndMonths[0];
    return formattedDay + formattedMonth + formattedYear;
};
