import React from "react";
import "./VisitAnimalSelectionListItem.css";
import { TableRow, TableCell } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category Visit
 * @component lol
 */

const VisitAnimalSelectionListItem = ({ animal, handleAnimalSelection }) => {
    return (
        <TableRow
            className="visit-animal-selection-list-row"
            onClick={(e) => {
                handleAnimalSelection(e, animal);
            }}
        >
            <TableCell className="visit-animal-selection-list-item">
                <div className="visit-animal-selection-list-item-upper">
                    <b>{animal.nafn ? animal.nafn : "Nafn vantar"}</b>
                    {!animal.isSelected ? (
                        <CheckBoxOutlineBlankIcon className="visit-animal-selection-list-item-checkbox" />
                    ) : (
                        <CheckBoxIcon className="visit-animal-selection-list-item-checkbox" />
                    )}
                </div>
                <div>
                    <i>
                        {animal.valnumer ? animal.valnumer : "Valnúmer vantar"}
                    </i>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default VisitAnimalSelectionListItem;
