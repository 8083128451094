import React from "react";
import "./VisitViewSelectionListItem.css";
import { Link } from "react-router-dom";

const VisitViewSelectionListItem = ({ animal }) => {
    return (
        <Link className="visit-view-item" to={`/visit/${animal.english}`}>
            <div className="visit-view-list-item">
                <div className="visit-view-list-item-animal">
                    <p>{animal.animalType}</p>
                </div>
                <div className="visit-view-list-item-image">
                    <img src={animal.image} alt={animal.animalType} />
                </div>
            </div>
        </Link>
    );
};

export default VisitViewSelectionListItem;
