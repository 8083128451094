/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/medicines/`;
const getAllMedicines = () => {
    return fetch(apiEndpoint, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const getAllMedicinesForAdmin = () => {
    return fetch(apiEndpoint + "admin", {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const createMedicine = (medicine) => {
    return fetch(apiEndpoint, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(medicine),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const deleteMedicine = (id) => {
    return fetch(apiEndpoint + id, {
        method: "DELETE",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const restoreMedicine = (id) => {
    return fetch(apiEndpoint + id, {
        method: "PUT",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

export default {
    getAllMedicines,
    getAllMedicinesForAdmin,
    createMedicine,
    deleteMedicine,
    restoreMedicine
};
