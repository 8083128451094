/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import { SET_LOGIN_USER, CHECK_USER_LOGIN, SET_LOGOUT_USER } from "../types";
const initState = {
    id: null,
    name: null,
    username: null,
    isAdmin: null,
    loggedIn: null,
    loggedInTimeStamp: null
};

export default (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_LOGIN_USER: {
            return {
                ...state,
                id: payload.id,
                name: payload.name,
                username: payload.username,
                isAdmin: payload.isAdmin,
                loggedIn: payload.loggedIn,
                loggedInTimeStamp: payload.loggedInTimeStamp
            };
        }
        case CHECK_USER_LOGIN: {
            return {
                ...state,
                loggedIn: payload.loggedIn
            };
        }
        case SET_LOGOUT_USER: {
            return {
                state: null
            };
        }
        default:
            return state;
    }
};
