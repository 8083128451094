import React, { useState } from "react";
import AddDiagnosesButton from "../../Visit/AddDiagnosesButton/AddDiagnosesButton";
import { Modal } from "@material-ui/core";
import VisitTabContainer from "../../Visit/VisitTabContainer/VisitTabContainer";
import ConfirmationButton from "../../Visit/ConfirmationButton/ConfirmationButton";
import CancelButton from "../../Visit/CancelButton/CancelButton";
import DiseaseSelectionView from "../../Visit/DiseaseSelection/DiseaseSelectionView/DiseaseSelectionView";
import MedicineSelectionContainer from "../../Visit/MedicineSelection/MedicineSelectionContainer/MedicineSelectionContainer";
import AnimalSelectionHeader from "../../Visit/AnimalSelection/AnimalSelectionHeader/AnimalSelectionHeader";
import DiagnosesMedicineView from "../../Visit/DiagnosesMedicineSelection/DiagnosesMedicineView/DiagnosesMedicineView";
import useForm from "../../../hooks/useForm";
import { useSelector } from "react-redux";
import VisitPoultryValidation from "../../../validations/visitPoultryValidation";
import "./VisitPoultryDiagnosesContainer.css";
import VisitPoultrySelection from "../VisitPoultrySelection/VisitPoultrySelection";
import { visitType } from "../../../types";
import useGetAllPoultryDiseases from "../../../hooks/useGetAllPoultryDiseases";
import VisitPoultryUpdateModal from "../VisitPoultryUpdate/VisitPoultryUpdateModal/VisitPoultryUpdateModal";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category PoultryVisit
 * @component holds all the components for user to create a poultry visit
 * @param visitPoultryInitState is the init state for details about poultry visit
 */

const visitPoultryInitState = {
    animalCount: 0,
    traceabilityNumber: "",
    speciesId: "",
    veterinarianSSN: "",
    poultryType: "",
    disease: {},
    medicineList: [],
    medicineTempList: []
};

const VisitPoultryDiagnosesContainer = ({ visitValues }) => {
    /**
     * React useState hook that keeps track of when the vist poultry modal is open or closed
     */
    const [modalIsOpen, setModalIsOpen] = useState(false);
    /**
     * React useState hook that keeps track of when the vist poultry update modal is open or closed
     */
    const [updatePoultryModalIsOpen, setUpdatePoultryModalIsOpen] = useState(
        false
    );
    /**
     * React useState hook that keeps track of which tab is currently set
     */
    const [tabValue, setTabValue] = useState(0);
    /**
     * Counter that select which visit to update
     */
    const [visitToUpdateCounter, setVisitToUpdateCounter] = useState(0);
    /**
     * React useState hook that keeps track of when the add medicine modal is open or closed
     */
    const [addMedicineOpen, setAddMedicineOpen] = useState(false);
    /**
     * User information from redux store
     */
    const user = useSelector((state) => state.user);
    /**
     * Gets all poultry diseases from database
     */
    const { poultryDiseases } = useGetAllPoultryDiseases();
    /**
     * The poultry visit object fixed for the hook that sends it to backend
     */
    const getDiagnosesObject = (values) => {
        const diagnosesObject = {
            animalCount: values.animalCount,
            traceabilityNumber: values.traceabilityNumber,
            disease: values.disease,
            diseaseId: values.disease.id,
            diseaseIsName: values.disease.isName,
            diseaseEnName: values.disease.enName,
            speciesId: values.speciesId,
            speciesIsName:
                values.speciesId !== "" &&
                values.poultrySpeciesList[values.speciesId - 1].isName,
            medicineList: values.medicineList,
            veterinarianSSN: user.username
        };

        return diagnosesObject;
    };
    /**
     * Function that handles open for the add medicine modal
     */
    const handleOpenModal = () => {
        setAddMedicineOpen(true);
    };
    /**
     * Function that handles close for the add medicine modal
     */
    const handleCloseModal = () => {
        setAddMedicineOpen(false);
    };
    /**
     * The submit handler pushes the the poultry visit details to the visit object,
     * closes the modal and clears up.
     */
    const visitPoultrySubmitHandler = (values) => {
        values.medicineList.forEach((medicine) => {
            medicine.medicineName = medicine.name;
        });
        const diagnosesObject = getDiagnosesObject(values);
        visitValues.diagnosesList.push(diagnosesObject);
        setModalIsOpen(false);
        visitPoultryInitState.disease = {};
        visitPoultryInitState.medicineList = [];
        visitPoultryInitState.medicineTempList = [];
        resetFields();
        setTabValue(0);
    };
    /**
     * onError function watches which error occurs, sending the user to
     * corresponding tab
     */
    const onError = () => {
        if (
            errors.animalCount ||
            errors.traceabilityNumber ||
            errors.speciesId
        ) {
            setTabValue(0);
        } else {
            if (errors.disease) {
                setTabValue(1);
            } else {
                setTabValue(2);
            }
        }
    };
    /**
     * Hook that keeps track of when user inserts information about poultry visit
     */
    const { values, handleChange, errors, handleSubmit, resetFields } = useForm(
        visitPoultryInitState,
        VisitPoultryValidation,
        visitPoultrySubmitHandler,
        onError
    );
    /**
     * Function that handles open for the poultry visit modal
     */
    const handleOpenPoultryVisitModal = () => {
        setModalIsOpen(true);
    };
    /**
     * Function that handles close for the poultry visit modal
     */
    const handleClosePoultryVisitModal = () => {
        setModalIsOpen(false);
    };
    const handleUpdate = () => {
        setUpdatePoultryModalIsOpen(false);
    };
    /**
     * Function that handles update poultry visit
     */
    const handleOpenUpdateVisitModal = (counter) => {
        setUpdatePoultryModalIsOpen(true);
        setVisitToUpdateCounter(counter);
    };
    /**
     * Function that handles close for the update poultry visit modal
     */
    const handleCloseUpdateVisitModal = () => {
        setUpdatePoultryModalIsOpen(false);
    };
    /**
     * Function that handles tab value change
     */
    const handleTabValueChange = (event, newValue) => {
        setAddMedicineOpen(false);
        setTabValue(newValue);
    };
    return (
        <div className="visit-poultry-diagnose-container">
            <div className="visit-poultry-diagnoses-body">
                <div className="visit-poultry-diagnoses-header">
                    <AnimalSelectionHeader values={visitValues} />
                </div>
                <div>
                    <DiagnosesMedicineView
                        visitValues={visitValues}
                        handleOpenUpdateVisitModal={handleOpenUpdateVisitModal}
                    />
                </div>
                <div className="visit-poultry-add-button-area">
                    <AddDiagnosesButton
                        handleClick={handleOpenPoultryVisitModal}
                    />
                </div>
            </div>
            <Modal
                className="visit-poultry-modal"
                open={modalIsOpen}
                onClose={handleClosePoultryVisitModal}
            >
                <div className="visit-poultry-modal-container">
                    <VisitTabContainer
                        handleTabValueChange={handleTabValueChange}
                        tabValue={tabValue}
                        animalSelectionTitle="Hópar"
                        hasSelectionArea={true}
                    />
                    {tabValue === 0 && (
                        <VisitPoultrySelection
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                        />
                    )}
                    {tabValue === 1 && (
                        <DiseaseSelectionView
                            diseases={poultryDiseases}
                            visitDetailValues={values}
                            errors={errors}
                        />
                    )}
                    {tabValue === 2 && (
                        <MedicineSelectionContainer
                            visitDetailValues={values}
                            visitDate={visitValues.visitDate}
                            isSlaughterBan={false}
                            isMilkBan={false}
                            hasDateLastGiven={false}
                            amountLabel="Heildarmagn í vitjun"
                            handleOpenModal={handleOpenModal}
                            handleCloseModal={handleCloseModal}
                            addMedicineOpen={addMedicineOpen}
                            errors={errors}
                        />
                    )}
                    <div className="visit-poultry-diagnoses-button-area">
                        <CancelButton
                            disabled={addMedicineOpen}
                            handleCancel={handleClosePoultryVisitModal}
                        />
                        <ConfirmationButton
                            disabled={addMedicineOpen}
                            handleSubmit={handleSubmit}
                            title="Staðfesta Meðhöndlun"
                        />
                    </div>
                </div>
            </Modal>
            <VisitPoultryUpdateModal
                visitValues={visitValues}
                updatePoultryModalIsOpen={updatePoultryModalIsOpen}
                handleCloseUpdateVisitModal={handleCloseUpdateVisitModal}
                visitToUpdateCounter={visitToUpdateCounter}
                handleUpdate={handleUpdate}
            />
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitPoultryDiagnosesContainer.propTypes = {
    /**
     * Aqua culture visit object with diagnosesList and treatment object
     */
    visitValues: visitType
};

export default VisitPoultryDiagnosesContainer;
