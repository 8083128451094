import { useState, useEffect } from "react";
import aquaCultureService from "../services/aquaCultureService";

const useGetAllAquaCultureDiseases = () => {
    const [aquaCultureDiseases, setAquaCultureDiseases] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        aquaCultureService
            .getAllAquaCultureDiseases()
            .then((aquaCultureDiseases) => {
                setAquaCultureDiseases(aquaCultureDiseases);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { aquaCultureDiseases, isLoading, error };
};
export default useGetAllAquaCultureDiseases;
