import { useState, useEffect } from "react";
import fisheryService from "../services/fisheryService";

const useGetFisheryById = (id) => {
    const [fishery, setFishery] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        fisheryService
            .getFisheryById(id)
            .then((fishery) => {
                setFishery(fishery);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, [id]);
    return { fishery, isLoading, error };
};

export default useGetFisheryById;
