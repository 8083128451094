import React, { useState } from "react";
import useForm from "../../../../hooks/useForm";
import {
    InputLabel,
    Input,
    InputAdornment,
    Button,
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import "./AdminCreateUserForm.css";
import createUserValidation from "../../../../validations/createUserValidation";
import useCreateUser from "../../../../hooks/useCreateUser";

const createUserInit = {
    username: "",
    name: "",
    password: "",
    passwordConfirmation: "",
    isAdmin: false
};

const AdminCreateUserForm = () => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const onError = () => {
        setError(true);
        setSuccess(false);
    };
    const onSuccess = () => {
        resetFields();
        setSuccess(true);
        setError(false);
    };
    const { handleCreateUser } = useCreateUser(onSuccess, onError);
    const {
        values,
        handleChange,
        handleSubmit,
        handleCheckBoxChange,
        errors,
        resetFields
    } = useForm(createUserInit, createUserValidation, handleCreateUser);

    return (
        <div className="admin-create-user-form-container">
            <div className="admin-create-user-item">
                <InputLabel className="input-form-label">
                    {errors.name ? errors.name : "Fornafn"}
                </InputLabel>
                <Input
                    className="admin-create-user-input"
                    value={values.name}
                    onChange={handleChange}
                    error={errors.name !== undefined}
                    name="name"
                    startAdornment={
                        <InputAdornment position="start">
                            <AccountCircleIcon />
                        </InputAdornment>
                    }
                />
            </div>
            <div className="admin-create-user-item">
                <InputLabel className="input-form-label">
                    {errors.username ? errors.username : "Kennitala"}
                </InputLabel>
                <Input
                    className="admin-create-user-input"
                    value={values.username}
                    error={errors.username !== undefined}
                    onChange={handleChange}
                    name="username"
                    startAdornment={
                        <InputAdornment position="start">
                            <AccountCircleIcon />
                        </InputAdornment>
                    }
                />
            </div>
            <div className="admin-create-user-item">
                <InputLabel className="input-form-label">
                    {errors.password ? errors.password : "Lykilorð"}
                </InputLabel>
                <Input
                    className="admin-create-user-input"
                    value={values.password}
                    error={errors.password !== undefined}
                    onChange={handleChange}
                    name="password"
                    startAdornment={
                        <InputAdornment position="start">
                            <VpnKeyIcon />
                        </InputAdornment>
                    }
                />
            </div>
            <div className="admin-create-user-item">
                <InputLabel className="input-form-label">
                    {errors.passwordConfirmation
                        ? errors.passwordConfirmation
                        : "Lykilorð aftur"}
                </InputLabel>
                <Input
                    className="admin-create-user-input"
                    value={values.passwordConfirmation}
                    error={errors.passwordConfirmation !== undefined}
                    onChange={handleChange}
                    name="passwordConfirmation"
                    startAdornment={
                        <InputAdornment position="start">
                            <VpnKeyIcon />
                        </InputAdornment>
                    }
                />
            </div>
            <div className="admin-create-user-item">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.isAdmin}
                            onChange={handleCheckBoxChange}
                            name="isAdmin"
                            color="secondary"
                        />
                    }
                    label="Kraft Notandi"
                />
            </div>
            <div className="create-user-button-container admin-create-user-item">
                <Button className="create-user-button" onClick={handleSubmit}>
                    Skrá nýjan notanda
                </Button>
            </div>
            {success && (
                <Alert severity="success" className="create-user-success-alert">
                    Nýskráning notanda tókst
                </Alert>
            )}
            {error && (
                <Alert severity="error" className="create-user-error-alert">
                    Nýskráning tókst ekki
                </Alert>
            )}
        </div>
    );
};

export default AdminCreateUserForm;
