import React from "react";
import "./AdminManagementView.css";
import AdminManagementViewSelectionList from "../AdminManagementViewSelectionList/AdminManagementViewSelectionList";

const AdminManagementView = () => {
    return (
        <div className="admin-management-container">
            <h2 className="admin-management-view-title">Stjórnborð</h2>
            <AdminManagementViewSelectionList />
        </div>
    );
};

export default AdminManagementView;