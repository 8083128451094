import React from "react";
import { Modal, Paper, FormGroup, FormControlLabel, Checkbox} from "@material-ui/core";
import "./VisitAnimalSelectionFilterModal.css";


const VisitAnimalSelectionFilterModal = ({
    open, handleClose, showValues, handleCheckChange
}) => {
    const currentYear = new Date().getFullYear();
    return (
        <Modal
            className="visit-animal-selection-filter-modal"
            open={open}
            onClose={handleClose}
        >
            <Paper className="visit-animal-selection-filter-modal-paper">
                <b>Síja fyrir gripalista</b>
                <FormGroup>
                    <FormControlLabel 
                        control={
                            <Checkbox  
                                checked={showValues.kk} 
                                onChange={(e) => handleCheckChange(e)}
                                style ={{color: "#8CBED6"}}
                            />
                        } 
                        label="Sýna kk" 
                        name="kk" 
                    />
                    <FormControlLabel 
                        control={
                            <Checkbox 
                            checked={showValues.kvk} 
                            onChange={(e) => handleCheckChange(e)}
                            style ={{color: "#8CBED6"}}
                            />
                        }
                        label="Sýna kvk" 
                        name="kvk"
                    />
                    <FormControlLabel 
                        control={
                            <Checkbox checked={showValues.youngOne} 
                            onChange={(e) => handleCheckChange(e)}
                            style ={{color: "#8CBED6"}}
                            />
                        } 
                        label={`Sýna fædda ${currentYear}`}
                        name="youngOne"
                    />
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                checked={showValues.oldOne} 
                                onChange={(e) => handleCheckChange(e)}
                                style ={{color: "#8CBED6"}}
                            />
                        } 
                        label={`Sýna eldri en ${currentYear}`} 
                        name="oldOne"
                    />
                </FormGroup>
            </Paper>
        </Modal>
    );
};
export default VisitAnimalSelectionFilterModal;