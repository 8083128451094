import { useState, useEffect } from "react";

const useLiveStockSearchBar = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [timeToCall, setTimeToCall] = useState(false);
    const handleSearchBarChange = (e) => {
        setSearchTerm(e.target.value);
    };
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setTimeToCall(true);
        }, 1000);

        return () => {
            clearTimeout(delayDebounceFn);
            setTimeToCall(false);
        };
    }, [searchTerm]);
    return { handleSearchBarChange, searchTerm, timeToCall };
};

export default useLiveStockSearchBar;
