import React, { useState } from "react";
import { MenuList, MenuItem } from "@material-ui/core";
import "./SideBarList.css";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { useLocation, Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import icelandic from "../../../images/iceland.jpg";
import english from "../../../images/english.jpg";

import {
    setLanguageToIcelandic,
    setLanguageToEnglish
} from "../../../redux/actions/languageActions";
import useLogout from "../../../hooks/useLogout";

const SideBarList = ({ toggleSideBar }) => {
    const [logout, setLogout] = useState(false);
    const router = useLocation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const logoutCB = () => {
        setLogout(true);
        toggleSideBar();
    };

    const { isProcessing, handleLogout } = useLogout(logoutCB);

    return (
        <div className="sidebar-item-container">
            {logout && !isProcessing && <Redirect to="/login" />}
            <MenuList className="sidebar-items-list" onClick={toggleSideBar}>
                <MenuItem className="sidebar-flag-container">
                    <img
                        className="sidebar-flag"
                        src={icelandic}
                        alt="is"
                        onClick={() => dispatch(setLanguageToIcelandic())}
                    />
                    <img
                        className="sidebar-flag"
                        src={english}
                        alt="en"
                        onClick={() => dispatch(setLanguageToEnglish())}
                    />
                </MenuItem>
                <Link className="sidebar-item" to="/visit">
                    <MenuItem selected={router.pathname === "/visit"}>
                        Vitjanir
                    </MenuItem>
                </Link>
                <Link className="sidebar-item" to="/uservisits">
                    <MenuItem selected={router.pathname === "/uservisits"}>
                        Mínar Vitjanir
                    </MenuItem>
                </Link>
                {user.isAdmin && (
                    <Link className="sidebar-item" to="/admin">
                        <MenuItem selected={router.pathname === "/admin"}>
                            Admin
                        </MenuItem>
                    </Link>
                )}
                <Link
                    className="sidebar-item"
                    onClick={(e) => {
                        window.location.href = "mailto:heilsa@mast.is";
                    }}
                >
                    <MenuItem>Ábendingar</MenuItem>
                </Link>
            </MenuList>
            <MenuList className="sidebar-user-list sidebar-user-bottom-list">
                <div className="sidebar-user-item sidebar-user-name">
                    {user.name}
                </div>
                <div className="sidebar-user-logout" onClick={handleLogout}>
                    <div className="sidebar-user-item logout-text">Skrá út</div>
                    <PowerSettingsNewIcon className="sidebar-user-item logout-icon" />
                </div>
            </MenuList>
        </div>
    );
};

export default SideBarList;
