import React, { useState } from "react";
import moment from "moment";
import MedicineSelectionView from "../MedicineSelectionView/MedicineSelectionView";
import MedicineSelectedItem from "../MedicineSelectedItem/MedicineSelectedItem";
import { Button } from "@material-ui/core";
import "./AddMedicineModal.css";

const AddMedicineModal = ({
    visitDetailValues,
    handleCloseModal,
    isSlaughterBan,
    isMilkBan,
    hasDateLastGiven,
    amountLabel,
    visitDate
}) => {
    const [selected, setSelected] = useState(false);
    const selectedCallBack = (medicine) => {
        visitDetailValues.dateLastGiven = visitDate;
        var slaughterBanDate = new Date(visitDate);
        if (isSlaughterBan) {
            slaughterBanDate.setDate(
                slaughterBanDate.getDate() + medicine.slaughterBanDays
            );
            visitDetailValues.slaughterBanDate = moment(
                slaughterBanDate
            ).format("YYYY-MM-DD");
            visitDetailValues.slaughterBanDays = medicine.slaughterBanDays;
        }
        var milkBanDate = new Date(visitDate);
        if (isMilkBan) {
            milkBanDate.setDate(milkBanDate.getDate() + medicine.milkBanDays);
            visitDetailValues.milkBanDate = moment(milkBanDate).format(
                "YYYY-MM-DD"
            );
            visitDetailValues.milkBanDays = medicine.milkBanDays;
        }
        setSelected(true);
    };
    return (
        <div>
            {!selected ? (
                <MedicineSelectionView
                    visitDetailValues={visitDetailValues}
                    selectedCallBack={selectedCallBack}
                />
            ) : (
                <MedicineSelectedItem
                    medicine={
                        visitDetailValues.medicineTempList[
                            visitDetailValues.medicineTempList.length - 1
                        ]
                    }
                    visitDetailValues={visitDetailValues}
                    visitDate={visitDate}
                    handleCloseModal={handleCloseModal}
                    isSlaughterBan={isSlaughterBan}
                    isMilkBan={isMilkBan}
                    hasDateLastGiven={hasDateLastGiven}
                    amountLabel={amountLabel}
                />
            )}
            <div className="add-medicine-close-modal-button-area">
                <Button
                    className="add-medicine-close-modal-button"
                    variant="contained"
                    size="large"
                    onClick={handleCloseModal}
                >
                    <b>Hætta við</b>
                </Button>
            </div>
        </div>
    );
};

export default AddMedicineModal;
