import { useState, useEffect } from "react";
import userService from "../services/visitService";

const useGetVisitById = (id) => {
    const [visit, setVisit] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        userService
            .getVisitById(id)
            .then((incomingVisit) => {
                setVisit(JSON.parse(incomingVisit));
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, [id]);
    return { visit, isLoading, error };
};
export default useGetVisitById;
