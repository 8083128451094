import { useState, useEffect } from "react";
import medicineService from "../services/medicineService";

const useRestoreMedicineById = () => {
    const [id, setId] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (id && !isProcessing) {
        setIsProcessing(true);
        medicineService
            .restoreMedicine(id)
            .then(() => {
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsProcessing(false);
                setId(null);
            });
        }
    }, [id, isProcessing]);

    const handleRestore = (id) => {
        setId(id);
    }

    return { handleRestore, isProcessing, error };

};
export default useRestoreMedicineById;