import { useState, useEffect } from "react";
import porcineService from "../services/porcineService";

const useGetAllPorcineDiseases = () => {
    const [porcineDiseases, setPorcineDiseases] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        porcineService
            .getAllPorcineDiseases()
            .then((porcineDiseases) => {
                setPorcineDiseases(porcineDiseases);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { porcineDiseases, isLoading, error };
};
export default useGetAllPorcineDiseases;
