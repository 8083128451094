import React from "react";
import "./AdminManagementViewSelectionListItem.css";
import { Link } from "react-router-dom";

const AdminManagementViewSelectionListItem = ({ item }) => {
    return (
        <Link className="admin-management-view-item" to={`/admin/${item.pathName}`}>
            <p>{item.itemType}</p>
        </Link>
    );
};

export default AdminManagementViewSelectionListItem;
