import { createStore } from "redux";
import reducers from "../redux/reducers";

const saveToLocalStorage = (userState) => {
    try {
        const serializedUserState = JSON.stringify(userState);
        localStorage.setItem("user", serializedUserState);
    } catch (e) {}
};

const loadFromLocalStorage = () => {
    try {
        const serializedUserState = localStorage.getItem("user");
        if (!serializedUserState) {
            return undefined;
        }
        return JSON.parse(serializedUserState);
    } catch (e) {
        return undefined;
    }
};

const persistedState = loadFromLocalStorage();

const store = createStore(reducers, persistedState);

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
