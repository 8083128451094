import React from "react";
import AdminDropDownListItem from "../AdminDropDownListItem/AdminDropDownListItem";
const AdminDropDownList = ({ items, values, handleChange }) => {

    return (
        <>
            {items.map((item, index) => (
                <div className="add-medicine-item" key={index}>
                    <AdminDropDownListItem
                        item={item}
                        values={values}
                        handleChange={handleChange}
                    />
                </div>
            ))}
        </>
    );
};

export default AdminDropDownList;
