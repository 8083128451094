import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Typography
} from "@material-ui/core";
import MedicineSelectionTableItem from "../MedicineSelectionTableItem/MedicineSelectionTableItem";
import "./MedicineSelectionTable.css";

const MedicineSelectionTable = ({ items }) => {
    const [listCount, setListCount] = useState(items.length);
    const handleRemove = (itemId) => {
        var index = items.findIndex((x) => x.id === itemId);
        items.splice(index, 1);
        setListCount(items.length);
    };
    return (
        <>
            {listCount > 0 ? (
                <>
                    <Typography className="medicine-selection-table-title">
                        Skráð lyf
                    </Typography>
                    <Table className="selection-table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <b>Nafn</b>
                                </TableCell>
                                <TableCell>Eining</TableCell>
                                <TableCell>Heildarmagn</TableCell>
                                <TableCell>Fjarlægja</TableCell>
                            </TableRow>
                        </TableHead>
                        {items && (
                            <TableBody>
                                {items.map((i) => (
                                    <MedicineSelectionTableItem
                                        item={i}
                                        key={i.id}
                                        handleRemove={handleRemove}
                                    />
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </>
            ) : (
                <Typography>... enginn skráð lyf eins og er</Typography>
            )}
        </>
    );
};

export default MedicineSelectionTable;
