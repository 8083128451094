import { useState, useEffect } from "react";
import sheepService from "../services/sheepService";

const useCreateSheepVisit = (cb) => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [sheepVisits, setSheepVisits] = useState(null);

    useEffect(() => {
        if (sheepVisits && !isProcessing) {
            setProcessing(true);
            sheepService
                .createSheepVisit(sheepVisits)
                .then(() => {
                    setError(null);
                    cb(sheepVisits[0].visitId);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setSheepVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, sheepVisits, cb]);

    const handleCreateSheepVisit = (visitId, incomingVisit) => {
        if (!isProcessing) {
            var vacimList = [];
            incomingVisit.forEach((sheepVisit) => {
                const visitSheepInputModelList = {
                    visitId: visitId,
                    sheeps: sheepVisit.animalIdList.map((animalId) =>
                        animalId.toString()
                    ),
                    slaughterBanUntil: sheepVisit.highestSlaughterBanDate,
                    diseaseId: sheepVisit.disease.id,
                    medicines: prepareMedicines(sheepVisit.medicineList),
                    veterinarianSSN: sheepVisit.veterinarianSSN
                };
                vacimList.push(visitSheepInputModelList);
            });
            setSheepVisits(vacimList);
        }
    };

    const prepareMedicines = (incomingMed) => {
        var medList = [];
        for (var i = 0; i < incomingMed.length; i++) {
            medList.push({
                medicineId: incomingMed[i].id,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleCreateSheepVisit, isProcessing };
};
export default useCreateSheepVisit;
