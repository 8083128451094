import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import "./UserVisitsListItem.css";
import { formatDate } from "../../../helpers/formatDateHelper";

const UserVisitsListItem = ({ visit, handleVisitClick }) => {
    const formattedDate = formatDate(visit.visitDate);

    return (
        <TableRow
            className="user-visit-list-item"
            onClick={() => handleVisitClick(visit)}
        >
            <TableCell>{visit.establishmentName}</TableCell>
            <TableCell>{visit.animalType}</TableCell>
            <TableCell>{formattedDate}</TableCell>
        </TableRow>
    );
};

export default UserVisitsListItem;
