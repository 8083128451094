/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import { SET_RML_TOKEN_INFO } from "../types";

const rmlTokenInit = {
    accessToken: null,
    tokenType: null,
    expiresIn: null
};

export default (state = rmlTokenInit, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_RML_TOKEN_INFO: {
            return {
                ...state,
                accessToken: payload.access_token,
                tokenType: payload.token_type,
                expiresIn: payload.expires_in
            };
        }
        default:
            return state;
    }
};
