import React from "react";
import { Modal, Paper } from "@material-ui/core";
import "./UpdateAquaCultureInputModal.css";
import VisitAquaCultureInput from "../../VisitAquaCultureInput/VisitAquaCultureInput";
import UpdateVisitButtons from "../../../Visit/UpdateVisit/UpdateVisitButtons/UpdateVisitButtons";
import {
    aquaCultureUpdateDiagnoses,
    boolType,
    funcType,
    unhandledInputType,
    stringType
} from "../../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category AquaCultureVisit
 * @component is a general component for each input that user can change
 *            in update aqua culture visit
 */

const UpdateAquaCultureInputModal = ({
    visit,
    open,
    handleClose,
    unhandledInput,
    label,
    name
}) => {
    /**
     * Handle cancel function, resets input if user cancels change
     */
    const handleCancel = () => {
        visit[name] = unhandledInput;
        handleClose();
    };
    const errors = {
        animalCount: undefined,
        areaSize: undefined,
        biomass: undefined,
        temperature: undefined
    };
    return (
        <Modal
            className="update-visit-input-modal"
            open={open}
            onClose={handleClose}
        >
            <Paper className="update-visit-input-paper">
                <VisitAquaCultureInput
                    label={label}
                    name={name}
                    values={visit}
                    errors={errors}
                />
                <UpdateVisitButtons
                    handleClose={handleClose}
                    handleCancel={handleCancel}
                />
            </Paper>
        </Modal>
    );
};

/**
 * This React app has generalized Proptypes
 */
UpdateAquaCultureInputModal.propTypes = {
    /**
     * Aqua culture visit object with diagnosesList and treatment object
     */
    visit: aquaCultureUpdateDiagnoses,
    /**
     * Boolean keeps track of is modal is open
     */
    open: boolType.isRequired,
    handleClose: funcType,
    /**
     * Saved unhandled input variable for when user cancels change
     */
    unhandledInput: unhandledInputType,
    /**
     * Label prop for reusability
     */
    label: stringType.isRequired,
    /**
     * Name prop for reusability
     */
    name: stringType.isRequired
    /**
     * Type prop for reusability
     */
};

export default UpdateAquaCultureInputModal;
