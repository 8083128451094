import { useState, useEffect } from "react";
import visitService from "../services/visitService";
import useCreateAquaCultureVisit from "./useCreateAquaCultureVisit";
import useCreatePoultryVisit from "./useCreatePoultryVisit";
import useCreatePorcineVisit from "./useCreatePorcineVisit";
import useCreateCattleVisit from "./useCreateCattleVisit";
import useCreateSheepVisit from "./useCreateSheepVisit";
import useCreateGoatVisit from "./useCreateGoatVisit";
import useCreateFurAnimalVisit from "./useCreateFurAnimalVisit";
import useCreateHorseVisit from "./useCreateHorseVisit";

const useCreateVisit = (cb) => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [visit, setVisit] = useState(null);
    const [visitWithChildVisits, setVisitWithChildVisits] = useState(null);
    const { handleCreateAquaCultureVisit } = useCreateAquaCultureVisit(cb);
    const { handleCreatePoultryVisit } = useCreatePoultryVisit(cb);
    const { handleCreatePorcineVisit } = useCreatePorcineVisit(cb);
    const { handleCreateCattleVisit } = useCreateCattleVisit(cb);
    const { handleCreateSheepVisit } = useCreateSheepVisit(cb);
    const { handleCreateGoatVisit } = useCreateGoatVisit(cb);
    const { handleCreateFurAnimalVisit } = useCreateFurAnimalVisit(cb);
    const { handleCreateHorseVisit } = useCreateHorseVisit(cb);

    useEffect(() => {
        if (visit && !isProcessing) {
            setProcessing(true);
            visitService
                .createVisit(visit)
                .then((data) => {
                    if (visitWithChildVisits.animalTypeId === 1) {
                        handleCreateAquaCultureVisit(
                            data.visitId,
                            visitWithChildVisits.diagnosesList
                        );
                    } else if (visitWithChildVisits.animalTypeId === 2) {
                        handleCreatePoultryVisit(
                            data.visitId,
                            visitWithChildVisits.diagnosesList
                        );
                    } else if (visitWithChildVisits.animalTypeId === 3) {
                        handleCreatePorcineVisit(
                            data.visitId,
                            visitWithChildVisits.diagnosesList
                        );
                    } else if (visitWithChildVisits.animalTypeId === 4) {
                        handleCreateCattleVisit(
                            data.visitId,
                            visitWithChildVisits.diagnosesList
                        );
                    } else if (visitWithChildVisits.animalTypeId === 5) {
                        handleCreateSheepVisit(
                            data.visitId,
                            visitWithChildVisits.diagnosesList
                        );
                    } else if (visitWithChildVisits.animalTypeId === 6) {
                        handleCreateHorseVisit(
                            data.visitId,
                            visitWithChildVisits.diagnosesList,
                            visitWithChildVisits
                        );
                    } else if (visitWithChildVisits.animalTypeId === 7) {
                        handleCreateGoatVisit(
                            data.visitId,
                            visitWithChildVisits.diagnosesList
                        );
                    } else if (visitWithChildVisits.animalTypeId === 8) {
                        handleCreateFurAnimalVisit(
                            data.visitId,
                            visitWithChildVisits.diagnosesList
                        );
                    }
                    setError(null);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setVisit(null);
                    setProcessing(false);
                });
        }
    }, [
        isProcessing,
        visit,
        visitWithChildVisits,
        handleCreateAquaCultureVisit,
        handleCreatePoultryVisit,
        handleCreatePorcineVisit,
        handleCreateCattleVisit,
        handleCreateSheepVisit,
        handleCreateGoatVisit,
        handleCreateFurAnimalVisit,
        handleCreateHorseVisit
    ]);

    const handleCreateVisit = (incomingVisit) => {
        if (!isProcessing) {
            const visitInputModel = {
                arrangementTypeId: parseInt(incomingVisit.arrangementTypeId),
                establishmentId: incomingVisit.establishmentId,
                establishmentName: incomingVisit.establishmentName,
                establishmentSSN: incomingVisit.establishmentSSN,
                animalTypeId: incomingVisit.animalTypeId,
                veterinarianId: incomingVisit.veterinarianId,
                veterinarianSSN: incomingVisit.veterinarianSSN,
                visitDate: incomingVisit.visitDate
            };
            setVisit(visitInputModel);
            setVisitWithChildVisits(incomingVisit);
        }
    };
    return { error, handleCreateVisit, isProcessing };
};
export default useCreateVisit;
