import React from "react";
import MedicineSelectionListItem from "../MedicineSelectionListItem/MedicineSelectionListItem";

const MedicineSelectionList = ({ medicines, handleSelection }) => {
    return (
        <div>
            {medicines.map((med, i) => (
                <MedicineSelectionListItem
                    medicine={med}
                    handleSelection={handleSelection}
                    key={i}
                />
            ))}
        </div>
    );
};
export default MedicineSelectionList;
