import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { floatInputHelper } from "../../../helpers/floatInputHelper";
import { aquaCultureDiagnoses, stringType, errorsType } from "../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category AquaCultureVisit
 * @component is a general component for each input that user can change
 *            in create/update aqua culture visit
 */

const VisitAquaCultureInput = ({ label, name, values, errors }) => {
    const [reload, setReload] = useState(false);

    const handleChange = (e) => {
        var input = floatInputHelper(e.target.value);
        if (input !== null) {
            if (name !== "temperature" && input >= 0) {
                values[name] = input;
                setReload(!reload);
            } else if (name === "temperature") {
                values[name] = input;
                setReload(!reload);
            }
        }
    };
    return (
        <>
            <TextField
                label={
                    values[name] > 0 || errors[name] === undefined
                        ? label
                        : errors[name]
                }
                type="text"
                name={name}
                onChange={handleChange}
                value={values[name]}
                error={values[name] < 1 && errors[name] !== undefined}
            />
        </>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitAquaCultureInput.propTypes = {
    /**
     * Aqua culture visit details object
     */
    values: aquaCultureDiagnoses,
    /**
     * Label prop for reusability
     */
    label: stringType.isRequired,
    /**
     * Name prop for reusability
     */
    name: stringType.isRequired,
    errors: errorsType
};

export default VisitAquaCultureInput;
