import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import "./EstablishmentsSelectionListItem.css";

const EstablishmentsSelectionListItem = ({
    establishment,
    handleEstablishmentSelection,
    hasType
}) => {
    return (
        <TableRow
            className="establishments-table-row"
            onClick={(e) => {
                handleEstablishmentSelection(e, establishment);
            }}
        >
            <TableCell>{establishment.companyIsName}</TableCell>
            <TableCell align="right">
                {establishment.registrationCode}
            </TableCell>
            {hasType && <TableCell>{establishment.typeName}</TableCell>}
        </TableRow>
    );
};

export default EstablishmentsSelectionListItem;
