import React from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress
} from "@material-ui/core";
import VisitHorseSelectionListItem from "../VisitHorseSelectionListItem/VisitHorseSelectionListItem";
import "./VisitHorseSelectionList.css";

const VisitHorseSelectionList = ({ horses, searchTerm }) => {
    return (
        <div>
            {searchTerm.length > 1 ? (
                <div>
                    {Object.keys(horses).length > 0 ? (
                        <Table
                            stickyHeader
                            aria-label="sticky table"
                            className="visit-horse-selection-list"
                            variant="head"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <b>Nafn</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>Örmerki</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {horses.map((horse) => (
                                    <VisitHorseSelectionListItem
                                        key={horse.ormerki}
                                        horse={horse}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div className="visit-horse-waiting-container">
                            Sæki gripalista ...
                            <CircularProgress />
                        </div>
                    )}
                </div>
            ) : (
                <div className="visit-horse-no-text">
                    <i>Skrifa þarf minnst eitt örmerki</i>
                </div>
            )}
        </div>
    );
};

export default VisitHorseSelectionList;
