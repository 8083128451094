import React from "react";
import { animalArray } from "./VisitAnimalData";
import VisitViewSelectionListItem from "../VisitViewSelectionListItem/VisitViewSelectionListItem";
import "./VisitViewSelectionList.css";

const VisitViewSelectionList = () => {
    return (
        <div className="visit-selection-list">
            {animalArray.map((animal) => (
                <VisitViewSelectionListItem
                    key={animal.animalType}
                    animal={animal}
                />
            ))}
        </div>
    );
};

export default VisitViewSelectionList;
