import React from "react";
import { Paper } from "@material-ui/core";
import "./VisitPetSelection.css";
import VisitPetInput from "../VisitPetInput/VisitPetInput";
import useGetAllPetSpecies from "../../../hooks/useGetAllPetSpecies";
import VisitPetSelectList from "../VisitPetSelectList/VisitPetSelectList";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category PetVisit
 * @component holds selection view for pet species
 */

const VisitPetSelection = ({ values, handleChange, errors }) => {
    /**
     * Hook that gets all pet species from the database
     */
    const { petSpecies } = useGetAllPetSpecies();
    values.petSpeciesList = petSpecies;
    return (
        <Paper className="pet-selection-container-paper">
            <div className="pet-selection-container-upper">
                <VisitPetSelectList
                    values={values}
                    handleChange={handleChange}
                    species={petSpecies}
                    errors={errors}
                />
                <VisitPetInput
                    label="Fjöldi dýra"
                    type="text"
                    name="animalCount"
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                />
            </div>
        </Paper>
    );
};

export default VisitPetSelection;
