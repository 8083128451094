/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/goatvisits`;

const getAllGoatDiseases = () => {
    return fetch(apiEndpoint + "/diseases", {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const createGoatVisit = (goatVisit) => {
    return fetch(apiEndpoint, {
        method: "POST",
        body: JSON.stringify(goatVisit),
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const updateGoatVisit = (goatVisit) => {
    return fetch(apiEndpoint, {
        method: "PUT",
        body: JSON.stringify(goatVisit),
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

export default {
    getAllGoatDiseases,
    createGoatVisit,
    updateGoatVisit
};
