import React from "react";
import "./VisitAquaCultureConfirmedInfo.css";
import { Button, CircularProgress } from "@material-ui/core";
import { visitType } from "../../../types";
import { useHistory } from "react-router-dom";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category AquaCultureVisit
 * @component has confirmed information about the general visit and its sub visit,
 *            in this case aqua culture visit. This is the last component in the create
 *            visit process and shows the information that the user is sending to
 *            database.
 */

const VisitAquaCultureConfirmedInfo = ({ visit }) => {
    /**
     * hasLoaded boolean checks if visit is exists before showing the information
     */
    const hasLoaded = visit !== null;
    /**
     * React history hook, used to redirect when back button is clicked
     */
    const history = useHistory();
    /**
     * fixDate makes the Date user friendly
     */
    const fixDate = (date) => {
        var fixedDateArray = date.split("-");
        var fixDateString =
            fixedDateArray[2] +
            "." +
            fixedDateArray[1] +
            "." +
            fixedDateArray[0];
        return fixDateString;
    };
    return (
        <>
            {hasLoaded ? (
                <div className="visit-aquaculture-confirmed-info-container">
                    <div className="visit-aquaculture-confirmed-info-upper">
                        <div>
                            Vitjanir þínar hjá <b>{visit.establishmentName} </b>
                            hafa verið skráðar þann
                            <b> {fixDate(visit.visitDate)}</b>
                        </div>
                    </div>
                    <div className="visit-aquaculture-confirmed-info-buttons">
                        <Button
                            className="visit-aquaculture-confirmed-button"
                            variant="outlined"
                            size="small"
                            onClick={() => history.push("/uservisits")}
                        >
                            <b>Mínar vitjanir</b>
                        </Button>
                        <Button
                            className="visit-aquaculture-confirmed-button"
                            variant="outlined"
                            size="small"
                            href="/visit"
                        >
                            <b>Skrá nýja vitjun</b>
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="visit-aqua-confirmed-loading">
                    <CircularProgress />
                </div>
            )}
        </>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitAquaCultureConfirmedInfo.propTypes = {
    /**
     * Aqua culture visit object with diagnosesList and treatment object
     */
    visit: visitType
};

export default VisitAquaCultureConfirmedInfo;
