import { useState, useEffect } from "react";
import medicineService from "../services/medicineService";

const useCreateMedicine = () => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [medicine, setMedicine] = useState(null);

    useEffect(() => {
        if (medicine && !isProcessing) {
            setProcessing(true);
            medicineService
                .createMedicine(medicine)
                .then(() => {
                    setError(null);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setMedicine(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, medicine]);

    const handleCreate = (medicine) => {
        if (!isProcessing) {
            medicine.isAntibiotic =
                medicine.isAntibiotic === "0" ? false : true;
            medicine.isMycobacteriumVaccine =
                medicine.isMycobacteriumVaccine === "0" ? false : true;
            medicine.isVetOnlyAdminister =
                medicine.isVetOnlyAdminister === "0" ? false : true;
            medicine.isBannedForFarmedAnimal =
                medicine.isBannedForFarmedAnimal === "0" ? false : true;
            medicine.isNotForHorses =
                medicine.isNotForHorses === "0" ? false : true;
            medicine.slaughterBanDays = parseInt(medicine.slaughterBanDays);
            medicine.competitionBanDays = parseInt(medicine.competitionBanDays);
            medicine.milkBanDays = parseInt(medicine.milkBanDays);
            medicine.strength = parseFloat(medicine.strength);
            medicine.packageUnit = parseFloat(medicine.packageUnit);
            setMedicine(medicine);
        }
    };
    return { error, handleCreate, isProcessing };
};
export default useCreateMedicine;
