import React, { useState } from "react";
import AddDiagnosesButton from "../../Visit/AddDiagnosesButton/AddDiagnosesButton";
import { Modal } from "@material-ui/core";
import VisitTabContainer from "../../Visit/VisitTabContainer/VisitTabContainer";
import ConfirmationButton from "../../Visit/ConfirmationButton/ConfirmationButton";
import CancelButton from "../../Visit/CancelButton/CancelButton";
import DiseaseSelectionView from "../../Visit/DiseaseSelection/DiseaseSelectionView/DiseaseSelectionView";
import MedicineSelectionContainer from "../../Visit/MedicineSelection/MedicineSelectionContainer/MedicineSelectionContainer";
import AnimalSelectionHeader from "../../Visit/AnimalSelection/AnimalSelectionHeader/AnimalSelectionHeader";
import DiagnosesMedicineView from "../../Visit/DiagnosesMedicineSelection/DiagnosesMedicineView/DiagnosesMedicineView";
import useForm from "../../../hooks/useForm";
import { useSelector } from "react-redux";
import "./VisitCattleDiagnosesContainer.css";
import { visitType } from "../../../types";
import useGetAllCattleDiseases from "../../../hooks/useGetAllCattleDiseases";
import VisitCattleValidation from "../../../validations/visitCattleValidations";
import useGetCattlesByFarmId from "../../../hooks/useGetCattlesByFarmId";
import VisitAnimalSelection from "../../Visit/VisitAnimalSelection/VisitAnimalSelection";
import VisitCattleUpdateModal from "../VisitCattleUpdate/VisitCattleUpdateModal/VisitCattleUpdateModal";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category CattleVisit
 * @component holds all the components for user to create a cattle visit
 * @param visitCattleInitState is the init state for details about cattle visit
 */

const visitCattleInitState = {
    veterinarianSSN: "",
    animalIdList: [],
    animalList: [],
    disease: {},
    medicineList: [],
    medicineTempList: [],
    milkBanDate: "",
    slaughterBanDate: "",
    hasInfiniteSlaughterBan: false,
    highestSlaughterBanDate: "",
    highestMilkBanDate: ""
};

const VisitCattleDiagnosesContainer = ({ visitValues }) => {
    /**
     * React useState hook that keeps track of when the vist cattle modal is open or closed
     */
    const [modalIsOpen, setModalIsOpen] = useState(false);
    /**
     * React useState hook that keeps track of when the vist cattle update modal is open or closed
     */
    const [updateCattleModalIsOpen, setUpdateCattleModalIsOpen] = useState(
        false
    );
    /**
     * React useState hook that keeps track of which tab is currently set
     */
    const [tabValue, setTabValue] = useState(0);
    /**
     * Counter that select which visit to update
     */
    const [visitToUpdateCounter, setVisitToUpdateCounter] = useState(0);
    /**
     * React useState hook that keeps track of when the add medicine modal is open or closed
     */
    const [addMedicineOpen, setAddMedicineOpen] = useState(false);
    /**
     * User information from redux store
     */
    const user = useSelector((state) => state.user);
    /**
     * Gets all cattle diseases from database
     */
    const { cattleDiseases } = useGetAllCattleDiseases();
    /**
     * The cattle visit object fixed for the hook that sends it to backend
     */
    const getDiagnosesObject = (values) => {
        const diagnosesObject = {
            animalIdList: values.animalIdList,
            animalList: values.animalList,
            disease: values.disease,
            diseaseId: values.disease.id,
            diseaseIsName: values.disease.isName,
            diseaseEnName: values.disease.enName,
            medicineList: values.medicineList,
            veterinarianSSN: user.username,
            animalCount: values.animalIdList.length,
            slaughterBanDate: values.slaughterBanDate,
            highestSlaughterBanDate: values.highestSlaughterBanDate,
            hasInfiniteSlaughterBan: values.hasInfiniteSlaughterBan,
            milkBanDate: values.milkBanDate,
            highestMilkBanDate: values.highestMilkBanDate
        };

        return diagnosesObject;
    };
    /**
     * Function that handles open for the add medicine modal
     */
    const handleOpenModal = () => {
        setAddMedicineOpen(true);
    };
    /**
     * Function that handles close for the add medicine modal
     */
    const handleCloseModal = () => {
        setAddMedicineOpen(false);
    };
    /**
     * Gets cattle list from selected farm from the RML database
     */
    const { cattleList } = useGetCattlesByFarmId(visitValues.establishmentId);
    /**
     * The submit handler pushes the the cattle visit details to the visit object,
     * closes the modal and clears up.
     */
    const visitCattleSubmitHandler = (values) => {
        values.medicineList.forEach((medicine) => {
            medicine.medicineName = medicine.name;
        });
        const diagnosesObject = getDiagnosesObject(values);
        visitValues.diagnosesList.push(diagnosesObject);
        setModalIsOpen(false);
        visitCattleInitState.disease = {};
        visitCattleInitState.medicineList = [];
        visitCattleInitState.medicineTempList = [];
        visitCattleInitState.animalIdList = [];
        visitCattleInitState.animalList = [];
        visitCattleInitState.slaughterBanDate = "";
        visitCattleInitState.milkBanDate = "";
        visitCattleInitState.highestMilkBanDate = "";
        visitCattleInitState.highestSlaughterBanDate = "";
        visitCattleInitState.hasInfiniteSlaughterBan = false;
        cattleList.map((cattle) => (cattle.isSelected = false));
        resetFields();
        setTabValue(0);
    };

    /**
     * onError function watches which error occurs, sending the user to
     * corresponding tab
     */
    const onError = () => {
        if (errors.animalIdList) {
            setTabValue(0);
        } else {
            if (errors.disease) {
                setTabValue(1);
            } else {
                setTabValue(2);
            }
        }
    };
    /**
     * Hook that keeps track of when user inserts information about cattle visit
     */
    const { values, errors, handleSubmit, resetFields } = useForm(
        visitCattleInitState,
        VisitCattleValidation,
        visitCattleSubmitHandler,
        onError
    );
    /**
     * Function that handles open for the cattle visit modal
     */
    const handleOpenCattleVisitModal = () => {
        setModalIsOpen(true);
    };
    /**
     * Function that handles close for the cattle visit modal
     */
    const handleCloseCattleVisitModal = () => {
        setModalIsOpen(false);
    };
    const handleUpdate = () => {
        cattleList.map((cattle) => (cattle.isSelected = false));
        setUpdateCattleModalIsOpen(false);
    };
    /**
     * Function that handles update cattle visit
     */
    const handleOpenUpdateVisitModal = (counter) => {
        setUpdateCattleModalIsOpen(true);
        setVisitToUpdateCounter(counter);
    };
    /**
     * Function that handles close for the update cattle visit modal
     */
    const handleCloseUpdateVisitModal = () => {
        cattleList.map((cattle) => (cattle.isSelected = false));
        setUpdateCattleModalIsOpen(false);
    };
    /**
     * Function that handles tab value change
     */
    const handleTabValueChange = (event, newValue) => {
        setAddMedicineOpen(false);
        setTabValue(newValue);
    };
    return (
        <div className="visit-cattle-diagnose-container">
            <div className="visit-cattle-diagnoses-body">
                <div className="visit-cattle-diagnoses-header">
                    <AnimalSelectionHeader values={visitValues} />
                </div>
                <div>
                    <DiagnosesMedicineView
                        visitValues={visitValues}
                        handleOpenUpdateVisitModal={handleOpenUpdateVisitModal}
                    />
                </div>
                <div className="visit-cattle-add-button-area">
                    <AddDiagnosesButton
                        handleClick={handleOpenCattleVisitModal}
                    />
                </div>
            </div>
            <Modal
                className="visit-cattle-modal"
                open={modalIsOpen}
                onClose={handleCloseCattleVisitModal}
            >
                <div className="visit-cattle-modal-container">
                    <VisitTabContainer
                        handleTabValueChange={handleTabValueChange}
                        tabValue={tabValue}
                        animalSelectionTitle="Nautgripir"
                        hasSelectionArea={true}
                    />
                    {tabValue === 0 && (
                        <VisitAnimalSelection
                            visitValues={visitValues}
                            values={values}
                            animals={cattleList}
                            errors={errors}
                            animalType="nautgripi"
                        />
                    )}
                    {tabValue === 1 && (
                        <DiseaseSelectionView
                            diseases={cattleDiseases}
                            visitDetailValues={values}
                            errors={errors}
                        />
                    )}
                    {tabValue === 2 && (
                        <MedicineSelectionContainer
                            visitDetailValues={values}
                            visitDate={visitValues.visitDate}
                            isSlaughterBan={true}
                            isMilkBan={true}
                            hasDateLastGiven={true}
                            amountLabel="Heildarmagn í vitjun"
                            handleOpenModal={handleOpenModal}
                            handleCloseModal={handleCloseModal}
                            addMedicineOpen={addMedicineOpen}
                            errors={errors}
                        />
                    )}
                    <div className="visit-cattle-diagnoses-button-area">
                        <CancelButton
                            disabled={addMedicineOpen}
                            handleCancel={handleCloseCattleVisitModal}
                        />
                        <ConfirmationButton
                            disabled={addMedicineOpen}
                            handleSubmit={handleSubmit}
                            title="Staðfesta Meðhöndlun"
                        />
                    </div>
                </div>
            </Modal>
            <VisitCattleUpdateModal
                visitValues={visitValues}
                updateCattleModalIsOpen={updateCattleModalIsOpen}
                handleCloseUpdateVisitModal={handleCloseUpdateVisitModal}
                visitToUpdateCounter={visitToUpdateCounter}
                handleUpdate={handleUpdate}
                animals={cattleList}
                errors={errors}
            />
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitCattleDiagnosesContainer.propTypes = {
    visitValues: visitType
};

export default VisitCattleDiagnosesContainer;
