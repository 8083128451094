import { useState, useEffect } from "react";

const useFilterSearchBar = (list, initKey, showValuesInit) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [showValues, setShowValues] = useState(showValuesInit);
    const searchKey = initKey || "name";
    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleCheckChange = (e) => {
        setShowValues({
            ...showValues,
            [e.target.name]: e.target.checked
        });
        if(e.target.checked) {
            if(e.target.name === "kk" || e.target.name === "kvk") {
                showValues.genderValueList.push(e.target.name)
            }
        }else {
            if(e.target.name === "kk" || e.target.name === "kvk") {
                showValues.genderValueList.splice(showValues.genderValueList.indexOf(e.target.name), 1)
            }
        }
    };
    useEffect(() => {
        let result = list.filter((listItem) =>
            listItem[searchKey].toLowerCase().includes(searchTerm.toLowerCase())
        );
        result = result.filter(i => showValues.genderValueList.includes( i.kyn ));
        if(showValues.youngOne === true && showValues.oldOne === false) {
            result = result.filter(res => res.isYoung === true);
        } else if (showValues.oldOne === true && showValues.youngOne === false) {
            result = result.filter(res => res.isYoung === false)
        } else if (showValues.youngOne === false && showValues.oldOne === false) {
            result = [];
        }
        setSearchResult(result);
    }, [searchTerm, list, searchKey, showValues]);

    return { searchResult, handleChange, searchTerm, handleCheckChange, showValues };
};

export default useFilterSearchBar;