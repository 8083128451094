import React from "react";
import { Modal, Paper } from "@material-ui/core";
import "./UpdateVisitDiseaseModal.css";
import UpdateVisitButtons from "../UpdateVisitButtons/UpdateVisitButtons";
import DiseaseSelectionView from "../../DiseaseSelection/DiseaseSelectionView/DiseaseSelectionView";

const UpdateVisitDiseaseModal = ({
    diagnoses,
    open,
    handleClose,
    unhandledDiseaseId,
    unhandledDiseaseIsName,
    unhandledDiseaseEnName,
    diseases
}) => {
    const handleCancel = () => {
        diagnoses.diseaseId = unhandledDiseaseId;
        diagnoses.diseaseIsName = unhandledDiseaseIsName;
        diagnoses.diseaseEnName = unhandledDiseaseEnName;
        handleClose();
    };
    const updateCallBack = (disease) => {
        diagnoses.diseaseId = disease.id;
        diagnoses.diseaseIsName = disease.isName;
        diagnoses.diseaseEnName = disease.enName;
    };

    const errors = { disease: undefined };
    return (
        <Modal
            className="update-visit-disease-modal"
            open={open}
            onClose={handleClose}
        >
            <Paper className="update-visit-disease-paper">
                <DiseaseSelectionView
                    visitDetailValues={diagnoses}
                    errors={errors}
                    updateCallBack={updateCallBack}
                    diseases={diseases}
                />
                <UpdateVisitButtons
                    handleClose={handleClose}
                    handleCancel={handleCancel}
                />
            </Paper>
        </Modal>
    );
};
export default UpdateVisitDiseaseModal;
