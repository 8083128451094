import React from "react";
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel
} from "@material-ui/core/";
import "./ArrangementSelection.css";
import useGetAllArrangements from "../../../hooks/useGetAllArrangements";

const ArrangementSelection = ({ values, handleChange, errors }) => {
    const { arrangements, isLoading, error } = useGetAllArrangements();
    values.arrangements = arrangements;
    return (
        <div>
            <FormControl error={errors.arrangementTypeId !== undefined}>
                <FormLabel className="arrangement-selection-label">
                    Fyrirkomulag vitjunar{" "}
                    {errors.arrangementTypeId === undefined ? "" : "vantar"}
                </FormLabel>
                <RadioGroup
                    aria-label="arrangementTypeId"
                    name="arrangementTypeId"
                    value={values.arrangementTypeId}
                    onChange={handleChange}
                    row
                >
                    {!isLoading &&
                        !error &&
                        arrangements.map((arrangement) => (
                            <FormControlLabel
                                key={arrangement.id}
                                value={arrangement.id.toString()}
                                control={<Radio size="small" />}
                                label={arrangement.isName}
                            />
                        ))}
                </RadioGroup>
            </FormControl>
        </div>
    );
};

export default ArrangementSelection;
