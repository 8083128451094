const VisitHorseValidation = (values) => {
    const { disease, medicineList } = values;
    const errors = {};

    if (Object.keys(disease) < 1) {
        errors.disease = "Velja þarf sjúkdómsgreiningu";
    }
    if (medicineList.length < 1) {
        errors.medicineList = "Velja þarf lyf";
    }

    return errors;
};

export default VisitHorseValidation;
