import React, { useState } from "react";
import useForm from "../../../hooks/useForm";
import { useSelector } from "react-redux";
import "./VisitPetView.css";
import MedicineSelectionContainer from "../../Visit/MedicineSelection/MedicineSelectionContainer/MedicineSelectionContainer";
import VisitPetSelection from "../VisitPetSelection/VisitPetSelection";
import ConfirmationButton from "../../Visit/ConfirmationButton/ConfirmationButton";
import useCreatePetVisit from "../../../hooks/useCreatePetVisit";
import VisitPetValidation from "../../../validations/visitPetValidation";
import VisitPetDateRangePicker from "../VisitPetDateRangePicker/VisitPetDateRangePicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category PetVisit
 * @component holds all the components for user to create a pet visit
 * @param visitInitState is an object for the general visit
 */

const visitInitState = {
    speciesId: "",
    animalCount: 0,
    veterinarianSSN: null,
    startDate: null,
    endDate: null,
    medicineList: [],
    medicineTempList: []
};

const VisitPetView = () => {
    /**
     * React useState hook that keeps track of when the add medicine modal is open or closed
     */
    const [addMedicineModalIsOpen, setAddMedicineModalIsOpen] = useState(false);
    /**
     * React useState hook to reset site on medicine cancelation
     */
    const [refresh, setRefresh] = useState(false);
    /**
     * Toaster for confirmation message
     */
    const toasterTrigger = () => toast.success("Skráning mótekin");

    /**
     * User information from redux store
     */
    const user = useSelector((state) => state.user);
    /**
     * hook that handles create pet visit
     */
    const { handleCreatePetVisit } = useCreatePetVisit();
    /**
     * The submitCallback function is a call back that gets called inside the
     * useCreateVisit hook. Creates a object from the values object en clears up.
     */
    const submitCallback = () => {
        values.veterinarianId = user.id;
        values.veterinarianSSN = user.username;
        handleCreatePetVisit(values);
        handleRemoveMedicine();
        toasterTrigger();
        resetFields();
    };
    /**
     * Hook that keeps track of when user inserts information about visit
     */
    const { handleSubmit, handleChange, values, errors, resetFields } = useForm(
        visitInitState,
        VisitPetValidation,
        submitCallback
    );
    /**
     * Function that handles close for the pet visit modal
     */
    const handleCloseAddMedicineModal = () => {
        setAddMedicineModalIsOpen(false);
    };
    /**
     * Function that handles close for the pet visit modal
     */
    const handleOpenAddMedicineModal = () => {
        setAddMedicineModalIsOpen(true);
    };
    const handleRemoveMedicine = () => {
        values.medicineList.pop();
        values.medicineTempList.pop();
        setRefresh(!refresh);
    };
    return (
        <div className="visit-pet-container">
            <div className="pet-selection-container">
                <VisitPetDateRangePicker values={values} />
                <VisitPetSelection
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                />
                {values.medicineList.length > 0 ? (
                    <div className="pet-selection-confirmation-button-container">
                        <div className="pet-selection-information-area">
                            <div>
                                <b>Skráð lyf:</b> {values.medicineList[0].name}{" "}
                                <i
                                    className="pet-selection-cancelation-button"
                                    onClick={handleRemoveMedicine}
                                >
                                    X
                                </i>
                            </div>
                            <br />
                            <div>
                                <b>Skráð magn:</b>{" "}
                                {values.medicineList[0].medicineAmount}
                                {values.medicineList[0].unitTypeIsName}
                            </div>
                        </div>
                        <div className="pet-selection-confirmation-button">
                            <ConfirmationButton
                                disabled={false}
                                handleSubmit={handleSubmit}
                                title={"Staðfesta Skráningu"}
                            />
                        </div>
                    </div>
                ) : (
                    <MedicineSelectionContainer
                        visitDetailValues={values}
                        visitDate={null}
                        isSlaughterBan={false}
                        isMilkBan={false}
                        hasDateLastGiven={false}
                        amountLabel="Heildarmagn"
                        handleOpenModal={handleOpenAddMedicineModal}
                        handleCloseModal={handleCloseAddMedicineModal}
                        addMedicineOpen={addMedicineModalIsOpen}
                        errors={errors}
                    />
                )}
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default VisitPetView;
