const createUserValidation = (values) => {
    const { username, name, password, passwordConfirmation } = values;

    const errors = {};

    if (username === "") {
        errors.username = "Kennitölu vantar";
    }
    if (username.length !== 10) {
        errors.username = "Kennitala er 10 tölustafir";
    }
    if (username !== "" && !/^\d+$/.test(username)) {
        errors.username = "Kennitala hefur ekki bókstafi";
    }
    if (name === "") {
        errors.name = "Nafn vantar";
    }
    if (password === "") {
        errors.password = "Lykilorð vantar";
    }
    if (password.length < 8) {
        errors.password = "Lykilorð er að lágmarki 8 stafir";
    }
    if (password !== passwordConfirmation) {
        errors.passwordConfirmation = "Lykilorðin eru ekki eins";
    }

    return errors;
};

export default createUserValidation;
