import React from "react";
import "./VisitHorseSelectionContainer.css";
import VisitHorseSelectionView from "../VisitHorseSelectionView/VisitHorseSelectionView";
import CalendarSelection from "../../Visit/CalendarSelection/CalendarSelection";
import ArrangementSelection from "../../Visit/ArrangementSelection/ArrangementSelection";

const VisitHorseSelectionContainer = ({
    values,
    handleChange,
    searchTerm,
    handleSearchBarChange,
    horses,
    errors
}) => {
    return (
        <div className="visit-horse-selection-container">
            <div className="visit-horse-selection-upper-area">
                <CalendarSelection
                    values={values}
                    handleChange={handleChange}
                    label="Dagsetning vitjunar"
                    valueName="visitDate"
                    disabled={false}
                />
                <ArrangementSelection
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                />
            </div>
            <div className="visit-horse-selection-lower-area">
                <VisitHorseSelectionView
                    searchTerm={searchTerm}
                    handleSearchBarChange={handleSearchBarChange}
                    horses={horses}
                    errors={errors}
                />
            </div>
        </div>
    );
};

export default VisitHorseSelectionContainer;
