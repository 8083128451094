import React from "react";
import SearchBar from "../../../SearchBar/SearchBar";
import "./LiveStockEstablishmentsSelectionView.css";
import LiveStockEstablishmentsSelectionList from "../LiveStockEstablishmentsSelectionList/LiveStockEstablishmentsSelectionList";
import useGetAllEstablishmentsFromRml from "../../../../hooks/useGetAllEstablishmentsFromRml";
import useLiveStockSearchBar from "../../../../hooks/useLiveStockSearchBar";

const LiveStockEstablishmentsSelectionView = ({
    values,
    handleSubmit,
    errors
}) => {
    const handleEstablishmentSelection = (e, establishment) => {
        values.establishmentId = establishment.busnumer;
        values.establishmentSSN = establishment.kennitala;
        values.establishmentName = establishment.heiti;
        handleSubmit(e);
    };

    const {
        handleSearchBarChange,
        searchTerm,
        timeToCall
    } = useLiveStockSearchBar();
    const { establishments } = useGetAllEstablishmentsFromRml(
        searchTerm,
        values.animalTypeId,
        timeToCall
    );
    return (
        <div className="live-stock-establishments-selection-container">
            <div className="live-stock-establishments-selection-searchbar">
                <SearchBar
                    searchTerm={searchTerm}
                    handleChange={handleSearchBarChange}
                    placeHolder="Leita eftir búi"
                />
            </div>
            <div className="live-stock-establishments-selection-list-container">
                {errors !== undefined && (
                    <span className="establishment-error">Velja þarf bú</span>
                )}
                <LiveStockEstablishmentsSelectionList
                    establishments={establishments}
                    handleEstablishmentSelection={handleEstablishmentSelection}
                    errors={errors}
                    searchTerm={searchTerm}
                />
            </div>
        </div>
    );
};

export default LiveStockEstablishmentsSelectionView;
