import React, { useState, useEffect } from "react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import "./UserVisitDateRangePicker.css";

const UserVisitDateRangePicker = ({ dateChanged }) => {
    const getStartDate = () => {
        var dateNow = new Date();
        dateNow.setMonth(dateNow.getMonth() - 1);
        moment.locale("is");
        return moment(dateNow).format("YYYY-MM-DD");
    };
    const getDateNow = () => {
        var dateNow = new Date();
        moment.locale("is");
        return moment(dateNow).format("YYYY-MM-DD");
    };
    const [startDate, setStartDate] = useState(getStartDate);
    const [endDate, setEndDate] = useState(getDateNow);
    const [dateError, setDateError] = useState(false);

    useEffect(() => {
        dateChanged(startDate, endDate);
    }, [startDate, endDate, dateChanged]);

    const handleStartChangeDate = (e) => {
        if (endDate >= moment(e._d).format("YYYY-MM-DD")) {
            setStartDate(moment(e._d).format("YYYY-MM-DD"));
            setDateError(false);
        } else {
            setDateError(true);
        }
    };
    const handleEndChangeDate = (e) => {
        if (startDate <= moment(e._d).format("YYYY-MM-DD")) {
            setEndDate(moment(e._d).format("YYYY-MM-DD"));
            setDateError(false);
        } else {
            setDateError(true);
        }
    };
    return (
        <div className="user-visit-date-range-picker-view-container">
            <div className="user-visit-date-range-picker-container">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        className="user-visit-calendar-picker-start"
                        margin="normal"
                        label="Byrjunar Dagsetning"
                        format="DD/MM/yyyy"
                        value={startDate}
                        onChange={handleStartChangeDate}
                        KeyboardButtonProps={{
                            "aria-label": "change date"
                        }}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        className="user-visit-calendar-picker-start"
                        margin="normal"
                        label="Enda Dagsetning"
                        format="DD/MM/yyyy"
                        value={endDate}
                        onChange={handleEndChangeDate}
                        KeyboardButtonProps={{
                            "aria-label": "change date"
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
            {dateError && (
                <div className="user-visit-date-range-pick-error">
                    Start date not bigger than end date
                </div>
            )}
        </div>
    );
};
export default UserVisitDateRangePicker;
