import React, { useState } from "react";
import CreateIcon from "@material-ui/icons/Create";
import "./VisitGoatUpdateDiagnosesListItem.css";
import useWindowSize from "../../../../hooks/useWindowSize";
import UpdateVisitDiseaseModal from "../../../Visit/UpdateVisit/UpdateVisitDiseaseModal/UpdateVisitDiseaseModal";
import UpdateVisitDiagnosesMedicineList from "../../../Visit/UpdateVisit/UpdateVisitDiagnosesMedicineList/UpdateVisitDiagnosesMedicineList";
import useGetAllSheepDiseases from "../../../../hooks/useGetAllSheepDiseases";
import UpdateVisitAnimalSelectionModal from "../../../Visit/UpdateVisit/UpdateVisitAnimalSelectionModal/UpdateVisitAnimalSelectionModal";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category GoatVisit
 * @component holds all the items that you can update in goat visit.
 *            Main purpose is to handle clicks from user and open and close modals
 */

const VisitGoatUpdateDiagnosesListItem = ({
    diagnoses,
    animals,
    errors,
    animalType
}) => {
    /**
     * Hook that gets user's current window size
     */
    const windowSize = useWindowSize();
    /**
     * Boolean which reads user's screen size and returns true if screen size
     * is less than or equal to 690px (standard screen size in 2021)
     */
    const isPhone = windowSize.width <= 690;
    /**
     * Gets all goat diseases from database
     */
    const { sheepDiseases } = useGetAllSheepDiseases();
    const [visitDiseaseModalIsOpen, setVisitDiseaseModalIsOpen] = useState(
        false
    );
    const [
        visitAnimalSelectionModalIsOpen,
        setVisitAnimalSelectionModalIsOpen
    ] = useState(false);
    const handleClose = () => {
        setVisitAnimalSelectionModalIsOpen(false);
    };
    return (
        <div className="user-visit-update-view-diagnoses-info">
            <div className="user-visit-update-view-diagnoses-site-info">
                <div
                    className="user-visit-update-text"
                    onClick={() => setVisitDiseaseModalIsOpen(true)}
                >
                    <b>Greining:</b> {diagnoses.diseaseIsName}
                    {!isPhone && (
                        <CreateIcon className="user-visit-update-update-icon" />
                    )}
                </div>
            </div>
            <div
                className="user-visit-update-view-animal-selection-view"
                onClick={() => setVisitAnimalSelectionModalIsOpen(true)}
            >
                <div>
                    <b>Breyta gripalista ({diagnoses.animalIdList.length})</b>
                    {!isPhone && (
                        <CreateIcon className="user-visit-update-update-icon" />
                    )}
                </div>
            </div>
            <div className="user-visit-update-view-diagnoses-medicine-info">
                <UpdateVisitDiagnosesMedicineList
                    medicineList={diagnoses.medicineList}
                />
            </div>
            <UpdateVisitDiseaseModal
                open={visitDiseaseModalIsOpen}
                handleClose={() => setVisitDiseaseModalIsOpen(false)}
                diagnoses={diagnoses}
                unhandledDiseaseId={diagnoses.diseaseId}
                unhandledDiseaseIsName={diagnoses.diseaseIsName}
                unhandledDiseaseEnName={diagnoses.diseaseEnName}
                diseases={sheepDiseases}
            />
            <UpdateVisitAnimalSelectionModal
                open={visitAnimalSelectionModalIsOpen}
                values={diagnoses}
                animals={animals}
                handleClose={handleClose}
                errors={errors}
                animalType={animalType}
            />
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitGoatUpdateDiagnosesListItem.propTypes = {
    /**
     * Goat visit object with diagnosesList and treatment object
     */
    //diagnoses: goatUpdateDiagnoses
};

export default VisitGoatUpdateDiagnosesListItem;
