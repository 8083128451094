import { useState, useEffect } from "react";
import rmlEstablishmentService from "../services/rmlEstablishmentsService";

const useGetHorsesByMicroMarkId = (searchTerm, timeToCall) => {
    const [horses, setHorses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        if (timeToCall && searchTerm.length > 1) {
            rmlEstablishmentService
                .getHorsesByMicroMarkId(searchTerm)
                .then((data) => {
                    setHorses(data);
                    setError(null);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [searchTerm, timeToCall]);
    return { horses, isLoading, error };
};

export default useGetHorsesByMicroMarkId;
