import React, { useState } from "react";
import "./VisitAnimalSelection.css";
import { Paper } from "@material-ui/core";
import VisitAnimalSelectionList from "../VisitAnimalSelectionList/VisitAnimalSelectionList";
import SearchBar from "../../SearchBar/SearchBar";
import useFilterSearchBar from "../../../hooks/useFilterSearchBar";
import SortIcon from '@material-ui/icons/Sort';
import VisitAnimalSelectionSelectedList from "../VisitAnimalSelectionSelectedList/VisitAnimalSelectionSelectedList";
import VisitAnimalSelectionFilterModal from "../VisitAnimalSelectionFilterModal/VisitAnimalSelectionFilterModal";

/**
 * @author [Árni Magnússon] (https://github.com/arnimegg)
 * @category Visit
 * @component
 */

 const showValuesInit = {
    kk: true, 
    kvk: true, 
    youngOne: true, 
    oldOne: true,
    genderValueList: ["kk", "kvk"]
};

const VisitAnimalSelection = ({ values, animals, errors, animalType }) => {
    const [refresh, setRefresh] = useState(false);
    const [selectAllAnimals, setSelectAllAnimals] = useState(false);
    const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);


    const { searchResult, handleChange, searchTerm, handleCheckChange, showValues } = useFilterSearchBar(
        animals,
        "searchKey",
        showValuesInit
    );

    const handleAnimalSelection = (e, animal) => {
        if (animal.isSelected) {
            animal.isSelected = false;
            setSelectAllAnimals(false);
            values.animalIdList = values.animalIdList.filter(
                (id) => id.toString() !== animal.gripur_numer.toString()
            );
            values.animalList = values.animalList.filter((x) => {
                return x.gripur_numer !== animal.gripur_numer;
            });
            setRefresh(!refresh);
        } else {
            animal.isSelected = true;
            values.animalIdList.push(animal.gripur_numer);
            values.animalList.push(animal);
            setRefresh(!refresh);
        }
    };
    const handleSelectAllAnimals = () => {
        values.animalIdList = [];
        searchResult.forEach((animal) => {
            animal.isSelected = true;
            values.animalIdList.push(animal.gripur_numer);
        });
        setSelectAllAnimals(true);
        values.animalList = searchResult;
    };
    const handleDeSelectAllAnimals = () => {
        animals.forEach((animal) => {
            animal.isSelected = false;
        });
        setSelectAllAnimals(false);
        values.animalList = [];
        values.animalIdList = [];
    };
    const handleOpenFilterModal = () => {
        setFilterModalIsOpen(true);
    }
    const handleCloseFilterModal = () => {
        setFilterModalIsOpen(false);
    }
    return (
        <>
            <Paper className="visit-animal-selection-container-paper">
                <div className="visit-animal-selection-header">
                    <div className="visit-animal-selection-header-text">
                        {errors.animalIdList ? (
                            <div className="visit-animal-selection-header-error">
                                {errors.animalIdList}
                            </div>
                        ) : (
                            <div>Veldu {animalType} </div>
                        )}
                    </div>
                    <div className="visit-animal-selection-searchbar-and-filter">
                        <div className="visit-animal-selection-filter" onClick={handleOpenFilterModal}>
                        <SortIcon /> Síjur
                        </div>
                        <div className="visit-animal-selection-searchbar">
                        <SearchBar
                            searchTerm={searchTerm}
                            handleChange={handleChange}
                            placeHolder="Leita að grip"
                        />
                        </div>
                    </div>
                </div>
                <div className="visit-animal-selection-list-container">
                    <div className="visit-animal-selection-list-upper">
                        <VisitAnimalSelectionList
                            animals={searchResult}
                            selectAllAnimals={selectAllAnimals}
                            handleAnimalSelection={handleAnimalSelection}
                            handleSelectAllAnimals={handleSelectAllAnimals}
                            handleDeSelectAllAnimals={handleDeSelectAllAnimals}
                        />
                    </div>
                    <div className="visit-animal-selection-selected-list-container-lower">
                        <VisitAnimalSelectionSelectedList
                            animals={values.animalList}
                        />
                    </div>
                </div>
            </Paper>
            <VisitAnimalSelectionFilterModal 
                open={filterModalIsOpen} 
                handleClose={handleCloseFilterModal} 
                showValues={showValues} 
                handleCheckChange={handleCheckChange}
            />
        </>
    );
};

export default VisitAnimalSelection;
