import { useState, useEffect } from "react";
import poultryService from "../services/poultryService";

const useGetAllPoultries = () => {
    const [poultries, setPoultries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        poultryService
            .getAllPoultries()
            .then((poultries) => {
                poultries.map(
                    (poultries) =>
                        (poultries.searchKey =
                            poultries.companyIsName +
                            " " +
                            poultries.registrationCode)
                );
                setPoultries(poultries);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { poultries, isLoading, error };
};
export default useGetAllPoultries;
