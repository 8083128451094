import React from "react";
import { Paper } from "@material-ui/core";
import AddMedicineButton from "../AddMedicineButton/AddMedicineButton";
import MedicineSelectionTable from "../MedicineSelectionTable/MedicineSelectionTable";
import "./MedicineSelectionContainer.css";
import AddMedicineModal from "../AddMedicineModal/AddMedicineModal";

const MedicineSelectionContainer = ({
    medicines,
    visitDetailValues,
    visitDate,
    isSlaughterBan,
    isMilkBan,
    hasDateLastGiven,
    amountLabel,
    handleOpenModal,
    handleCloseModal,
    addMedicineOpen,
    errors
}) => {
    return (
        <Paper className="medicine-selection-container">
            {!addMedicineOpen ? (
                <div className="medicine-selection-container-view">
                    <div className="medicine-selection-button-area">
                        <AddMedicineButton
                            handleClick={handleOpenModal}
                            text="Bæta við lyfi"
                        />
                    </div>
                    <b className="medicine-selection-error">
                        {visitDetailValues.medicineList.length < 1 &&
                            errors.medicineList}
                    </b>
                    <div className="medicine-selection-selected-table">
                        <MedicineSelectionTable
                            items={visitDetailValues.medicineList}
                        />
                    </div>
                </div>
            ) : (
                <div className="medicine-selection-container-modal">
                    <AddMedicineModal
                        medicines={medicines}
                        visitDetailValues={visitDetailValues}
                        handleCloseModal={handleCloseModal}
                        visitDate={visitDate}
                        isSlaughterBan={isSlaughterBan}
                        isMilkBan={isMilkBan}
                        hasDateLastGiven={hasDateLastGiven}
                        amountLabel={amountLabel}
                    />
                </div>
            )}
        </Paper>
    );
};

export default MedicineSelectionContainer;
