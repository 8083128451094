import { useState, useEffect } from "react";
import sheepService from "../services/sheepService";

const useGetAllSheepDiseases = () => {
    const [sheepDiseases, setSheepDiseases] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        sheepService
            .getAllSheepDiseases()
            .then((sheepDiseases) => {
                setSheepDiseases(sheepDiseases);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { sheepDiseases, isLoading, error };
};
export default useGetAllSheepDiseases;
