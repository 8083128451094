import { useState, useEffect } from "react";
import packageUnitTypeService from "../services/packageUnitTypeService";

const useGetAllPackageUnitTypes = () => {
    const [packageUnitTypes, setPackageUnitTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        packageUnitTypeService
            .getAllPackageUnitTypes()
            .then((packageUnitTypes) => {
                setPackageUnitTypes(packageUnitTypes);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { packageUnitTypes, isLoading, error };
};

export default useGetAllPackageUnitTypes;
