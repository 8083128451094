import React from "react";
import { Button, Modal, Paper } from "@material-ui/core";
import VisitHorseUpdateDiagnosesListItem from "../VisitHorseUpdateDiagnosesListItem/VisitHorseUpdateDiagnosesListItem";
import "./VisitHorseUpdateModal.css";

const VisitHorseUpdateModal = ({
    visitValues,
    updateHorseModalIsOpen,
    handleCloseUpdateVisitModal,
    visitToUpdateCounter,
    handleUpdate
}) => {
    return (
        <Modal
            className="update-visit-horse-modal"
            open={updateHorseModalIsOpen}
            onClose={handleCloseUpdateVisitModal}
        >
            <Paper className="update-visit-horse-paper">
                <div className="update-visit-horse-modal-header">
                    Uppfæra vitjun
                </div>
                <VisitHorseUpdateDiagnosesListItem
                    diagnoses={visitValues.diagnosesList[visitToUpdateCounter]}
                    establishmentId={visitValues.establishmentId}
                />
                <div className="diagnoses-medicine-view-update-card-button-area">
                    <Button
                        className="diagnoses-medicine-view-update-card-cancel-button"
                        onClick={handleCloseUpdateVisitModal}
                    >
                        <b>Til baka</b>
                    </Button>
                    <Button
                        className="diagnoses-medicine-view-update-card-confirm-button"
                        onClick={handleUpdate}
                    >
                        <b>Vista</b>
                    </Button>
                </div>
            </Paper>
        </Modal>
    );
};

export default VisitHorseUpdateModal;
