import { useState, useEffect } from "react";
import userService from "../services/userService";

const useCreateUser = (onSuccess, onError) => {
    const [isProcessing, setProcessing] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (user && !isProcessing) {
            setProcessing(true);
            userService
                .createUser(user)
                .then(() => {
                    onSuccess();
                })
                .catch(() => onError())
                .finally(() => {
                    setUser(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, user, onSuccess, onError]);

    const handleCreateUser = (incomingUser) => {
        if (!isProcessing) {
            const userInputModel = {
                name: incomingUser.name,
                userName: incomingUser.username,
                passWord: incomingUser.password,
                isAdmin: incomingUser.isAdmin
            };
            setUser(userInputModel);
        }
    };
    return { handleCreateUser };
};
export default useCreateUser;
