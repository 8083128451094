const VisitCattleValidation = (values) => {
    const { animalIdList, disease, medicineList } = values;
    const errors = {};

    if (animalIdList.length < 1) {
        errors.animalIdList = "Velja þarf dýr til meðferðar";
    }
    if (Object.keys(disease) < 1) {
        errors.disease = "Velja þarf sjúkdómsgreiningu";
    }
    if (medicineList.length < 1) {
        errors.medicineList = "Velja þarf lyf";
    }

    return errors;
};

export default VisitCattleValidation;
