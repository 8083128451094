/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/porcinevisits`;
const apiPorcineLicensesEndPoint = `${endpoint}/api/licenses/porcines`;

const getAllPorcines = () => {
    return fetch(apiPorcineLicensesEndPoint, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const getAllPorcineDiseases = () => {
    return fetch(apiEndpoint + "/diseases", {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const createPorcineVisit = (porcineVisit) => {
    return fetch(apiEndpoint, {
        method: "POST",
        body: JSON.stringify(porcineVisit),
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const updatePorcineVisit = (porcineVisit) => {
    return fetch(apiEndpoint, {
        method: "PUT",
        body: JSON.stringify(porcineVisit),
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

export default {
    getAllPorcines,
    getAllPorcineDiseases,
    createPorcineVisit,
    updatePorcineVisit
};
