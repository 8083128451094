import React from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InfoIcon from "@material-ui/icons/Info";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import NotesIcon from "@material-ui/icons/Notes";
import "./VisitTabContainer.css";

const VisitTabContainer = ({
    handleTabValueChange,
    tabValue,
    animalSelectionTitle,
    hasSelectionArea
}) => {
    return (
        <Paper className="diagnoses-tab-container-paper">
            <Tabs
                className="dignoses-tab-container"
                value={tabValue}
                onChange={handleTabValueChange}
                variant="fullWidth"
                aria-label="icon label tabs example"
            >
                {hasSelectionArea && (
                    <Tab
                        className="diagnoses-tab-item"
                        icon={<InfoIcon />}
                        label={animalSelectionTitle}
                    />
                )}
                <Tab
                    className="diagnoses-tab-item"
                    icon={<NotesIcon />}
                    label="Greining"
                />
                <Tab
                    className="diagnoses-tab-item"
                    icon={<LocalHospitalIcon />}
                    label="Lyf"
                />
            </Tabs>
        </Paper>
    );
};

export default VisitTabContainer;
