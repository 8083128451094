import React from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@material-ui/core";
import EstablishmentsSelectionListItem from "../EstablishmentsSelectionListItem/EstablishmentsSelectionListItem";
import "./EstablishmentsSelectionList.css";

const EstablishmentsSelectionList = ({
    establishments,
    handleEstablishmentSelection,
    hasType,
    errors
}) => {
    return (
        <Table
            stickyHeader
            aria-label="sticky table"
            className="establishments-selection-list"
            variant="head"
        >
            <TableHead>
                <TableRow>
                    <TableCell>
                        <b>Fyrirtæki</b>
                    </TableCell>
                    <TableCell align="right">
                        <b>Leyfisnúmer</b>
                    </TableCell>
                    {hasType && (
                        <TableCell>
                            <b>Tegund</b>
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {establishments.map((establishment) => (
                    <EstablishmentsSelectionListItem
                        key={establishment.registrationId}
                        establishment={establishment}
                        handleEstablishmentSelection={
                            handleEstablishmentSelection
                        }
                        hasType={hasType}
                    />
                ))}
            </TableBody>
        </Table>
    );
};

export default EstablishmentsSelectionList;
