import React from "react";
import "./VisitView.css";
import { useSelector } from "react-redux";
import VisitViewSelectionList from "../VisitViewSelectionList/VisitViewSelectionList";

const VisitView = () => {
    const language = useSelector((state) => state.languages);
    const user = useSelector((state) => state.user);
    return (
        <div className="visit-container">
            <h2 className="visit-view-title">
                {language.isIcelandic
                    ? `Hæ ${user.name}, hvaða dýrategund viltu skrá vitjun á?`
                    : `Hey ${user.name}, which type of animal are you visiting?`}
            </h2>
            <VisitViewSelectionList />
        </div>
    );
};

export default VisitView;
