import React, { useState } from 'react';

import { TextField } from "@material-ui/core";

const AdminInputFormItem = ({
    error,
    item,
    values,
    handleChange
}
) => {

    const [openFocusOnInput, setOpenFocusOnInput] = useState(false);

    const focusOnInput = () => {
        setOpenFocusOnInput(!openFocusOnInput);
    }
    return (
        <>
            <TextField
                onClick={() => { focusOnInput() }}
                focused={openFocusOnInput}
                className="admin-input-label"
                label={item.title}
                type="text"
                name={item.name}
                autoComplete="off"
                value={values[item.name]}
                onChange={handleChange}
                helperText={error[item.name] ?
                    <span className="admin-input-label-helper-text admin-input-label-helper-text-error">
                        {error[item.name]}
                    </span> :
                    <span className="admin-input-label-helper-text">
                        {item.label}
                    </span>
                }
            />
        </>
    );
}

export default AdminInputFormItem;