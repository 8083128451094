import { useState, useEffect } from "react";
import aquaCultureSpeciesService from "../services/aquaCultureSpeciesService";

const useGetAllAquaCultureSpecies = () => {
    const [aquaCultureSpecies, setAquaCultureSpecies] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        aquaCultureSpeciesService
            .getAllAquaCultureSpecies()
            .then((aquaCultureSpecies) => {
                setAquaCultureSpecies(aquaCultureSpecies);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { aquaCultureSpecies, isLoading, error };
};

export default useGetAllAquaCultureSpecies;
