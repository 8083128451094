import React from "react";
import LoginForm from "../LoginForm/LoginForm";
import "./LoginLandingPage.css";

const LoginLandingPage = () => {
    return (
        <div className="login-landing-page-container">
            <div className="login-page-login-container">
                <div className="login-page-login-title">
                    <h1>Betri Dýraheilsa</h1>
                </div>
                <div>
                    <LoginForm />
                </div>
            </div>
        </div>
    );
};
export default LoginLandingPage;
