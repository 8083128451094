import React from "react";
import UpdateVisitDiagnosesMedicineListItem from "../UpdateVisitDiagnosesMedicineListItem/UpdateVisitDiagnosesMedicineListItem";
import "./UpdateVisitDiagnosesMedicineList.css";

const UpdateVisitDiagnosesMedicineList = ({ medicineList }) => {
    return (
        <div className="user-visit-update-view-diagnoses-medicine-list">
            {medicineList.map((medicine, i) => (
                <UpdateVisitDiagnosesMedicineListItem
                    medicine={medicine}
                    key={i}
                />
            ))}
        </div>
    );
};

export default UpdateVisitDiagnosesMedicineList;
