import React from "react";
import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import "./DiseaseSelectedItem.css";

const DiseaseSelectedItem = ({ visitDetailValues, handleRemoveDiagnoses }) => {
    return (
        <div className="disease-view-selected-container">
            <div className="disease-selection-selected-items">
                <b>{visitDetailValues.disease.isName}</b>
            </div>
            <div>
                <IconButton
                    className="disease-selection-selected-header-button"
                    onClick={handleRemoveDiagnoses}
                    size="small"
                >
                    Fjarlægja greiningu
                    <ClearIcon />
                </IconButton>
            </div>
        </div>
    );
};

export default DiseaseSelectedItem;
