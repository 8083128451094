/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/aquaculturevisits`;

const getAllAquaCultureDiseases = () => {
    return fetch(apiEndpoint + "/diseases", {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const createAquaCultureVisit = (aquaCultureVisit) => {
    return fetch(apiEndpoint, {
        method: "POST",
        body: JSON.stringify(aquaCultureVisit),
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const updateAquaCultureVisit = (aquaCultureVisit) => {
    return fetch(apiEndpoint, {
        method: "PUT",
        body: JSON.stringify(aquaCultureVisit),
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

export default {
    getAllAquaCultureDiseases,
    createAquaCultureVisit,
    updateAquaCultureVisit
};
