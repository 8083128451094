import { useState, useEffect } from "react";
import goatService from "../services/goatService";

const useUpdateGoatVisit = () => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [goatVisits, setGoatVisits] = useState(null);

    useEffect(() => {
        if (goatVisits && !isProcessing) {
            setProcessing(true);
            goatService
                .updateGoatVisit(goatVisits)
                .then(() => {
                    setError(null);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setGoatVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, goatVisits]);

    const handleUpdateGoatVisit = (incomingVisit) => {
        if (!isProcessing) {
            const outgoingVisit = {
                id: incomingVisit.id,
                arrangementTypeId: parseInt(incomingVisit.arrangementTypeId),
                establishmentId: incomingVisit.establishmentId,
                establishmentName: incomingVisit.establishmentName,
                establishmentSSN: incomingVisit.establishmentSSN,
                animalTypeId: incomingVisit.animalTypeId,
                veterinarianId: incomingVisit.veterinarianId,
                veterinarianSSN: incomingVisit.veterinarianSSN,
                visitDate: incomingVisit.visitDate,
                goatVisits: prepareGoatVisits(incomingVisit)
            };
            setGoatVisits(outgoingVisit);
        }
    };

    const prepareGoatVisits = (incomingGoatVisits) => {
        var vcList = [];
        incomingGoatVisits.diagnosesList.forEach((goatVisit) => {
            const visitGoatInputModelList = {
                id: goatVisit.id,
                visitId: goatVisit.visitId,
                goats: goatVisit.animalIdList.map((animalId) =>
                    animalId.toString()
                ),
                diseaseId: goatVisit.diseaseId,
                veterinarianSSN: goatVisit.veterinarianSSN,
                medicines: prepareMedicines(goatVisit.medicineList)
            };
            vcList.push(visitGoatInputModelList);
        });
        return vcList;
    };

    const prepareMedicines = (incomingMed) => {
        let medList = [];
        for (let i = 0; i < incomingMed.length; i++) {
            medList.push({
                id: incomingMed[i].id,
                medicineId: incomingMed[i].medicineId,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleUpdateGoatVisit, isProcessing };
};
export default useUpdateGoatVisit;
