import React from "react";
import "./UpdateVisitButtons.css";
import { Button } from "@material-ui/core";
const UpdateVisitButtons = ({ handleClose, handleCancel, disabled }) => {
    return (
        <div className="update-visit-button-area">
            <Button
                className="update-visit-cancel-button"
                onClick={handleCancel}
            >
                <b>Hætta Við</b>
            </Button>
            <Button
                className="update-visit-confirm-button"
                onClick={handleClose}
                disabled={disabled}
            >
                <b>Staðfesta</b>
            </Button>
        </div>
    );
};
export default UpdateVisitButtons;
