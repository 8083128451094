import React from "react";
import "./VisitAnimalSelectionList.css";
import VisitAnimalSelectionListItem from "../VisitAnimalSelectionListItem/VisitAnimalSelectionListItem";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import InfoIcon from "@material-ui/icons/Info";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@material-ui/core";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category Visit
 * @component lol
 */

const VisitAnimalSelectionList = ({
    animals,
    selectAllAnimals,
    handleSelectAllAnimals,
    handleDeSelectAllAnimals,
    handleAnimalSelection
}) => {
    return (
        <div className="visit-animal-selection-list-table-container">
            <Table
                stickyHeader
                aria-label="sticky table"
                className="visit-animal-selection-list"
                variant="head"
            >
                <TableHead>
                    <TableRow>
                        <TableCell className="visit-animal-selection-list-title">
                            <div className="visit-animal-selection-list-title-text">
                                Gripir ({animals.length})
                                {
                                !selectAllAnimals ? (
                                    <div className="visit-animal-selection-list-title-text-box">
                                        <InfoIcon className="visit-animal-selection-list-title-info" titleAccess="Velja alla gripi innan síju" />
                                        <CheckBoxOutlineBlankIcon
                                            onClick={handleSelectAllAnimals}
                                            className="visit-animal-selection-list-item-checkbox"
                                        />
                                    </div>
                                ) : (
                                    <div className="visit-animal-selection-list-title-text-box">
                                        <InfoIcon className="visit-animal-selection-list-title-info" titleAccess="Afvelja alla gripi innan síju" />
                                        <CheckBoxIcon
                                            onClick={handleDeSelectAllAnimals}
                                            className="visit-animal-selection-list-item-checkbox"
                                        />
                                    </div>
                                )}
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {animals.map((animal, i) => (
                        <VisitAnimalSelectionListItem
                            key={i}
                            animal={animal}
                            handleAnimalSelection={handleAnimalSelection}
                        />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default VisitAnimalSelectionList;
