import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import "./VisitStepperView.css";
import { funcType, boolType, numberType } from "../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category Visit
 * @component is a general stepper inside every visit. The component has 3 steps.
 * 1. General details about visit
 * 2. View for adding sub visits
 * 3. Confirmation details
 */

const useStyles = makeStyles({
    root: {
        maxWidth: "100%",
        backgroundColor: "rgb(41, 39, 46)"
    }
});

const VisitStepperView = ({
    handleSubmit,
    handleBack,
    activeStep,
    isPhone,
    forwardText,
    backText
}) => {
    const classes = useStyles();
    const stepperPos = isPhone ? "bottom" : "static";

    return (
        <MobileStepper
            variant="dots"
            steps={2}
            position={stepperPos}
            activeStep={activeStep}
            className={classes.root}
            nextButton={
                <Button size="small" onClick={(e) => handleSubmit(e)}>
                    {" "}
                    {activeStep === 0 && (
                        <div className="stepper-button-text">
                            {forwardText()}
                        </div>
                    )}
                    {activeStep === 1 && (
                        <div className="stepper-button-text">
                            {!isPhone
                                ? forwardText() + " Skráningu"
                                : forwardText()}
                        </div>
                    )}
                    {activeStep !== 2 && (
                        <KeyboardArrowRight className="stepper-visit-right" />
                    )}
                </Button>
            }
            backButton={
                <Button size="small" onClick={handleBack}>
                    {activeStep === 1 && (
                        <KeyboardArrowLeft className="stepper-visit-left" />
                    )}
                    {activeStep !== 0 && (
                        <div className="stepper-button-text">{backText()}</div>
                    )}
                </Button>
            }
        />
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitStepperView.propTypes = {
    /**
     * Gets called when the user confirms visit
     */
    handleSubmit: funcType,
    /**
     * Gets called when the user clicks backward button
     */
    handleBack: funcType,
    /**
     * Keeps track of current active step
     */
    activeStep: numberType,
    /**
     * Boolean which reads user's screen size and returns true if screen size
     * is less than or equal to 690px (standard screen size in 2021)
     */
    isPhone: boolType,
    /**
     * Function that returns text for the forward button
     */
    forwardText: funcType,
    /**
     * Function that returns text for the backward button
     */
    backText: funcType
};

export default VisitStepperView;
