import { useState, useEffect } from "react";
import aquaCultureService from "../services/aquaCultureService";

const useUpdateAquaCultureVisit = () => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [aquaCultureVisits, setAquaCultureVisits] = useState(null);

    useEffect(() => {
        if (aquaCultureVisits && !isProcessing) {
            setProcessing(true);
            aquaCultureService
                .updateAquaCultureVisit(aquaCultureVisits)
                .then(() => {
                    setError(null);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setAquaCultureVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, aquaCultureVisits]);

    const handleUpdateAquaCultureVisit = (incomingVisit) => {
        if (!isProcessing) {
            const outgoingVisit = {
                id: incomingVisit.id,
                arrangementTypeId: parseInt(incomingVisit.arrangementTypeId),
                establishmentId: incomingVisit.establishmentId,
                establishmentName: incomingVisit.establishmentName,
                establishmentSSN: incomingVisit.establishmentSSN,
                animalTypeId: incomingVisit.animalTypeId,
                veterinarianId: incomingVisit.veterinarianId,
                veterinarianSSN: incomingVisit.veterinarianSSN,
                visitDate: incomingVisit.visitDate,
                aquaCultureVisits: prepareAquaCultureVisits(incomingVisit)
            };
            setAquaCultureVisits(outgoingVisit);
        }
    };
    const prepareAquaCultureVisits = (incomingAquaCultureVisits) => {
        var vacimList = [];
        incomingAquaCultureVisits.diagnosesList.forEach((aquavisit) => {
            const visitAquaCultureInputModelList = {
                id: aquavisit.id,
                visitId: aquavisit.visitId,
                speciesId: parseInt(aquavisit.speciesId),
                speciesIsName: aquavisit.speciesIsName,
                speciesEnName: aquavisit.speciesEnName,
                diseaseId: aquavisit.diseaseId,
                siteId:
                    aquavisit.siteId === "" ? null : parseInt(aquavisit.siteId),
                siteName: aquavisit.siteName,
                fishCount: parseFloat(aquavisit.animalCount),
                biomass: parseFloat(aquavisit.biomass),
                areaSize: parseFloat(aquavisit.areaSize),
                temperature: parseFloat(aquavisit.temperature),
                veterinarianSSN: aquavisit.veterinarianSSN,
                medicines: prepareMedicines(aquavisit.medicineList)
            };
            vacimList.push(visitAquaCultureInputModelList);
        });
        return vacimList;
    };
    const prepareMedicines = (incomingMed) => {
        let medList = [];
        for (let i = 0; i < incomingMed.length; i++) {
            medList.push({
                id: incomingMed[i].id,
                medicineId: incomingMed[i].medicineId,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleUpdateAquaCultureVisit, isProcessing };
};
export default useUpdateAquaCultureVisit;
