import React from "react";
import "./AdminRadio.css";
import {
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel
} from "@material-ui/core";

const AdminRadio = ({
    error,
    items,
    values,
    handleChange
}
) => {
    return (
        <>
            {items.map((it, index) =>
                <div className="add-medicine-item" key={index}>
                    <div className="admin-radio">
                        <div className="admin-radio-label" >
                            <FormLabel component="legend">{it.title} </FormLabel>
                            {error[it.name] ?
                                <span className="admin-radio-label-helper-text admin-radio-label-helper-text-error">
                                    {error[it.name]}
                                </span> :
                                <span className="admin-radio-label-helper-text">

                                </span>
                            }
                        </div>
                        <RadioGroup aria-label="" name={it.name} value={values[it.name]} onChange={handleChange}>
                            <FormControlLabel value={"1"} control={<Radio />} label="Já" />
                            <FormControlLabel value={"0"} control={<Radio />} label="Nei" />
                        </RadioGroup>
                        <div>

                        </div>
                    </div>
                </div>
            )
            }
        </>
    );
}

export default AdminRadio;