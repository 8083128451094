import React from "react";
import { TableCell, TableRow, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import "./MedicineSelectionTableItem.css";

const MedicineSelectionTableItem = ({ item, handleRemove }) => {
    return (
        <TableRow className="medicine-selection-table-row">
            <TableCell className="medicine-selection-table-item">
                <b>{item.name}</b>
            </TableCell>
            <TableCell className="medicine-selection-table-item">
                {item.packageUnit} {item.packageUnitTypeIsName}
            </TableCell>
            <TableCell className="medicine-selection-table-item">
                {item.medicineAmount} {item.unitTypeIsName}
            </TableCell>
            <TableCell>
                <IconButton
                    aria-label="delete"
                    color="secondary"
                    onClick={() => handleRemove(item.id)}
                >
                    <ClearIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default MedicineSelectionTableItem;
