import React from "react";
import UserVisitsListItem from "../UserVisitsListItem/UserVisitsListItem";
import {
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow
} from "@material-ui/core";
import "./UserVisitsList.css";

const UserVisitsList = ({ visits, handleVisitClick }) => {
    return (
        <Table className="user-visit-selection-table" size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Starfstöð</TableCell>
                    <TableCell>Dýrategund</TableCell>
                    <TableCell>Dagsetning</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {visits.length > 0 ? (
                    visits.map((visit, i) => (
                        <UserVisitsListItem
                            visit={visit}
                            handleVisitClick={handleVisitClick}
                            key={i}
                        />
                    ))
                ) : (
                    <TableRow>
                        <TableCell className="user-visit-table-no-visits">
                            Engar vitjanir fundust á völdu tímabili ...
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

export default UserVisitsList;
