import React from "react";
import { TextField } from "@material-ui/core";
import {
    poultryDiagnoses,
    errorsType,
    stringType,
    funcType
} from "../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category PoultryVisit
 * @component is a general component for each input that user can change
 *            in create/update poultry visit
 */

const VisitPoultryInput = ({
    handleChange,
    values,
    type,
    name,
    label,
    errors
}) => {
    return (
        <div>
            <TextField
                label={
                    values[name] > 0 || errors[name] === undefined
                        ? label
                        : errors[name]
                }
                type={type}
                name={name}
                onChange={handleChange}
                value={values[name]}
                error={values[name] < 1 && errors[name] !== undefined}
            />
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitPoultryInput.propTypes = {
    /**
     * Function that handles change on input
     */
    handleChange: funcType,
    /**
     * Poultry visit object with diagnosesList and treatment object
     */
    values: poultryDiagnoses,
    /**
     * Label prop for reusability
     */
    label: stringType.isRequired,
    /**
     * Name prop for reusability
     */
    name: stringType.isRequired,
    /**
     * Type prop for reusability
     */
    type: stringType.isRequired,
    errors: errorsType
};

export default VisitPoultryInput;
