import React from "react";
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    Radio,
    FormLabel
} from "@material-ui/core";
import "./VisitAquaCultureRadio.css";
import {
    aquaCultureDiagnoses,
    visitType,
    funcType,
    errorsType
} from "../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category AquaCultureVisit
 * @component holds radio buttons for aqua culture site
 */

const VisitAquaCultureRadio = ({
    visitValues,
    values,
    handleChange,
    errors
}) => {
    return (
        <div className="visit-aqua-culture-radio">
            <FormControl component="fieldset">
                <FormLabel>
                    {values.siteId !== null || errors.siteId === undefined ? (
                        <b className="visit-aqua-establishment-label">
                            Veldu Svæði
                        </b>
                    ) : (
                        <b className="visit-aqua-establishment-label-error">
                            {errors.siteId}
                        </b>
                    )}
                </FormLabel>
                <RadioGroup
                    aria-label="siteId"
                    name="siteId"
                    value={values.siteId}
                    onChange={handleChange}
                >
                    {visitValues.establishmentAreas.length > 0 ? (
                        visitValues.establishmentAreas.map((site, i) => (
                            <FormControlLabel
                                value={i.toString()}
                                control={<Radio />}
                                label={site.name}
                                key={i}
                            />
                        ))
                    ) : (
                        <>Enginn svæði í boði ...</>
                    )}
                </RadioGroup>
            </FormControl>
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitAquaCultureRadio.propTypes = {
    /**
     * Aqua culture visit object with diagnosesList and treatment object
     */
    visitValues: visitType,
    /**
     * Aqua culture visit details object
     */
    values: aquaCultureDiagnoses,
    /**
     * Function that handles change when user changes species
     */
    handleChange: funcType,
    errors: errorsType
};

export default VisitAquaCultureRadio;
