import { useState, useEffect } from "react";
import furAnimalService from "../services/furAnimalService";

const useCreateFurAnimalVisit = (cb) => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [furAnimalVisits, setFurAnimalVisits] = useState(null);

    useEffect(() => {
        if (furAnimalVisits && !isProcessing) {
            setProcessing(true);
            furAnimalService
                .createFurAnimalVisit(furAnimalVisits)
                .then(() => {
                    setError(null);
                    cb(furAnimalVisits[0].visitId);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setFurAnimalVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, furAnimalVisits, cb]);

    const handleCreateFurAnimalVisit = (visitId, incomingVisit) => {
        if (!isProcessing) {
            var vpimList = [];

            incomingVisit.forEach((furAnimalVisit) => {
                const visitFurAnimalInputModelList = {
                    visitId: visitId,
                    diseaseId: furAnimalVisit.disease.id,
                    veterinarianSSN: furAnimalVisit.veterinarianSSN,
                    medicines: prepareMedicines(furAnimalVisit.medicineList)
                };
                vpimList.push(visitFurAnimalInputModelList);
            });
            setFurAnimalVisits(vpimList);
        }
    };

    const prepareMedicines = (incomingMed) => {
        var medList = [];
        for (var i = 0; i < incomingMed.length; i++) {
            medList.push({
                medicineId: incomingMed[i].id,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleCreateFurAnimalVisit, isProcessing };
};
export default useCreateFurAnimalVisit;
