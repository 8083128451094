import { useState, useEffect } from "react";
import furAnimalService from "../services/furAnimalService";

const useUpdateFurAnimalVisit = () => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [furAnimalVisits, setFurAnimalVisits] = useState(null);

    useEffect(() => {
        if (furAnimalVisits && !isProcessing) {
            setProcessing(true);
            furAnimalService
                .updateFurAnimalVisit(furAnimalVisits)
                .then(() => {
                    setError(null);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setFurAnimalVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, furAnimalVisits]);

    const handleUpdateFurAnimalVisit = (incomingVisit) => {
        if (!isProcessing) {
            const outgoingVisit = {
                id: incomingVisit.id,
                arrangementTypeId: parseInt(incomingVisit.arrangementTypeId),
                establishmentId: incomingVisit.establishmentId,
                establishmentName: incomingVisit.establishmentName,
                establishmentSSN: incomingVisit.establishmentSSN,
                animalTypeId: incomingVisit.animalTypeId,
                veterinarianId: incomingVisit.veterinarianId,
                veterinarianSSN: incomingVisit.veterinarianSSN,
                visitDate: incomingVisit.visitDate,
                furAnimalVisits: prepareFurAnimalVisits(incomingVisit)
            };
            setFurAnimalVisits(outgoingVisit);
        }
    };

    const prepareFurAnimalVisits = (incomingFurAnimalVisits) => {
        var vpList = [];
        incomingFurAnimalVisits.diagnosesList.forEach((furAnimal) => {
            const visitFurAnimalInputModelList = {
                id: furAnimal.id,
                visitId: furAnimal.visitId,
                diseaseId: furAnimal.diseaseId,
                veterinarianSSN: furAnimal.veterinarianSSN,
                medicines: prepareMedicines(furAnimal.medicineList)
            };
            vpList.push(visitFurAnimalInputModelList);
        });
        return vpList;
    };

    const prepareMedicines = (incomingMed) => {
        let medList = [];
        for (let i = 0; i < incomingMed.length; i++) {
            medList.push({
                id: incomingMed[i].id,
                medicineId: incomingMed[i].medicineId,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleUpdateFurAnimalVisit, isProcessing };
};
export default useUpdateFurAnimalVisit;
