import React from "react";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import "./AddDiagnosesButton.css";

const AddDiagnosesButton = ({ handleClick }) => {
    return (
        <div className="add-diagnosis-button" onClick={handleClick}>
            <IconButton size="medium">
                <AddIcon />
                <b>Ný Meðhöndlun</b>
            </IconButton>
        </div>
    );
};

export default AddDiagnosesButton;
