import React from "react";
import "./AnimalSelectionHeader.css";

const AnimalSelectionHeader = ({ values }) => {
    const formatDateHelper = (date) => {
        const parts = date.split("-");
        return new Date(parts[0], parts[1] - 1, parts[2]).toDateString();
    };
    return (
        <div className="animal-selection-header">
            <div className="animal-selection-header-item">
                <i>{formatDateHelper(values.visitDate)}</i>
            </div>
            <div className="animal-selection-header-item">
                <h3>{values.establishmentName}</h3>
            </div>
            <div className="animal-selection-header-item">
                <i>
                    {
                        values.arrangements.find(
                            (x) => x.id === parseInt(values.arrangementTypeId)
                        ).isName
                    }
                </i>
            </div>
        </div>
    );
};
export default AnimalSelectionHeader;
