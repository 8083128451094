const VisitPoultryValidation = (values) => {
    const { animalCount, speciesId, disease, medicineList } = values;
    const errors = {};

    if (animalCount < 0) {
        errors.animalCount = "Fjöldi má ekki vera mínustala";
    }
    if (speciesId === "") {
        errors.speciesId = "Velja þarf fuglategund";
    }
    if (Object.keys(disease) < 1) {
        errors.disease = "Velja þarf sjúkdómsgreiningu";
    }
    if (medicineList.length < 1) {
        errors.medicineList = "Velja þarf lyf";
    }

    return errors;
};

export default VisitPoultryValidation;
