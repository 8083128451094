import React from "react";
import { adminManagementArray } from "./AdminManagementData";
import AdminManagementViewSelectionListItem from "../AdminManagementViewSelectionListItem/AdminManagementViewSelectionListItem";
import "./AdminManagementViewSelectionList.css";

const AdminManagementViewSelectionList = () => {
    return (
        <div className="admin-management-selection-list">
            {adminManagementArray.map((item) => (
                <AdminManagementViewSelectionListItem
                    key={item.itemType}
                    item={item}
                />
            ))}
        </div>
    );
};

export default AdminManagementViewSelectionList;