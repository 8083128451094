import React, { useState } from "react";
import AddDiagnosesButton from "../../Visit/AddDiagnosesButton/AddDiagnosesButton";
import { Modal } from "@material-ui/core";
import VisitTabContainer from "../../Visit/VisitTabContainer/VisitTabContainer";
import VisitAreaSelection from "../VisitAreaSelection/VisitAreaSelection";
import "./VisitAquaCultureDiagnosesContainer.css";
import ConfirmationButton from "../../Visit/ConfirmationButton/ConfirmationButton";
import CancelButton from "../../Visit/CancelButton/CancelButton";
import DiseaseSelectionView from "../../Visit/DiseaseSelection/DiseaseSelectionView/DiseaseSelectionView";
import MedicineSelectionContainer from "../../Visit/MedicineSelection/MedicineSelectionContainer/MedicineSelectionContainer";
import AnimalSelectionHeader from "../../Visit/AnimalSelection/AnimalSelectionHeader/AnimalSelectionHeader";
import DiagnosesMedicineView from "../../Visit/DiagnosesMedicineSelection/DiagnosesMedicineView/DiagnosesMedicineView";
import useForm from "../../../hooks/useForm";
import visitAquaCultureValidation from "../../../validations/visitAquaCultureValidation";
import { useSelector } from "react-redux";
import { visitType } from "../../../types";
import useGetAllAquaCultureDiseases from "../../../hooks/useGetAllAquaCultureDiseases";
import VisitAquaCultureUpdateModal from "../VisitAquaCultureUpdate/VisitAquaCultureUpdateModal/VisitAquaCultureUpdateModal";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category AquaCultureVisit
 * @component holds all the components for user to create a aqua culture visit
 * @param visitAquaCultureInitState is the init state for details about aqua culture visit
 */

const visitAquaCultureInitState = {
    animalCount: 0,
    areaSize: 0,
    temperature: 0,
    biomass: 0,
    speciesId: "",
    speciesIsName: "",
    speciesEnName: "",
    siteId: "",
    siteName: "",
    veterinarianSSN: "",
    disease: {},
    medicineList: [],
    medicineTempList: []
};

const VisitAquaCultureDiagnosesContainer = ({ visitValues }) => {
    /**
     * React useState hook that keeps track of when the vist aqua culture modal is open or closed
     */
    const [modalIsOpen, setModalIsOpen] = useState(false);
    /**
     * React useState hook that keeps track of when the vist aqua culture update modal is open or closed
     */
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
    /**
     * React useState hook that keeps track of which tab is currently set
     */
    const [tabValue, setTabValue] = useState(0);
    /**
     * Counter that select which visit to update
     */
    const [visitToUpdateCounter, setVisitToUpdateCounter] = useState(0);
    /**
     * React useState hook that keeps track of when the add medicine modal is open or closed
     */
    const [addMedicineOpen, setAddMedicineOpen] = useState(false);
    /**
     * User information from redux store
     */
    const user = useSelector((state) => state.user);
    /**
     * Gets all aqua culture diseases from database
     */
    const { aquaCultureDiseases } = useGetAllAquaCultureDiseases();
    /**
     * The aqua culture visit object fixed for the hook that sends it to backend
     */
    const getDiagnosesObject = (values) => {
        const diagnosesObject = {
            animalCount: values.animalCount,
            areaSize: values.areaSize,
            biomass: values.biomass,
            temperature: values.temperature,
            disease: values.disease,
            diseaseId: values.disease.id,
            diseaseIsName: values.disease.isName,
            diseaseEnName: values.disease.enName,
            medicineList: values.medicineList,
            speciesIsName: values.speciesList[values.speciesId - 1].isName,
            speciesEnName: values.speciesList[values.speciesId - 1].enName,
            speciesId: values.speciesId,
            siteId:
                visitValues.establishmentAreas.length > 0
                    ? visitValues.establishmentAreas[values.siteId].id
                    : null,
            siteName:
                visitValues.establishmentAreas.length > 0
                    ? visitValues.establishmentAreas[values.siteId].name
                    : "",
            veterinarianSSN: user.username
        };

        return diagnosesObject;
    };
    /**
     * Function that handles open for the add medicine modal
     */
    const handleOpenModal = () => {
        setAddMedicineOpen(true);
    };
    /**
     * Function that handles close for the add medicine modal
     */
    const handleCloseModal = () => {
        setAddMedicineOpen(false);
    };
    /**
     * The submit handler pushes the the aqua culture visit details to the visit object,
     * closes the modal and clears up.
     */
    const visitAquaCultureSubmitHandler = (values) => {
        values.medicineList.forEach((medicine) => {
            medicine.medicineName = medicine.name;
        });
        const diagnosesObject = getDiagnosesObject(values);
        visitValues.diagnosesList.push(diagnosesObject);
        setModalIsOpen(false);
        visitAquaCultureInitState.disease = {};
        visitAquaCultureInitState.medicineList = [];
        visitAquaCultureInitState.medicineTempList = [];
        resetFields();
        setTabValue(0);
    };
    /**
     * onError function watches which error occurs, sending the user to
     * corresponding tab
     */
    const onError = () => {
        if (
            errors.siteId ||
            errors.animalCount ||
            errors.areaSize ||
            errors.temperature ||
            errors.biomass ||
            errors.species
        ) {
            setTabValue(0);
        } else {
            if (errors.disease) {
                setTabValue(1);
            } else {
                setTabValue(2);
            }
        }
    };
    /**
     * Hook that keeps track of when user inserts information about aqua culture visit
     */
    const { values, handleChange, errors, handleSubmit, resetFields } = useForm(
        visitAquaCultureInitState,
        visitAquaCultureValidation,
        visitAquaCultureSubmitHandler,
        onError
    );
    /**
     * Function that handles open for the aqua culture visit modal
     */
    const handleOpenAquaCultureVisitModal = () => {
        setModalIsOpen(true);
    };
    /**
     * Function that handles close for the aqua culture visit modal
     */
    const handleCloseAquaCultureVisitModal = () => {
        setModalIsOpen(false);
    };
    /**
     * Function that handles close for the update aqua culture visit modal
     */
    const handleCloseUpdateVisitModal = () => {
        setUpdateModalIsOpen(false);
    };
    /**
     * Function that handles update aqua culture visit
     */
    const handleOpenUpdateVisitModal = (counter) => {
        setVisitToUpdateCounter(counter);
        setUpdateModalIsOpen(true);
    };
    const handleUpdate = () => {
        setUpdateModalIsOpen(false);
    };
    /**
     * Function that handles tab value change
     */
    const handleTabValueChange = (event, newValue) => {
        setAddMedicineOpen(false);
        setTabValue(newValue);
    };
    return (
        <div className="visit-aqua-culture-diagnose-container">
            <div className="visit-aqua-culture-diagnoses-body">
                <div className="visit-aqua-culture-diagnoses-header">
                    <AnimalSelectionHeader values={visitValues} />
                </div>
                <div>
                    <DiagnosesMedicineView
                        visitValues={visitValues}
                        handleOpenUpdateVisitModal={handleOpenUpdateVisitModal}
                    />
                </div>
                <div className="visit-aqua-culture-add-button-area">
                    <AddDiagnosesButton
                        handleClick={handleOpenAquaCultureVisitModal}
                    />
                </div>
            </div>
            <Modal
                className="visit-aqua-culture-modal"
                open={modalIsOpen}
                onClose={handleCloseAquaCultureVisitModal}
            >
                <div className="visit-aqua-culture-modal-container">
                    <VisitTabContainer
                        handleTabValueChange={handleTabValueChange}
                        tabValue={tabValue}
                        animalSelectionTitle="Svæði"
                        hasSelectionArea={true}
                    />
                    {tabValue === 0 && (
                        <VisitAreaSelection
                            visitValues={visitValues}
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                        />
                    )}
                    {tabValue === 1 && (
                        <DiseaseSelectionView
                            diseases={aquaCultureDiseases}
                            visitDetailValues={values}
                            errors={errors}
                        />
                    )}
                    {tabValue === 2 && (
                        <MedicineSelectionContainer
                            visitDetailValues={values}
                            visitDate={visitValues.visitDate}
                            isSlaughterBan={false}
                            isMilkBan={false}
                            hasDateLastGiven={false}
                            amountLabel="Heildarmagn í vitjun"
                            handleOpenModal={handleOpenModal}
                            handleCloseModal={handleCloseModal}
                            addMedicineOpen={addMedicineOpen}
                            errors={errors}
                        />
                    )}
                    <div className="visit-aqua-culture-diagnoses-button-area">
                        <CancelButton
                            disabled={addMedicineOpen}
                            handleCancel={handleCloseAquaCultureVisitModal}
                        />
                        <ConfirmationButton
                            disabled={addMedicineOpen}
                            handleSubmit={handleSubmit}
                            title="Staðfesta Meðhöndlun"
                        />
                    </div>
                </div>
            </Modal>
            <VisitAquaCultureUpdateModal
                visitValues={visitValues}
                updateModalIsOpen={updateModalIsOpen}
                handleCloseUpdateVisitModal={handleCloseUpdateVisitModal}
                visitToUpdateCounter={visitToUpdateCounter}
                handleUpdate={handleUpdate}
            />
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitAquaCultureDiagnosesContainer.propTypes = {
    /**
     * Aqua culture visit object with diagnosesList and treatment object
     */
    visitValues: visitType
};

export default VisitAquaCultureDiagnosesContainer;
