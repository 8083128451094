import { combineReducers } from "redux";
import languagesReducer from "../reducers/languageReducer";
import userReducer from "./userReducer";
import rmlTokenReducer from "./rmlTokenReducer";

export default combineReducers({
    languages: languagesReducer,
    user: userReducer,
    rmlToken: rmlTokenReducer
});
