import { useState, useEffect } from "react";
import userService from "../services/userService";

const useGetUnRegisteredVisitsByUserId = (userId) => {
    const [unRegisteredVisits, setUnRegisteredVisits] = useState([]);
    const [
        unRegisteredVisitsIsLoading,
        setUnRegisteredVisitsIsLoading
    ] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        userService
            .getUnRegisteredVisitsByUserId(userId)
            .then((visits) => {
                setUnRegisteredVisits(visits);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setUnRegisteredVisitsIsLoading(false);
            });
    }, [userId]);
    return { unRegisteredVisits, unRegisteredVisitsIsLoading, error };
};
export default useGetUnRegisteredVisitsByUserId;
