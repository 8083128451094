import { useState, useEffect } from "react";
import furAnimalService from "../services/furAnimalService";

const useGetAllFurAnimalDiseases = () => {
    const [furAnimalDiseases, setFurAnimalDiseases] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        furAnimalService
            .getAllFurAnimalDiseases()
            .then((furAnimalDiseases) => {
                setFurAnimalDiseases(furAnimalDiseases);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { furAnimalDiseases, isLoading, error };
};
export default useGetAllFurAnimalDiseases;
