import React from "react";
import { Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import "./ConfirmationButton.css";

const ConfirmationButton = ({ disabled, handleSubmit, title }) => {
    return (
        <Button
            className="diagnoses-confirm-button"
            disabled={disabled}
            onClick={handleSubmit}
        >
            <CheckIcon className="diagnoses-confirm-icon" />
            <b>{title}</b>
        </Button>
    );
};

export default ConfirmationButton;
