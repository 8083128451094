import React, { useState } from "react";
import "./SlaughterBanSelection.css";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import AnimalBanCalendar from "../../AnimalBanCalendar/AnimalBanCalendar";

const SlaughterBanSelection = ({
    values,
    visitDetailValues,
    error,
    errorMessage,
    dateGiven,
    getDateDiffCallBack
}) => {
    const [checked, setChecked] = useState(false);
    const handleCheck = () => {
        setChecked(!checked);
        if (!checked) {
            visitDetailValues.hasInfiniteSlaughterBan = true;
        } else {
            visitDetailValues.hasInfiniteSlaughterBan = false;
            getDateDiffCallBack();
        }
    };
    return (
        <div className="slaughterban-container">
            <div className="slaughterban-calendar">
                <AnimalBanCalendar
                    visitDetailValues={visitDetailValues}
                    label="Sláturbann"
                    valueName="slaughterBanDate"
                    daysType="slaughterBanDays"
                    disabled={checked}
                    error={error}
                    errorMessage={errorMessage}
                    dateGiven={dateGiven}
                    daysTitle="Sláturbann"
                    getDateDiffCallBack={getDateDiffCallBack}
                />
            </div>

            <div className="slaughterban-infinte">
                <FormControlLabel
                    control={
                        <Checkbox checked={checked} onChange={handleCheck} />
                    }
                    label={"Óendanlegt sláturbann"}
                />
            </div>
        </div>
    );
};

export default SlaughterBanSelection;
