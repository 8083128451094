import { useState, useEffect } from "react";
import goatService from "../services/goatService";

const useCreateGoatVisit = (cb) => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [goatVisits, setGoatVisits] = useState(null);

    useEffect(() => {
        if (goatVisits && !isProcessing) {
            setProcessing(true);
            goatService
                .createGoatVisit(goatVisits)
                .then(() => {
                    setError(null);
                    cb(goatVisits[0].visitId);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setGoatVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, goatVisits, cb]);

    const handleCreateGoatVisit = (visitId, incomingVisit) => {
        if (!isProcessing) {
            var vacimList = [];
            incomingVisit.forEach((goatVisit) => {
                const visitGoatInputModelList = {
                    visitId: visitId,
                    goats: goatVisit.animalIdList.map((animalId) =>
                        animalId.toString()
                    ),
                    slaughterBanUntil: goatVisit.highestSlaughterBanDate,
                    milkBanUntil: goatVisit.highestMilkBanDate,
                    diseaseId: goatVisit.disease.id,
                    medicines: prepareMedicines(goatVisit.medicineList),
                    veterinarianSSN: goatVisit.veterinarianSSN
                };
                vacimList.push(visitGoatInputModelList);
            });
            setGoatVisits(vacimList);
        }
    };

    const prepareMedicines = (incomingMed) => {
        var medList = [];
        for (var i = 0; i < incomingMed.length; i++) {
            medList.push({
                medicineId: incomingMed[i].id,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleCreateGoatVisit, isProcessing };
};
export default useCreateGoatVisit;
