import { useState, useEffect } from "react";
import furAnimalService from "../services/furAnimalService";

const useGetAllFurAnimals = () => {
    const [furAnimals, setFurAnimals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        furAnimalService
            .getAllFurAnimals()
            .then((furAnimals) => {
                furAnimals.map(
                    (furAnimals) =>
                        (furAnimals.searchKey =
                            furAnimals.companyIsName +
                            " " +
                            furAnimals.registrationCode)
                );
                setFurAnimals(furAnimals);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { furAnimals, isLoading, error };
};
export default useGetAllFurAnimals;
