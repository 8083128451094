import React from "react";
import { useParams } from "react-router-dom";
import useGetVisitById from "../../../hooks/useGetVisitById";
import { CircularProgress } from "@material-ui/core";
import "./UserVisitDetail.css";
import VisitAquaCultureUpdateView from "../../VisitAquaCulture/VisitAquaCultureUpdate/VisitAquaCultureUpdateView/VisitAquaCultureUpdateView";
import VisitPoultryUpdateView from "../../VisitPoultry/VisitPoultryUpdate/VisitPoultryUpdateView/VisitPoultryUpdateView";
import VisitPorcineUpdateView from "../../VisitPorcine/VisitPorcineUpdate/VisitPorcineUpdateView/VisitPorcineUpdateView";
import VisitSheepUpdateView from "../../VisitSheep/VisitSheepUpdate/VisitSheepUpdateView/VisitSheepUpdateView";
import VisitCattleUpdateView from "../../VisitCattle/VisitCattleUpdate/VisitCattleUpdateView/VisitCattleUpdateView";
import VisitGoatUpdateView from "../../VisitGoat/VisitGoatUpdate/VisitGoatUpdateView/VisitGoatUpdateView";
import VisitFurAnimalsUpdateView from "../../VisitFurAnimals/VisitFurAnimalsUpdate/VisitFurAnimalsUpdateView/VisitFurAnimalsUpdateView";
import VisitHorseUpdateView from "../../VisitHorse/VisitHorseUpdate/VisitHorseUpdateView/VisitHorseUpdateView";

const UserVisitDetail = () => {
    const { id } = useParams();
    const { visit, error, isLoading } = useGetVisitById(id);
    const prepareVisit = () => {
        if (visit) {
            visit.diagnosesList.forEach((diagnoses) => {
                diagnoses.disease = {
                    id: diagnoses.diseaseId,
                    isName: diagnoses.diseaseIsName,
                    enName: diagnoses.diseaseEnName
                };
                diagnoses.medicineTempList = diagnoses.medicineList;
            });
        }
    };
    prepareVisit();
    return (
        <>
            {error ? (
                <>Error</>
            ) : (
                <>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <div className="visit-detail-container">
                            {visit.animalTypeId === 1 && (
                                <VisitAquaCultureUpdateView visit={visit} />
                            )}
                            {visit.animalTypeId === 2 && (
                                <VisitPoultryUpdateView visit={visit} />
                            )}
                            {visit.animalTypeId === 3 && (
                                <VisitPorcineUpdateView visit={visit} />
                            )}
                            {visit.animalTypeId === 4 && (
                                <VisitCattleUpdateView visit={visit} />
                            )}
                            {visit.animalTypeId === 5 && (
                                <VisitSheepUpdateView
                                    visit={visit}
                                    isLoading={isLoading}
                                />
                            )}
                            {visit.animalTypeId === 6 && (
                                <VisitHorseUpdateView
                                    visit={visit}
                                    isLoading={isLoading}
                                />
                            )}
                            {visit.animalTypeId === 7 && (
                                <VisitGoatUpdateView
                                    visit={visit}
                                    isLoading={isLoading}
                                />
                            )}
                            {visit.animalTypeId === 8 && (
                                <VisitFurAnimalsUpdateView
                                    visit={visit}
                                    isLoading={isLoading}
                                />
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default UserVisitDetail;
