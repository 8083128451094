import React from "react";
import "./VisitCattleUpdateDiagnosesList.css";
import { numberType, cattleUpdateDiagnosesList } from "../../../../types";
import VisitCattleUpdateDiagnosesListItem from "../VisitCattleUpdateDiagnosesListItem/VisitCattleUpdateDiagnosesListItem";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category CattleVisit
 * @component maps through cattle diagnoses list and spreads it to list items
 */

const VisitCattleUpdateDiagnosesList = ({
    diagnosesList,
    establishmentId,
    animals
}) => {
    return (
        <div className="user-visit-update-view-diagnoses-list">
            {diagnosesList.map((diagnoses) => (
                <VisitCattleUpdateDiagnosesListItem
                    diagnoses={diagnoses}
                    establishmentId={establishmentId}
                    key={diagnoses.id}
                    animals={animals}
                    errors={{}}
                    animalType={"nautgripi"}
                />
            ))}
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitCattleUpdateDiagnosesList.propTypes = {
    /**
     * Cattle visit object with diagnosesList and treatment object
     */
    diagnosesList: cattleUpdateDiagnosesList,
    establishmentId: numberType.isRequired
};

export default VisitCattleUpdateDiagnosesList;
