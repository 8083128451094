import React, { useState } from "react";
import AddDiagnosesButton from "../../Visit/AddDiagnosesButton/AddDiagnosesButton";
import { Modal } from "@material-ui/core";
import VisitTabContainer from "../../Visit/VisitTabContainer/VisitTabContainer";
import ConfirmationButton from "../../Visit/ConfirmationButton/ConfirmationButton";
import CancelButton from "../../Visit/CancelButton/CancelButton";
import DiseaseSelectionView from "../../Visit/DiseaseSelection/DiseaseSelectionView/DiseaseSelectionView";
import MedicineSelectionContainer from "../../Visit/MedicineSelection/MedicineSelectionContainer/MedicineSelectionContainer";
import AnimalSelectionHeader from "../../Visit/AnimalSelection/AnimalSelectionHeader/AnimalSelectionHeader";
import DiagnosesMedicineView from "../../Visit/DiagnosesMedicineSelection/DiagnosesMedicineView/DiagnosesMedicineView";
import useForm from "../../../hooks/useForm";
import { useSelector } from "react-redux";
import "./VisitGoatDiagnosesContainer.css";
import { visitType } from "../../../types";
import useGetAllSheepDiseases from "../../../hooks/useGetAllSheepDiseases";
import VisitGoatValidation from "../../../validations/visitGoatValidations";
import useGetGoatsByFarmId from "../../../hooks/useGetGoatsByFarmId";
import VisitAnimalSelection from "../../Visit/VisitAnimalSelection/VisitAnimalSelection";
import VisitGoatUpdateModal from "../VisitGoatUpdate/VisitGoatUpdateModal/VisitGoatUpdateModal";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category GoatVisit
 * @component holds all the components for user to create a goat visit
 * @param visitGoatInitState is the init state for details about goat visit
 */

const visitGoatInitState = {
    veterinarianSSN: "",
    animalIdList: [],
    animalList: [],
    disease: {},
    medicineList: [],
    medicineTempList: [],
    milkBanDate: "",
    slaughterBanDate: "",
    hasInfiniteSlaughterBan: false,
    highestSlaughterBanDate: "",
    highestMilkBanDate: ""
};

const VisitGoatDiagnosesContainer = ({ visitValues }) => {
    /**
     * React useState hook that keeps track of when the vist goat modal is open or closed
     */
    const [modalIsOpen, setModalIsOpen] = useState(false);
    /**
     * React useState hook that keeps track of when the vist goat update modal is open or closed
     */
    const [updateGoatModalIsOpen, setUpdateGoatModalIsOpen] = useState(false);
    /**
     * React useState hook that keeps track of which tab is currently set
     */
    const [tabValue, setTabValue] = useState(0);
    /**
     * Counter that select which visit to update
     */
    const [visitToUpdateCounter, setVisitToUpdateCounter] = useState(0);
    /**
     * React useState hook that keeps track of when the add medicine modal is open or closed
     */
    const [addMedicineOpen, setAddMedicineOpen] = useState(false);
    /**
     * User information from redux store
     */
    const user = useSelector((state) => state.user);
    /**
     * Gets all goat diseases from database
     */
    const { sheepDiseases } = useGetAllSheepDiseases();
    /**
     * The goat visit object fixed for the hook that sends it to backend
     */
    const getDiagnosesObject = (values) => {
        const diagnosesObject = {
            animalIdList: values.animalIdList,
            animalList: values.animalList,
            disease: values.disease,
            diseaseId: values.disease.id,
            diseaseIsName: values.disease.isName,
            diseaseEnName: values.disease.enName,
            medicineList: values.medicineList,
            veterinarianSSN: user.username,
            animalCount: values.animalIdList.length,
            slaughterBanDate: values.slaughterBanDate,
            highestSlaughterBanDate: values.highestSlaughterBanDate,
            hasInfiniteSlaughterBan: values.hasInfiniteSlaughterBan,
            milkBanDate: values.milkBanDate,
            highestMilkBanDate: values.highestMilkBanDate
        };

        return diagnosesObject;
    };
    /**
     * Function that handles open for the add medicine modal
     */
    const handleOpenModal = () => {
        setAddMedicineOpen(true);
    };
    /**
     * Function that handles close for the add medicine modal
     */
    const handleCloseModal = () => {
        setAddMedicineOpen(false);
    };
    /**
     * Gets goat list from selected farm from the RML database
     */
    const { goatList } = useGetGoatsByFarmId(visitValues.establishmentId);
    /**
     * The submit handler pushes the the goat visit details to the visit object,
     * closes the modal and clears up.
     */
    const visitGoatSubmitHandler = (values) => {
        values.medicineList.forEach((medicine) => {
            medicine.medicineName = medicine.name;
        });
        const diagnosesObject = getDiagnosesObject(values);
        visitValues.diagnosesList.push(diagnosesObject);
        setModalIsOpen(false);
        visitGoatInitState.disease = {};
        visitGoatInitState.medicineList = [];
        visitGoatInitState.medicineTempList = [];
        visitGoatInitState.animalIdList = [];
        visitGoatInitState.animalList = [];
        visitGoatInitState.slaughterBanDate = "";
        visitGoatInitState.milkBanDate = "";
        visitGoatInitState.highestMilkBanDate = "";
        visitGoatInitState.highestSlaughterBanDate = "";
        visitGoatInitState.hasInfiniteSlaughterBan = false;
        goatList.map((goat) => (goat.isSelected = false));
        resetFields();
        setTabValue(0);
    };

    /**
     * onError function watches which error occurs, sending the user to
     * corresponding tab
     */
    const onError = () => {
        if (errors.animalIdList) {
            setTabValue(0);
        } else {
            if (errors.disease) {
                setTabValue(1);
            } else {
                setTabValue(2);
            }
        }
    };
    /**
     * Hook that keeps track of when user inserts information about goat visit
     */
    const { values, errors, handleSubmit, resetFields } = useForm(
        visitGoatInitState,
        VisitGoatValidation,
        visitGoatSubmitHandler,
        onError
    );
    /**
     * Function that handles open for the goat visit modal
     */
    const handleOpenGoatVisitModal = () => {
        setModalIsOpen(true);
    };
    /**
     * Function that handles close for the goat visit modal
     */
    const handleCloseGoatVisitModal = () => {
        setModalIsOpen(false);
    };
    const handleUpdate = () => {
        goatList.map((goat) => (goat.isSelected = false));
        setUpdateGoatModalIsOpen(false);
    };
    /**
     * Function that handles update goat visit
     */
    const handleOpenUpdateVisitModal = (counter) => {
        setUpdateGoatModalIsOpen(true);
        setVisitToUpdateCounter(counter);
    };
    /**
     * Function that handles close for the update goat visit modal
     */
    const handleCloseUpdateVisitModal = () => {
        goatList.map((goat) => (goat.isSelected = false));
        setUpdateGoatModalIsOpen(false);
    };
    /**
     * Function that handles tab value change
     */
    const handleTabValueChange = (event, newValue) => {
        setAddMedicineOpen(false);
        setTabValue(newValue);
    };

    return (
        <div className="visit-goat-diagnose-container">
            <div className="visit-goat-diagnoses-body">
                <div className="visit-goat-diagnoses-header">
                    <AnimalSelectionHeader values={visitValues} />
                </div>
                <div>
                    <DiagnosesMedicineView
                        visitValues={visitValues}
                        handleOpenUpdateVisitModal={handleOpenUpdateVisitModal}
                    />
                </div>
                <div className="visit-goat-add-button-area">
                    <AddDiagnosesButton
                        handleClick={handleOpenGoatVisitModal}
                    />
                </div>
            </div>
            <Modal
                className="visit-goat-modal"
                open={modalIsOpen}
                onClose={handleCloseGoatVisitModal}
            >
                <div className="visit-goat-modal-container">
                    <VisitTabContainer
                        handleTabValueChange={handleTabValueChange}
                        tabValue={tabValue}
                        animalSelectionTitle="Geitur"
                        hasSelectionArea={true}
                    />
                    {tabValue === 0 && (
                        <VisitAnimalSelection
                            visitValues={visitValues}
                            values={values}
                            animals={goatList}
                            errors={errors}
                            animalType="geitur"
                        />
                    )}
                    {tabValue === 1 && (
                        <DiseaseSelectionView
                            diseases={sheepDiseases}
                            visitDetailValues={values}
                            errors={errors}
                        />
                    )}
                    {tabValue === 2 && (
                        <MedicineSelectionContainer
                            visitDetailValues={values}
                            visitDate={visitValues.visitDate}
                            isSlaughterBan={true}
                            isMilkBan={true}
                            hasDateLastGiven={true}
                            amountLabel="Heildarmagn í vitjun"
                            handleOpenModal={handleOpenModal}
                            handleCloseModal={handleCloseModal}
                            addMedicineOpen={addMedicineOpen}
                            errors={errors}
                        />
                    )}
                    <div className="visit-goat-diagnoses-button-area">
                        <CancelButton
                            disabled={addMedicineOpen}
                            handleCancel={handleCloseGoatVisitModal}
                        />
                        <ConfirmationButton
                            disabled={addMedicineOpen}
                            handleSubmit={handleSubmit}
                            title="Staðfesta Meðhöndlun"
                        />
                    </div>
                </div>
            </Modal>
            <VisitGoatUpdateModal
                visitValues={visitValues}
                updateGoatModalIsOpen={updateGoatModalIsOpen}
                handleCloseUpdateVisitModal={handleCloseUpdateVisitModal}
                visitToUpdateCounter={visitToUpdateCounter}
                handleUpdate={handleUpdate}
                animals={goatList}
                errors={errors}
            />
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitGoatDiagnosesContainer.propTypes = {
    visitValues: visitType
};

export default VisitGoatDiagnosesContainer;
