import React from "react";
import VisitAquaCultureUpdateDiagnosesListItem from "../VisitAquaCultureUpdateDiagnosesListItem/VisitAquaCultureUpdateDiagnosesListItem";
import "./VisitAquaCultureUpdateDiagnosesList.css";
import { numberType, aquaCultureUpdateDiagnoses } from "../../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category AquaCultureVisit
 * @component maps through aqua culture diagnoses list and spreads it to list items
 */

const VisitAquaCultureUpdateDiagnosesList = ({
    diagnosesList,
    establishmentId
}) => {
    return (
        <div className="user-visit-update-view-diagnoses-list">
            {diagnosesList.map((diagnoses, i) => (
                <VisitAquaCultureUpdateDiagnosesListItem
                    diagnoses={diagnoses}
                    establishmentId={establishmentId}
                    key={diagnoses.id}
                />
            ))}
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */


VisitAquaCultureUpdateDiagnosesList.propTypes = {  

    //Aqua culture visit object with diagnosesList and treatment object
    diagnosesList: aquaCultureUpdateDiagnoses,
    establishmentId: numberType.isRequired
};


export default VisitAquaCultureUpdateDiagnosesList;
