import React, { useState } from 'react';
import useSearchBar from "../../../../../hooks/useSearchBar";
import SearchBar from "../../../../SearchBar/SearchBar";
import AdminMedicineSelectionList from "../AdminMedicineSelectionList/AdminMedicineSelectionList";
import Switch from "react-switch";
import "./AdminMedicineSelectView.css"
import { Link } from "react-router-dom";
import ConfirmationButton from "../../../../Visit/ConfirmationButton/ConfirmationButton";

const AdminMedicineSelectView = ({ medicines }) => {
    
    const [showAllMedicine, setShowAllMedicine] = useState(false);
    const [medicinesToShow, setMedicinesToShow] = useState(medicines.filter(x => x.active === true)) // start with active medicines
    
    const handleSwitch = () => {
        setShowAllMedicine(!showAllMedicine);
        if(showAllMedicine) {
            setMedicinesToShow(medicines.filter(x => x.active === true))
        } else {
            setMedicinesToShow(medicines)
        }
    };

    const { searchResult, searchTerm, handleChange } = useSearchBar(
        medicinesToShow,
        "name"
    );
    
    const meds = searchResult.slice(0, 6);

    return (
        <div className="admin-medicine-select-view-container">
            <div className="admin-medicine-select-view-upper">
                <div className="admin-medicine-select-view-show-active">
                    <b>Sýna óvirk lyf</b>
                    <Switch
                        onChange={() => handleSwitch()}
                        checked={showAllMedicine}
                        className="react-switch"
                    />
                </div>
                <div className="admin-medicine-view-add-button">
                    <Link to={`/admin/medicine/medicinecreate`}>
                        <ConfirmationButton title="Skrá nýtt lyf" />
                    </Link>
                </div>
            </div>
            <div className="admin-medicine-select-view-middle">
                <div className="admin-medicine-select-view-search">
                        <SearchBar
                            searchTerm={searchTerm}
                            handleChange={handleChange}
                            placeHolder="Leita að lyfi"
                        />
                </div>
            </div>
            <div className="admin-medicine-select-view-lower">
                <AdminMedicineSelectionList
                    medicines={meds}
                />
            </div>
        </div>
    )
} 

export default AdminMedicineSelectView;