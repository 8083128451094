import React, { useState } from "react";
import DiagnosesMedicineCard from "../DiagnosesMedicineCard/DiagnosesMedicineCard";
import "./DiagnosesMedicineCardTable.css";

const DiagnosesMedicineCardTable = ({
    visitValues,
    handleOpenUpdateVisitModal
}) => {
    const { diagnosesList, animalTypeId } = visitValues;
    const [trigger, reload] = useState(false);

    const handleRemove = (counter) => {
        diagnosesList.splice(counter, 1);
        reload(!trigger);
    };
    return (
        <div className="diagnoses-medicine-card-table-container">
            {diagnosesList.length > 0 ? (
                <div>
                    {diagnosesList.map((diagnoses, i) => (
                        <DiagnosesMedicineCard
                            key={i}
                            diagnoses={diagnoses}
                            animalTypeId={animalTypeId}
                            counter={i}
                            handleRemove={handleRemove}
                            handleOpenUpdateVisitModal={
                                handleOpenUpdateVisitModal
                            }
                        />
                    ))}
                </div>
            ) : (
                <div className="diagnoses-table-empty">
                    <i>Engin skráð meðhöndlun eins og er ...</i>
                </div>
            )}
        </div>
    );
};

export default DiagnosesMedicineCardTable;
