import React, { useState } from "react";
import AddDiagnosesButton from "../../Visit/AddDiagnosesButton/AddDiagnosesButton";
import { Modal } from "@material-ui/core";
import VisitTabContainer from "../../Visit/VisitTabContainer/VisitTabContainer";
import ConfirmationButton from "../../Visit/ConfirmationButton/ConfirmationButton";
import CancelButton from "../../Visit/CancelButton/CancelButton";
import DiseaseSelectionView from "../../Visit/DiseaseSelection/DiseaseSelectionView/DiseaseSelectionView";
import MedicineSelectionContainer from "../../Visit/MedicineSelection/MedicineSelectionContainer/MedicineSelectionContainer";
import AnimalSelectionHeader from "../../Visit/AnimalSelection/AnimalSelectionHeader/AnimalSelectionHeader";
import DiagnosesMedicineView from "../../Visit/DiagnosesMedicineSelection/DiagnosesMedicineView/DiagnosesMedicineView";
import useForm from "../../../hooks/useForm";
import { useSelector } from "react-redux";
import "./VisitHorseDiagnosesContainer.css";
import visitHorseValidation from "../../../validations/visitHorseValidation";
import { visitType } from "../../../types";
import useGetAllHorseDiseases from "../../../hooks/useGetAllHorseDiseases";
import VisitHorseUpdateModal from "../VisitHorseUpdate/VisitHorseUpdateModal/VisitHorseUpdateModal";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category HorseVisit
 * @component holds all the components for user to create a horse visit
 * @param visithorseInitState is the init state for details about horse visit
 */

const visitHorseInitState = {
    veterinarianSSN: "",
    disease: {},
    medicineList: [],
    medicineTempList: [],
    slaughterBanDate: "",
    hasInfiniteSlaughterBan: false,
    highestSlaughterBanDate: ""
};

const VisitHorseDiagnosesContainer = ({ visitValues }) => {
    /**
     * React useState hook that keeps track of when the vist horse modal is open or closed
     */
    const [modalIsOpen, setModalIsOpen] = useState(false);
    /**
     * React useState hook that keeps track of when the vist horse update modal is open or closed
     */
    const [updateHorseModalIsOpen, setUpdateHorseModalIsOpen] = useState(false);
    /**
     * Counter that select which visit to update
     */
    const [visitToUpdateCounter, setVisitToUpdateCounter] = useState(0);
    /**
     * React useState hook that keeps track of which tab is currently set
     */
    const [tabValue, setTabValue] = useState(0);
    /**
     * React useState hook that keeps track of when the add medicine modal is open or closed
     */
    const [addMedicineOpen, setAddMedicineOpen] = useState(false);
    /**
     * User information from redux store
     */
    const user = useSelector((state) => state.user);
    /**
     * Gets all horse diseases from database
     */
    const { horseDiseases } = useGetAllHorseDiseases();
    /**
     * The horse visit object fixed for the hook that sends it to backend
     */
    const getDiagnosesObject = (values) => {
        const diagnosesObject = {
            disease: values.disease,
            diseaseId: values.disease.id,
            diseaseIsName: values.disease.isName,
            diseaseEnName: values.disease.enName,
            animalCount: visitValues.horseList.length,
            medicineList: values.medicineList,
            veterinarianSSN: user.username,
            slaughterBanDate: values.slaughterBanDate,
            highestSlaughterBanDate: values.highestSlaughterBanDate,
            hasInfiniteSlaughterBan: values.hasInfiniteSlaughterBan
        };

        return diagnosesObject;
    };
    /**
     * Function that handles open for the add medicine modal
     */
    const handleOpenModal = () => {
        setAddMedicineOpen(true);
    };
    /**
     * Function that handles close for the add medicine modal
     */
    const handleCloseModal = () => {
        setAddMedicineOpen(false);
    };
    /**
     * The submit handler pushes the the horse visit details to the visit object,
     * closes the modal and clears up.
     */
    const visitHorseSubmitHandler = (values) => {
        values.medicineList.forEach((medicine) => {
            medicine.medicineName = medicine.name;
        });
        const diagnosesObject = getDiagnosesObject(values);
        visitValues.diagnosesList.push(diagnosesObject);
        setModalIsOpen(false);
        visitHorseInitState.disease = {};
        visitHorseInitState.medicineList = [];
        visitHorseInitState.medicineTempList = [];
        visitHorseInitState.slaughterBanDate = "";
        visitHorseInitState.hasInfiniteSlaughterBan = false;
        visitHorseInitState.highestSlaughterBanDate = "";
        resetFields();
        setTabValue(0);
    };
    /**
     * onError function watches which error occurs, sending the user to
     * corresponding tab
     */
    const onError = () => {
        if (errors.disease) {
            setTabValue(0);
        } else {
            setTabValue(1);
        }
    };
    /**
     * Hook that keeps track of when user inserts information about horse visit
     */
    const { values, errors, handleSubmit, resetFields } = useForm(
        visitHorseInitState,
        visitHorseValidation,
        visitHorseSubmitHandler,
        onError
    );
    /**
     * Function that handles close for the update horse visit modal
     */
    const handleCloseUpdateVisitModal = () => {
        setUpdateHorseModalIsOpen(false);
    };
    const handleUpdate = () => {
        setUpdateHorseModalIsOpen(false);
    };
    /**
     * Function that handles update horse visit
     */
    const handleOpenUpdateVisitModal = (counter) => {
        setUpdateHorseModalIsOpen(true);
        setVisitToUpdateCounter(counter);
    };
    /**
    /**
     * Function that handles open for the horse visit modal
     */
    const handleOpenHorseVisitModal = () => {
        setModalIsOpen(true);
    };
    /**
     * Function that handles close for the horse visit modal
     */
    const handleCloseHorseVisitModal = () => {
        setModalIsOpen(false);
    };
    /**
     * Function that handles tab value change
     */
    const handleTabValueChange = (event, newValue) => {
        setAddMedicineOpen(false);
        setTabValue(newValue);
    };

    return (
        <div className="visit-horse-diagnose-container">
            <div className="visit-horse-diagnoses-body">
                <div className="visit-horse-diagnoses-header">
                    <AnimalSelectionHeader values={visitValues} />
                </div>
                <div>
                    <DiagnosesMedicineView
                        visitValues={visitValues}
                        handleOpenUpdateVisitModal={handleOpenUpdateVisitModal}
                    />
                </div>
                <div className="visit-horse-add-button-area">
                    <AddDiagnosesButton
                        handleClick={handleOpenHorseVisitModal}
                    />
                </div>
            </div>
            <Modal
                className="visit-horse-modal"
                open={modalIsOpen}
                onClose={handleCloseHorseVisitModal}
            >
                <div className="visit-horse-modal-container">
                    <VisitTabContainer
                        handleTabValueChange={handleTabValueChange}
                        tabValue={tabValue}
                        animalSelectionTitle="Hópar"
                        hasSelectionArea={false}
                    />
                    {tabValue === 0 && (
                        <DiseaseSelectionView
                            diseases={horseDiseases}
                            visitDetailValues={values}
                            errors={errors}
                        />
                    )}
                    {tabValue === 1 && (
                        <MedicineSelectionContainer
                            visitDetailValues={values}
                            visitDate={visitValues.visitDate}
                            isSlaughterBan={true}
                            isMilkBan={false}
                            hasDateLastGiven={true}
                            amountLabel="Heildarmagn í vitjun"
                            handleOpenModal={handleOpenModal}
                            handleCloseModal={handleCloseModal}
                            addMedicineOpen={addMedicineOpen}
                            errors={errors}
                        />
                    )}
                    <div className="visit-horse-diagnoses-button-area">
                        <CancelButton
                            disabled={addMedicineOpen}
                            handleCancel={handleCloseHorseVisitModal}
                        />
                        <ConfirmationButton
                            disabled={addMedicineOpen}
                            handleSubmit={handleSubmit}
                            title="Staðfesta Meðhöndlun"
                        />
                    </div>
                </div>
            </Modal>
            <VisitHorseUpdateModal
                visitValues={visitValues}
                updateHorseModalIsOpen={updateHorseModalIsOpen}
                handleCloseUpdateVisitModal={handleCloseUpdateVisitModal}
                visitToUpdateCounter={visitToUpdateCounter}
                handleUpdate={handleUpdate}
            />
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitHorseDiagnosesContainer.propTypes = {
    visitValues: visitType
};

export default VisitHorseDiagnosesContainer;
