/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/petvisits`;

const getAllPetSpecies = () => {
    return fetch(`${apiEndpoint}/species`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const createPetVisit = (petVisit) => {
    return fetch(apiEndpoint, {
        method: "POST",
        body: JSON.stringify(petVisit),
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

export default {
    getAllPetSpecies,
    createPetVisit
};
