import { useState, useEffect } from "react";
import cattleService from "../services/cattleService";

const useCreateCattleVisit = (cb) => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [cattleVisits, setCattleVisits] = useState(null);

    useEffect(() => {
        if (cattleVisits && !isProcessing) {
            setProcessing(true);
            cattleService
                .createCattleVisit(cattleVisits)
                .then(() => {
                    setError(null);
                    cb(cattleVisits[0].visitId);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setCattleVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, cattleVisits, cb]);

    const handleCreateCattleVisit = (visitId, incomingVisit) => {
        if (!isProcessing) {
            var vacimList = [];
            incomingVisit.forEach((cattleVisit) => {
                const visitCattleInputModelList = {
                    visitId: visitId,
                    cattles: cattleVisit.animalIdList.map((animalId) =>
                        animalId.toString()
                    ),
                    slaughterBanUntil: cattleVisit.highestSlaughterBanDate,
                    milkBanUntil: cattleVisit.highestMilkBanDate,
                    diseaseId: cattleVisit.disease.id,
                    medicines: prepareMedicines(cattleVisit.medicineList),
                    veterinarianSSN: cattleVisit.veterinarianSSN
                };
                vacimList.push(visitCattleInputModelList);
            });
            setCattleVisits(vacimList);
        }
    };

    const prepareMedicines = (incomingMed) => {
        var medList = [];
        for (var i = 0; i < incomingMed.length; i++) {
            medList.push({
                medicineId: incomingMed[i].id,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleCreateCattleVisit, isProcessing };
};
export default useCreateCattleVisit;
