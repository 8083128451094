import React from "react";
import { Button, Modal, Paper } from "@material-ui/core";
import VisitPorcineUpdateDiagnosesListItem from "../VisitPorcineUpdateDiagnosesListItem/VisitPorcineUpdateDiagnosesListItem";
import "./VisitPorcineUpdateModal.css";

const VisitPorcineUpdateModal = ({
    visitValues,
    updatePorcineModalIsOpen,
    handleCloseUpdateVisitModal,
    visitToUpdateCounter,
    handleUpdate
}) => {
    return (
        <Modal
            className="update-visit-porcine-modal"
            open={updatePorcineModalIsOpen}
            onClose={handleCloseUpdateVisitModal}
        >
            <Paper className="update-visit-porcine-paper">
                <div className="update-visit-porcine-modal-header">
                    Uppfæra vitjun
                </div>
                <VisitPorcineUpdateDiagnosesListItem
                    diagnoses={visitValues.diagnosesList[visitToUpdateCounter]}
                    establishmentId={visitValues.establishmentId}
                />
                <div className="diagnoses-medicine-view-update-card-button-area">
                    <Button
                        className="diagnoses-medicine-view-update-card-cancel-button"
                        onClick={handleCloseUpdateVisitModal}
                    >
                        <b>Til baka</b>
                    </Button>
                    <Button
                        className="diagnoses-medicine-view-update-card-confirm-button"
                        onClick={handleUpdate}
                    >
                        <b>Vista</b>
                    </Button>
                </div>
            </Paper>
        </Modal>
    );
};

export default VisitPorcineUpdateModal;
