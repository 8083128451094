import { useState, useEffect } from "react";
import porcineService from "../services/porcineService";

const useCreatePorcineVisit = (cb) => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [porcineVisits, setPorcineVisits] = useState(null);

    useEffect(() => {
        if (porcineVisits && !isProcessing) {
            setProcessing(true);
            porcineService
                .createPorcineVisit(porcineVisits)
                .then(() => {
                    setError(null);
                    cb(porcineVisits[0].visitId);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setPorcineVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, porcineVisits, cb]);

    const handleCreatePorcineVisit = (visitId, incomingVisit) => {
        if (!isProcessing) {
            var vpimList = [];

            incomingVisit.forEach((porcineVisit) => {
                const visitPorcineInputModelList = {
                    visitId: visitId,
                    diseaseId: porcineVisit.disease.id,
                    animalCount: parseInt(porcineVisit.animalCount),
                    veterinarianSSN: porcineVisit.veterinarianSSN,
                    medicines: prepareMedicines(porcineVisit.medicineList)
                };
                vpimList.push(visitPorcineInputModelList);
            });
            setPorcineVisits(vpimList);
        }
    };

    const prepareMedicines = (incomingMed) => {
        var medList = [];
        for (var i = 0; i < incomingMed.length; i++) {
            medList.push({
                medicineId: incomingMed[i].id,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleCreatePorcineVisit, isProcessing };
};
export default useCreatePorcineVisit;
