import { useState, useEffect } from "react";
import strengthTypeService from "../services/strengthTypeService";

const useGetAllStrengthTypes = () => {
    const [strengthTypes, setStrengthTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        strengthTypeService
            .getAllStrengthTypes()
            .then((strengthTypes) => {
                setStrengthTypes(strengthTypes);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { strengthTypes, isLoading, error };
};

export default useGetAllStrengthTypes;
