import React from "react";
import DiagnosesMedicineCardTable from "../DiagnosesMedicineCardTable/DiagnosesMedicineCardTable";
import "./DiagnosesMedicineView.css";

const DiagnosesMedicineView = ({ visitValues, handleOpenUpdateVisitModal }) => {
    return (
        <div>
            <DiagnosesMedicineCardTable
                visitValues={visitValues}
                handleOpenUpdateVisitModal={handleOpenUpdateVisitModal}
            />
        </div>
    );
};

export default DiagnosesMedicineView;
