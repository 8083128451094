import { useState, useEffect } from "react";
import petService from "../services/petService";

const useCreatePetVisit = () => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [petVisits, setPetVisits] = useState(null);
    useEffect(() => {
        if (petVisits && !isProcessing) {
            setProcessing(true);
            petService
                .createPetVisit(petVisits)
                .then(() => {
                    setError(null);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setPetVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, petVisits]);

    const handleCreatePetVisit = (incomingVisit) => {
        if (!isProcessing) {
            const visitPetInputModel = {
                speciesId: incomingVisit.speciesId,
                animalCount: parseInt(incomingVisit.animalCount),
                veterinarianSSN: incomingVisit.veterinarianSSN,
                veterinarianId: incomingVisit.veterinarianId,
                medicineId: incomingVisit.medicineList[0].id,
                medicineAmount: parseFloat(
                    incomingVisit.medicineList[0].medicineAmount
                )
            };
            setPetVisits(visitPetInputModel);
        }
    };
    return { error, handleCreatePetVisit, isProcessing };
};
export default useCreatePetVisit;
