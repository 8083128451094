import React, { useState } from "react";
import AddDiagnosesButton from "../../Visit/AddDiagnosesButton/AddDiagnosesButton";
import { Modal } from "@material-ui/core";
import VisitTabContainer from "../../Visit/VisitTabContainer/VisitTabContainer";
import ConfirmationButton from "../../Visit/ConfirmationButton/ConfirmationButton";
import CancelButton from "../../Visit/CancelButton/CancelButton";
import DiseaseSelectionView from "../../Visit/DiseaseSelection/DiseaseSelectionView/DiseaseSelectionView";
import MedicineSelectionContainer from "../../Visit/MedicineSelection/MedicineSelectionContainer/MedicineSelectionContainer";
import AnimalSelectionHeader from "../../Visit/AnimalSelection/AnimalSelectionHeader/AnimalSelectionHeader";
import DiagnosesMedicineView from "../../Visit/DiagnosesMedicineSelection/DiagnosesMedicineView/DiagnosesMedicineView";
import useForm from "../../../hooks/useForm";
import { useSelector } from "react-redux";
import "./VisitFurAnimalsDiagnosesContainer.css";
import visitFurAnimalValidation from "../../../validations/visitFurAnimalValidation";
import { visitType } from "../../../types";
import useGetAllFurAnimalDiseases from "../../../hooks/useGetAllFurAnimalDiseases";
import VisitFurAnimalsUpdateModal from "../VisitFurAnimalsUpdate/VisitFurAnimalsUpdateModal/VisitFurAnimalsUpdateModal";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category FurAnimalsVisit
 * @component holds all the components for user to create a furAnimals visit
 * @param visitFurAnimalsInitState is the init state for details about furAnimals visit
 */

const visitFurAnimalsInitState = {
    veterinarianSSN: "",
    disease: {},
    medicineList: [],
    medicineTempList: []
};

const VisitFurAnimalsDiagnosesContainer = ({ visitValues }) => {
    /**
     * React useState hook that keeps track of when the vist furAnimals modal is open or closed
     */
    const [modalIsOpen, setModalIsOpen] = useState(false);
    /**
     * React useState hook that keeps track of when the vist furAnimals update modal is open or closed
     */
    const [
        updateFurAnimalsModalIsOpen,
        setUpdateFurAnimalsModalIsOpen
    ] = useState(false);
    /**
     * Counter that select which visit to update
     */
    const [visitToUpdateCounter, setVisitToUpdateCounter] = useState(0);
    /**
     * React useState hook that keeps track of which tab is currently set
     */
    const [tabValue, setTabValue] = useState(0);
    /**
     * React useState hook that keeps track of when the add medicine modal is open or closed
     */
    const [addMedicineOpen, setAddMedicineOpen] = useState(false);
    /**
     * User information from redux store
     */
    const user = useSelector((state) => state.user);
    /**
     * Gets all furAnimals diseases from database
     */
    const { furAnimalDiseases } = useGetAllFurAnimalDiseases();
    /**
     * The furAnimals visit object fixed for the hook that sends it to backend
     */
    const getDiagnosesObject = (values) => {
        const diagnosesObject = {
            disease: values.disease,
            diseaseId: values.disease.id,
            diseaseIsName: values.disease.isName,
            diseaseEnName: values.disease.enName,
            medicineList: values.medicineList,
            veterinarianSSN: user.username
        };

        return diagnosesObject;
    };
    /**
     * Function that handles open for the add medicine modal
     */
    const handleOpenModal = () => {
        setAddMedicineOpen(true);
    };
    /**
     * Function that handles close for the add medicine modal
     */
    const handleCloseModal = () => {
        setAddMedicineOpen(false);
    };
    /**
     * The submit handler pushes the the furAnimals visit details to the visit object,
     * closes the modal and clears up.
     */
    const visitFurAnimalsSubmitHandler = (values) => {
        values.medicineList.forEach((medicine) => {
            medicine.medicineName = medicine.name;
        });
        const diagnosesObject = getDiagnosesObject(values);
        visitValues.diagnosesList.push(diagnosesObject);
        setModalIsOpen(false);
        visitFurAnimalsInitState.disease = {};
        visitFurAnimalsInitState.medicineList = [];
        visitFurAnimalsInitState.medicineTempList = [];
        resetFields();
        setTabValue(0);
    };
    /**
     * onError function watches which error occurs, sending the user to
     * corresponding tab
     */
    const onError = () => {
        if (errors.disease) {
            setTabValue(0);
        } else {
            setTabValue(1);
        }
    };
    /**
     * Hook that keeps track of when user inserts information about furAnimals visit
     */
    const { values, errors, handleSubmit, resetFields } = useForm(
        visitFurAnimalsInitState,
        visitFurAnimalValidation,
        visitFurAnimalsSubmitHandler,
        onError
    );
    /**
     * Function that handles close for the update furAnimals visit modal
     */
    const handleCloseUpdateVisitModal = () => {
        setUpdateFurAnimalsModalIsOpen(false);
    };
    const handleUpdate = () => {
        setUpdateFurAnimalsModalIsOpen(false);
    };
    /**
     * Function that handles update furAnimals visit
     */
    const handleOpenUpdateVisitModal = (counter) => {
        setUpdateFurAnimalsModalIsOpen(true);
        setVisitToUpdateCounter(counter);
    };
    /**
    /**
     * Function that handles open for the furAnimals visit modal
     */
    const handleOpenFurAnimalsVisitModal = () => {
        setModalIsOpen(true);
    };
    /**
     * Function that handles close for the furAnimals visit modal
     */
    const handleCloseFurAnimalsVisitModal = () => {
        setModalIsOpen(false);
    };
    /**
     * Function that handles tab value change
     */
    const handleTabValueChange = (event, newValue) => {
        setAddMedicineOpen(false);
        setTabValue(newValue);
    };
    return (
        <div className="visit-furanimals-diagnose-container">
            <div className="visit-furanimals-diagnoses-body">
                <div className="visit-furanimals-diagnoses-header">
                    <AnimalSelectionHeader values={visitValues} />
                </div>
                <div>
                    <DiagnosesMedicineView
                        visitValues={visitValues}
                        handleOpenUpdateVisitModal={handleOpenUpdateVisitModal}
                    />
                </div>
                <div className="visit-furanimals-add-button-area">
                    <AddDiagnosesButton
                        handleClick={handleOpenFurAnimalsVisitModal}
                    />
                </div>
            </div>
            <Modal
                className="visit-furanimals-modal"
                open={modalIsOpen}
                onClose={handleCloseFurAnimalsVisitModal}
            >
                <div className="visit-furanimals-modal-container">
                    <VisitTabContainer
                        handleTabValueChange={handleTabValueChange}
                        tabValue={tabValue}
                        animalSelectionTitle="Hópar"
                        hasSelectionArea={false}
                    />
                    {tabValue === 0 && (
                        <DiseaseSelectionView
                            diseases={furAnimalDiseases}
                            visitDetailValues={values}
                            errors={errors}
                        />
                    )}
                    {tabValue === 1 && (
                        <MedicineSelectionContainer
                            visitDetailValues={values}
                            visitDate={visitValues.visitDate}
                            isSlaughterBan={false}
                            isMilkBan={false}
                            hasDateLastGiven={false}
                            amountLabel="Heildarmagn í vitjun"
                            handleOpenModal={handleOpenModal}
                            handleCloseModal={handleCloseModal}
                            addMedicineOpen={addMedicineOpen}
                            errors={errors}
                        />
                    )}
                    <div className="visit-furanimals-diagnoses-button-area">
                        <CancelButton
                            disabled={addMedicineOpen}
                            handleCancel={handleCloseFurAnimalsVisitModal}
                        />
                        <ConfirmationButton
                            disabled={addMedicineOpen}
                            handleSubmit={handleSubmit}
                            title="Staðfesta Meðhöndlun"
                        />
                    </div>
                </div>
            </Modal>
            <VisitFurAnimalsUpdateModal
                visitValues={visitValues}
                updateFurAnimalsModalIsOpen={updateFurAnimalsModalIsOpen}
                handleCloseUpdateVisitModal={handleCloseUpdateVisitModal}
                visitToUpdateCounter={visitToUpdateCounter}
                handleUpdate={handleUpdate}
            />
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitFurAnimalsDiagnosesContainer.propTypes = {
    visitValues: visitType
};

export default VisitFurAnimalsDiagnosesContainer;
