import React, { useState } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import CalendarSelection from "../../CalendarSelection/CalendarSelection";
import SlaughterBanSelection from "../SlaughterBanSelection/SlaughterBanSelection";
import useForm from "../../../../hooks/useForm";
import "./MedicineGivenForm.css";
import MilkBanSelection from "../MilkBanSelection/MilkBanSelection";
import medicineGivenValidation from "../../../../validations/medicineGivenValidation";
import AddMedicineButton from "../AddMedicineButton/AddMedicineButton";
import moment from "moment";
import { floatInputHelper } from "../../../../helpers/floatInputHelper";

const medicineGivenInit = {
    medicineAmount: 0
};

const MedicineGivenForm = ({
    medicine,
    handleCloseModal,
    isSlaughterBan,
    isMilkBan,
    hasDateLastGiven,
    visitDate,
    visitDetailValues,
    amountLabel
}) => {
    const [refresh, setRefresh] = useState(false);
    const medicineGivenSubmitHandler = (values) => {
        medicine.medicineAmount = values.medicineAmount;
        visitDetailValues.medicineList.push(medicine);
        if (isSlaughterBan) {
            if (visitDetailValues.hasInfiniteSlaughterBan) {
                const currentDate = new Date();
                currentDate.setDate(currentDate.getDate() + 365 * 40);
                visitDetailValues.highestSlaughterBanDate = moment(
                    currentDate
                ).format("YYYY-MM-DD");
            } else {
                visitDetailValues.highestSlaughterBanDate = banDateHelper(
                    visitDetailValues.slaughterBanDate,
                    "highestSlaughterBanDate"
                );
            }

            if (isMilkBan) {
                visitDetailValues.highestMilkBanDate = banDateHelper(
                    visitDetailValues.milkBanDate,
                    "highestMilkBanDate"
                );
            }
        }
        values.medicineAmount = 0;
        handleCloseModal();
    };
    const { values, handleSubmit, errors } = useForm(
        medicineGivenInit,
        medicineGivenValidation,
        medicineGivenSubmitHandler
    );
    const getDateDiffOnChange = (valueName) => {
        const gDate = new Date(visitDetailValues.dateLastGiven);
        if (valueName === "dateLastGiven") {
            if (isSlaughterBan) {
                const tempDate = new Date(visitDetailValues.dateLastGiven);
                tempDate.setDate(
                    gDate.getDate() + visitDetailValues.slaughterBanDays
                );
                visitDetailValues.slaughterBanDate = tempDate;
            }
            if (isMilkBan) {
                const tempDate = new Date(visitDetailValues.dateLastGiven);
                tempDate.setDate(
                    gDate.getDate() + visitDetailValues.milkBanDays
                );
                visitDetailValues.milkBanDate = tempDate;
            }
        } else {
            if (isSlaughterBan) {
                const sDate = new Date(visitDetailValues.slaughterBanDate);
                const sDaysBetweenDifference = Math.floor(
                    (sDate - gDate) / (1000 * 3600 * 24)
                );
                visitDetailValues.slaughterBanDays =
                    sDaysBetweenDifference < 0 ? 0 : sDaysBetweenDifference;
            }
            if (isMilkBan) {
                const mDate = new Date(visitDetailValues.milkBanDate);
                const mDaysBetweenDifference = Math.floor(
                    (mDate - gDate) / (1000 * 3600 * 24)
                );
                visitDetailValues.milkBanDays =
                    mDaysBetweenDifference < 0 ? 0 : mDaysBetweenDifference;
            }
        }

        setRefresh(!refresh);
    };
    const banDateHelper = (newBanDate, name) => {
        const currentBanDate = visitDetailValues[name]
            ? new Date(visitDetailValues[name])
            : new Date();
        const newBanToDate = new Date(newBanDate);
        if (currentBanDate > newBanToDate) {
            return moment(currentBanDate).format("YYYY-MM-DD");
        } else {
            return moment(newBanDate).format("YYYY-MM-DD");
        }
    };
    const handleChangeMedicineAmount = (e) => {
        var input = floatInputHelper(e.target.value);
        values.medicineAmount = input;
        setRefresh(!refresh);
    };
    return (
        <>
            <div className="medicine-given-form-container">
                <TextField
                    className="outlined-input"
                    type="text"
                    label={amountLabel}
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {medicine.unitTypeIsName}
                            </InputAdornment>
                        )
                    }}
                    helperText={errors.medicineAmount}
                    value={values.medicineAmount}
                    name="medicineAmount"
                    onChange={handleChangeMedicineAmount}
                    error={errors.medicineAmount !== undefined}
                />
                {hasDateLastGiven && (
                    <CalendarSelection
                        values={visitDetailValues}
                        label="Skal síðast gefið"
                        valueName="dateLastGiven"
                        disabled={false}
                        error={errors.dateLastGiven !== undefined}
                        errorMessage={errors.dateLastGiven}
                        dateGiven={visitDate}
                        getDateDiffCallBack={getDateDiffOnChange}
                    />
                )}
                {isSlaughterBan && (
                    <SlaughterBanSelection
                        visitDetailValues={visitDetailValues}
                        error={errors.slaughterBanDate !== undefined}
                        errorMessage={errors.slaughterBanDate}
                        dateGiven={visitDetailValues.slaughterBanDate}
                        getDateDiffCallBack={getDateDiffOnChange}
                    />
                )}
                {isMilkBan && (
                    <MilkBanSelection
                        error={errors.milkBanDate !== undefined}
                        errorMessage={errors.milkBanDate}
                        dateGiven={visitDetailValues.milkBanDate}
                        visitDetailValues={visitDetailValues}
                        getDateDiffCallBack={getDateDiffOnChange}
                    />
                )}
                <div className="medicine-given-form-add-button">
                    <AddMedicineButton
                        handleClick={handleSubmit}
                        text="Staðfesta lyf"
                    />
                </div>
            </div>
        </>
    );
};

export default MedicineGivenForm;
