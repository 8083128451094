import { useState, useEffect } from "react";
import poultryService from "../services/poultryService";

const useCreatePoultryVisit = (cb) => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [poultryVisits, setPoultryVisits] = useState(null);

    useEffect(() => {
        if (poultryVisits && !isProcessing) {
            setProcessing(true);
            poultryService
                .createPoultryVisit(poultryVisits)
                .then(() => {
                    setError(null);
                    cb(poultryVisits[0].visitId);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setPoultryVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, poultryVisits, cb]);

    const handleCreatePoultryVisit = (visitId, incomingVisit) => {
        if (!isProcessing) {
            var vpimList = [];

            incomingVisit.forEach((poultryVisit) => {
                const visitPoultryInputModelList = {
                    visitId: visitId,
                    diseaseId: poultryVisit.disease.id,
                    traceabilityNumber: poultryVisit.traceabilityNumber,
                    speciesId: poultryVisit.speciesId,
                    animalCount: parseInt(poultryVisit.animalCount),
                    veterinarianSSN: poultryVisit.veterinarianSSN,
                    medicines: prepareMedicines(poultryVisit.medicineList)
                };
                vpimList.push(visitPoultryInputModelList);
            });
            setPoultryVisits(vpimList);
        }
    };

    const prepareMedicines = (incomingMed) => {
        var medList = [];
        for (var i = 0; i < incomingMed.length; i++) {
            medList.push({
                medicineId: incomingMed[i].id,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleCreatePoultryVisit, isProcessing };
};
export default useCreatePoultryVisit;
