import React from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@material-ui/core";
import VisitAnimalSelectionSelectedListItem from "../VisitAnimalSelectionSelectedListItem/VisitAnimalSelectionSelectedListItem";
import "./VisitAnimalSelectionSelectedList.css";

const VisitAnimalSelectionSelectedList = ({ animals }) => {
    return (
        <div className="visit-animal-selection-selected-list-container">
            <Table
                stickyHeader
                aria-label="sticky table"
                className="visit-animal-selection-selected-list"
                variant="head"
            >
                <TableHead>
                    <TableRow>
                        <TableCell className="visit-animal-selection-selected-list-title">
                            <b>Valdir Gripir ({animals.length})</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {animals.map((animal, i) => (
                        <VisitAnimalSelectionSelectedListItem
                            key={i}
                            animal={animal}
                        />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default VisitAnimalSelectionSelectedList;
