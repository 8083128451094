import React, { useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "./AdminDropDownListItem.css";
const AdminDropDownListItem = ({ item, values, handleChange }) => {
    const [openDropDownList, setOpenDropDownList] = useState(false);

    const dropDownList = () => {
        setOpenDropDownList(!openDropDownList);
    };
    return (
        <FormControl
            className="admin-drop-down-list"
            onClick={() => {
                dropDownList();
            }}
        >
            <FormHelperText>{item.label}</FormHelperText>
            <Select
                open={openDropDownList}
                className="admin-drop-down-list-select"
                value={values[item.name]}
                onChange={handleChange}
                name={item.name}
            >
                {item.types.map((it, index) => (
                    <MenuItem key={index} value={it.id}>
                        {" "}
                        {it.isName}{" "}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default AdminDropDownListItem;