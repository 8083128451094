import React from "react";
import { Paper } from "@material-ui/core";
import "./VisitPoultrySelection.css";
import VisitPoultryInput from "../VisitPoultryInput/VisitPoultryInput";
import useGetAllPoultrySpecies from "../../../hooks/useGetAllPoultrySpecies";
import VisitPoultrySelectList from "../VisitPoultrySelectList/VisitPoultrySelectList";
import { funcType, poultryDiagnoses } from "../../../types";

////NOTDONE
/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category PoultryVisit
 * @component holds selection view for poultry species
 */

const VisitPoultrySelection = ({ values, handleChange, errors }) => {
    /**
     * Hook that gets all poultry species from the database
     */
    const { poultrySpecies } = useGetAllPoultrySpecies();
    values.poultrySpeciesList = poultrySpecies;
    return (
        <Paper className="poultry-selection-container-paper">
            <div className="poultry-selection-details-upper">
                <VisitPoultrySelectList
                    values={values}
                    handleChange={handleChange}
                    species={poultrySpecies}
                    errors={errors}
                />
            </div>
            <div className="poultry-selection-details-lower">
                <VisitPoultryInput
                    label="Fjöldi fugla"
                    type="text"
                    name="animalCount"
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                />
                <VisitPoultryInput
                    label="Rekjanleikanúmer"
                    type="text"
                    name="traceabilityNumber"
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                />
            </div>
        </Paper>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitPoultrySelection.propTypes = {
    /**
     * Poultry visit object with diagnosesList and treatment object
     */
    values: poultryDiagnoses,
    /**
     * Function that handles change when user changes information about poultry visit
     */
    handleChange: funcType
};

export default VisitPoultrySelection;
