import { useState, useEffect } from "react";

const useSearchBar = (list, initKey) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const searchKey = initKey || "name";
    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };
    useEffect(() => {
        const result = list.filter((listItem) =>
            listItem[searchKey].toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResult(result);
    }, [searchTerm, list, searchKey]);

    return { searchResult, handleChange, searchTerm };
};

export default useSearchBar;
