import React from "react";
import { Route, Redirect } from "react-router-dom";
import useCheckLoginStatus from "../../hooks/useCheckLoginStatus";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children, ...rest }) => {
    const user = useSelector((state) => state.user);
    const { error } = useCheckLoginStatus(children);

    return error ? (
        <Redirect to="/login" />
    ) : (
        <Route
            {...rest}
            render={() => {
                return user.loggedIn && children;
            }}
        />
    );
};
export default PrivateRoute;
