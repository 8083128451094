import React, { useState } from "react";
import { Modal, Paper, CircularProgress } from "@material-ui/core";
import "./UpdateVisitAquaCultureSiteRadio.css";
import VisitAquaCultureRadio from "../../VisitAquaCultureRadio/VisitAquaCultureRadio";
import useGetFisheryById from "../../../../hooks/useGetFisheryById";
import UpdateVisitButtons from "../../../Visit/UpdateVisit/UpdateVisitButtons/UpdateVisitButtons";
import {
    boolType,
    funcType,
    unhandledInputType,
    aquaCultureUpdateDiagnoses,
    numberType
} from "../../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category AquaCultureVisit
 * @component that handles site change inside aqua culture visit
 */

const UpdateVisitAquaCultureSiteRadio = ({
    open,
    establishmentId,
    handleClose,
    unhandledSiteId,
    visit
}) => {
    /**
     * React useState hook to reload component on change
     */
    const [reload, setReload] = useState(false);
    /**
     * Handle cancel function, resets input if user cancels change
     */
    const handleCancel = () => {
        visit.siteId = unhandledSiteId;
        var unhandledSite = fishery.sites.filter(
            (x) => x.id === parseInt(unhandledSiteId)
        );
        visit.siteName = unhandledSite[0].name;
        handleClose();
    };
    /**
     * Handle change function
     */
    const handleChange = (e) => {
        visit.siteId = e.target.value;
        visit.siteName = fishery.sites[e.target.value].name;
        setReload(!reload);
    };
    const errors = {
        siteId: undefined
    };
    const getVisitValues = () => {
        const visitValues = { establishmentAreas: fishery.sites };
        return visitValues;
    };
    /**
     * Hook that gets fishery by id
     */
    const { isLoading, fishery, error } = useGetFisheryById(establishmentId);
    return (
        <Modal
            className="update-visit-site-radio-modal"
            open={open}
            onClose={handleClose}
        >
            {isLoading ? (
                <CircularProgress />
            ) : (
                <Paper className="update-visit-site-radio-paper">
                    {error ? (
                        <div>Ekki tókst að sækja svæði</div>
                    ) : (
                        <>
                            <VisitAquaCultureRadio
                                visitValues={getVisitValues()}
                                values={visit}
                                handleChange={handleChange}
                                errors={errors}
                            />
                            <UpdateVisitButtons
                                handleClose={handleClose}
                                handleCancel={handleCancel}
                            />
                        </>
                    )}
                </Paper>
            )}
        </Modal>
    );
};

/**
 * This React app has generalized Proptypes
 */
UpdateVisitAquaCultureSiteRadio.propTypes = {
    /**
     * Boolean keeps track of is modal is open
     */
    open: boolType.isRequired,
    /**
     * The id of the establishment
     */
    establishmentId: numberType,
    handleClose: funcType,
    /**
     * Saved unhandled input variable for when user cancels change
     */
    unhandledSpeciesId: unhandledInputType,
    /**
     * Aqua culture visit object with diagnosesList and treatment object
     */
    diagnoses: aquaCultureUpdateDiagnoses
};

export default UpdateVisitAquaCultureSiteRadio;
