import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import "./LiveStockEstablishmentsSelectionListItem.css";

const LiveStockEstablishmentsSelectionListItem = ({
    establishment,
    handleEstablishmentSelection
}) => {
    return (
        <TableRow
            className="live-stock-establishments-table-row"
            onClick={(e) => {
                handleEstablishmentSelection(e, establishment);
            }}
        >
            <TableCell className="hallo">
                {establishment.heiti}
                <br />
                <i>{establishment.umradamadur}</i>
            </TableCell>
            <TableCell>{establishment.busnumer}</TableCell>
            <TableCell>{establishment.postnumer}</TableCell>
        </TableRow>
    );
};

export default LiveStockEstablishmentsSelectionListItem;
