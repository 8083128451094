import { useState, useEffect } from "react";
import rmlEstablishmentsService from "../services/rmlEstablishmentsService";

const useGetSheepsByFarmId = (farmId) => {
    const [sheepList, setSheepList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        setIsLoading(true);
        rmlEstablishmentsService
            .getSheepsByFarmId(farmId)
            .then((sheepList) => {
                sheepList.forEach((sheep) => {
                    const hasValnumer = sheep.valnumer !== null;
                    sheep.isSelected = false;
                    sheep.searchKey = hasValnumer
                        ? sheep.nafn + sheep.valnumer.toString()
                        : "Upplýsingar um kind fundust ekki";
                    const yearBorn = sheep.faedingardags.split("-");
                    sheep.isYoung = yearBorn[0] === currentYear.toString();
                });
                
                setSheepList(sheepList);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, [farmId, currentYear]);
    return { sheepList, isLoading, error };
};

export default useGetSheepsByFarmId;
