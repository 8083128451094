import React, { useState } from "react";
import CreateIcon from "@material-ui/icons/Create";
import "./VisitPorcineUpdateDiagnosesListItem.css";
import useWindowSize from "../../../../hooks/useWindowSize";
import UpdateVisitDiseaseModal from "../../../Visit/UpdateVisit/UpdateVisitDiseaseModal/UpdateVisitDiseaseModal";
import UpdateVisitDiagnosesMedicineList from "../../../Visit/UpdateVisit/UpdateVisitDiagnosesMedicineList/UpdateVisitDiagnosesMedicineList";
import { porcineUpdateDiagnoses } from "../../../../types";
import useGetAllPorcineDiseases from "../../../../hooks/useGetAllPorcineDiseases";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category PorcineVisit
 * @component holds all the items that you can update in porcine visit.
 *            Main purpose is to handle clicks from user and open and close modals
 */

const VisitPorcineUpdateDiagnosesListItem = ({ diagnoses }) => {
    /**
     * Hook that gets user's current window size
     */
    const windowSize = useWindowSize();
    /**
     * Boolean which reads user's screen size and returns true if screen size
     * is less than or equal to 690px (standard screen size in 2021)
     */
    const isPhone = windowSize.width <= 690;
    const { porcineDiseases } = useGetAllPorcineDiseases();
    const [visitDiseaseModalIsOpen, setVisitDiseaseModalIsOpen] = useState(
        false
    );

    return (
        <div className="user-visit-update-view-diagnoses-info">
            <div className="user-visit-update-view-diagnoses-site-info">
                <div
                    className="user-visit-update-text"
                    onClick={() => setVisitDiseaseModalIsOpen(true)}
                >
                    <b>Greining:</b> {diagnoses.diseaseIsName}
                    {!isPhone && (
                        <CreateIcon className="user-visit-update-update-icon" />
                    )}
                </div>
            </div>
            <div className="user-visit-update-view-diagnoses-medicine-info">
                <UpdateVisitDiagnosesMedicineList
                    medicineList={diagnoses.medicineList}
                />
            </div>
            <UpdateVisitDiseaseModal
                open={visitDiseaseModalIsOpen}
                handleClose={() => setVisitDiseaseModalIsOpen(false)}
                diagnoses={diagnoses}
                unhandledDiseaseId={diagnoses.diseaseId}
                unhandledDiseaseIsName={diagnoses.diseaseIsName}
                unhandledDiseaseEnName={diagnoses.diseaseEnName}
                diseases={porcineDiseases}
            />
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitPorcineUpdateDiagnosesListItem.propTypes = {
    /**
     * Porcine visit object with diagnosesList and treatment object
     */
    diagnoses: porcineUpdateDiagnoses
};
export default VisitPorcineUpdateDiagnosesListItem;
