import { useState, useEffect } from "react";
import formulationTypeService from "../services/formulationTypeService";

const useGetAllFormulationTypes = () => {
    const [formulationTypes, setFormulationTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        formulationTypeService
            .getAllFormulationTypes()
            .then((formulationTypes) => {
                setFormulationTypes(formulationTypes);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { formulationTypes, isLoading, error };
};

export default useGetAllFormulationTypes;
