import React, { useState } from "react";
import useWindowSize from "../../../../hooks/useWindowSize";
import CreateIcon from "@material-ui/icons/Create";
import UpdateVisitDiagnosesMedicineModal from "../UpdateVisitDiagnosesMedicineModal/UpdateVisitDiagnosesMedicineModal";
import UpdateVisitDiagnosesMedicineDetailsModal from "../UpdateVisitDiagnosesMedicineDetailsModal/UpdateVisitDiagnosesMedicineDetailsModal";
import "./UpdateVisitDiagnosesMedicineListItem.css";

const UpdateVisitDiagnosesMedicineListItem = ({ medicine }) => {
    const windowSize = useWindowSize();
    const isPhone = windowSize.width <= 690;
    const [
        visitDiagnosesMedicineModalIsOpen,
        setVisitDiagnosesMedicineModalIsOpen
    ] = useState(false);
    const [
        visitDiagnosesMedicineDetailsModalIsOpen,
        setVisitDiagnosesMedicineDetailsModalIsOpen
    ] = useState(false);
    return (
        <div>
            <div
                className="user-visit-update-text"
                onClick={() => setVisitDiagnosesMedicineModalIsOpen(true)}
            >
                <b>Lyf:</b> {medicine.medicineName} {medicine.strength}
                {medicine.strengthTypeIsName}
                {!isPhone && (
                    <CreateIcon className="user-visit-update-update-icon" />
                )}
            </div>
            <div
                className="user-visit-update-text"
                onClick={() =>
                    setVisitDiagnosesMedicineDetailsModalIsOpen(true)
                }
            >
                <b>Heildarmagn í vitjun:</b> {medicine.medicineAmount}
                {!isPhone && (
                    <CreateIcon className="user-visit-update-update-icon" />
                )}
            </div>
            <UpdateVisitDiagnosesMedicineModal
                open={visitDiagnosesMedicineModalIsOpen}
                handleClose={() => setVisitDiagnosesMedicineModalIsOpen(false)}
                medicine={medicine}
            />
            <UpdateVisitDiagnosesMedicineDetailsModal
                open={visitDiagnosesMedicineDetailsModalIsOpen}
                handleClose={() =>
                    setVisitDiagnosesMedicineDetailsModalIsOpen(false)
                }
                medicine={medicine}
                unhandledAmount={medicine.medicineAmount}
            />
        </div>
    );
};
export default UpdateVisitDiagnosesMedicineListItem;
