import React from "react";
import useGetAllMedicinesForAdmin from "../../../../../hooks/useGetAllMedicinesForAdmin";
import { CircularProgress, Paper } from "@material-ui/core";
import "./AdminMedicineView.css";
import AdminMedicineSelectView from "../AdminMedicineSelectView/AdminMedicineSelectView";

const AdminMedicineView = () => {
    const { medicines, isLoading, error } = useGetAllMedicinesForAdmin();
    return (

        <div className="admin-medicine-view-container">
            <Paper className="admin-medicine-view-paper">
                <div className="admin-medicine-view-header" >
                    <h2>Lyf</h2>     
                </div>
                {isLoading ? (
                    <div className="admin-medicine-view-loading">
                        <CircularProgress 
                            size={100}
                        />
                    </div>
                ) : error ? (
                    <>Ekki tókst að sækja lyf</>
                ) : (
                    <div className="admin-medicine-view-selection">
                        <AdminMedicineSelectView medicines={medicines} />
                    </div>
                )}
            </Paper>
        </div>
    )
};

export default AdminMedicineView;

