import { useState, useEffect } from "react";
import rmlEstablishmentsService from "../services/rmlEstablishmentsService";

const useGetGoatsByFarmId = (farmId) => {
    const [goatList, setGoatList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        setIsLoading(true);
        rmlEstablishmentsService
            .getGoatsByFarmId(farmId)
            .then((goatList) => {
                goatList.forEach((goat) => {
                    goat.isSelected = false;
                    goat.searchKey = goat.nafn + goat.valnumer.toString();
                    const yearBorn = goat.faedingardags.split("-");
                    goat.isYoung = yearBorn[0] === currentYear.toString();
                });
                setGoatList(goatList);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, [farmId, currentYear]);
    return { goatList, isLoading, error };
};

export default useGetGoatsByFarmId;
