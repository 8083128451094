import { useState, useEffect } from "react";
import horseService from "../services/horseService";

const useUpdateHorseVisit = () => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [horseVisits, setHorseVisits] = useState(null);

    useEffect(() => {
        if (horseVisits && !isProcessing) {
            setProcessing(true);
            horseService
                .updateHorseVisit(horseVisits)
                .then(() => {
                    setError(null);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setHorseVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, horseVisits]);

    const handleUpdateHorseVisit = (incomingVisit) => {
        if (!isProcessing) {
            const outgoingVisit = {
                id: incomingVisit.id,
                arrangementTypeId: parseInt(incomingVisit.arrangementTypeId),
                establishmentId: incomingVisit.establishmentId,
                establishmentName: incomingVisit.establishmentName,
                establishmentSSN: incomingVisit.establishmentSSN,
                animalTypeId: incomingVisit.animalTypeId,
                veterinarianId: incomingVisit.veterinarianId,
                veterinarianSSN: incomingVisit.veterinarianSSN,
                visitDate: incomingVisit.visitDate,
                horseVisits: prepareHorseVisits(incomingVisit)
            };
            setHorseVisits(outgoingVisit);
        }
    };

    const prepareHorseVisits = (incomingHorseVisits) => {
        var vcList = [];
        incomingHorseVisits.diagnosesList.forEach((horseVisit) => {
            const visitHorseInputModelList = {
                id: horseVisit.id,
                visitId: horseVisit.visitId,
                horses: horseVisit.animalIdList.map((animalId) =>
                    animalId.toString()
                ),
                diseaseId: horseVisit.diseaseId,
                veterinarianSSN: horseVisit.veterinarianSSN,
                medicines: prepareMedicines(horseVisit.medicineList)
            };
            vcList.push(visitHorseInputModelList);
        });
        return vcList;
    };

    const prepareMedicines = (incomingMed) => {
        let medList = [];
        for (let i = 0; i < incomingMed.length; i++) {
            medList.push({
                id: incomingMed[i].id,
                medicineId: incomingMed[i].medicineId,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleUpdateHorseVisit, isProcessing };
};
export default useUpdateHorseVisit;
