const visitInfoValidation = (values) => {
    const { arrangementTypeId, establishmentId, visitDate } = values;

    const errors = {};

    if (arrangementTypeId === null) {
        errors.arrangementTypeId = "Velja þarf aðferð";
    }
    if (establishmentId === null) {
        errors.establishmentId = "Velja þarf starfstöð";
    }
    if (visitDate === null) {
        errors.visitDate = "Velja þarf dagsetningu";
    }

    return errors;
};

export default visitInfoValidation;
