import React from "react";
import { Button } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import "./CancelButton.css";

const CancelButton = ({ disabled, handleCancel }) => {
    return (
        <Button
            className="diagnoses-cancel-button"
            disabled={disabled}
            onClick={handleCancel}
        >
            <ClearIcon className="diagnoses-cancel-icon" />
            <b>Hætta við Meðhöndlun</b>
        </Button>
    );
};

export default CancelButton;