export const floatInputHelper = (numberAsString) => {
    var hasComma = false;
    for (var i = 0; i < numberAsString.length; i++) {
        if (!hasComma && numberAsString[i].includes(",")) {
            numberAsString = numberAsString.replace(",", ".");
            hasComma = true;
        } else {
            if (isNaN(numberAsString[i])) {
                numberAsString = numberAsString.replace(",", "");
            }
        }
    }
    if (numberAsString === "") {
        numberAsString = "0";
    }
    if (!isNaN(numberAsString) && !isNaN(parseFloat(numberAsString))) {
        if (numberAsString.includes(".")) {
            return numberAsString;
        } else {
            let numberAsFloat = parseFloat(numberAsString);
            return numberAsFloat;
        }
    }
    return 0;
};
