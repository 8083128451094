import React from "react";
import VisitPoultryUpdateDiagnosesListItem from "../../../VisitPoultry/VisitPoultryUpdate/VisitPoultryUpdateDiagnosesListItem/VisitPoultryUpdateDiagnosesListItem";
import { Button, Modal, Paper } from "@material-ui/core";
import "./VisitPoultryUpdateModal.css";

const VisitPoultryUpdateModal = ({
    visitValues,
    updatePoultryModalIsOpen,
    handleCloseUpdateVisitModal,
    visitToUpdateCounter,
    handleUpdate
}) => {
    return (
        <Modal
            className="update-visit-poultry-modal"
            open={updatePoultryModalIsOpen}
            onClose={handleCloseUpdateVisitModal}
        >
            <Paper className="update-visit-poultry-paper">
                <div className="update-visit-poultry-modal-header">
                    Uppfæra vitjun
                </div>
                <VisitPoultryUpdateDiagnosesListItem
                    diagnoses={visitValues.diagnosesList[visitToUpdateCounter]}
                    establishmentId={visitValues.establishmentId}
                />
                <div className="diagnoses-medicine-view-update-card-button-area">
                    <Button
                        className="diagnoses-medicine-view-update-card-cancel-button"
                        onClick={handleCloseUpdateVisitModal}
                    >
                        <b>Til baka</b>
                    </Button>
                    <Button
                        className="diagnoses-medicine-view-update-card-confirm-button"
                        onClick={handleUpdate}
                    >
                        <b>Vista</b>
                    </Button>
                </div>
            </Paper>
        </Modal>
    );
};

export default VisitPoultryUpdateModal;
