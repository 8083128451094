import { useState, useEffect } from "react";
import aquaCultureService from "../services/aquaCultureService";

const useCreateAquaCultureVisit = (cb) => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [aquaCultureVisits, setAquaCultureVisits] = useState(null);

    useEffect(() => {
        if (aquaCultureVisits && !isProcessing) {
            setProcessing(true);
            aquaCultureService
                .createAquaCultureVisit(aquaCultureVisits)
                .then(() => {
                    setError(null);
                    cb(aquaCultureVisits[0].visitId);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setAquaCultureVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, aquaCultureVisits, cb]);

    const handleCreateAquaCultureVisit = (visitId, incomingVisit) => {
        if (!isProcessing) {
            var vacimList = [];

            incomingVisit.forEach((aquavisit) => {
                const visitAquaCultureInputModelList = {
                    visitId: visitId,
                    siteId:
                        aquavisit.siteId === ""
                            ? null
                            : parseInt(aquavisit.siteId),
                    siteName: aquavisit.siteName,
                    speciesIsName: aquavisit.speciesIsName,
                    speciesEnName: aquavisit.speciesEnName,
                    fishCount: parseInt(aquavisit.animalCount),
                    areaSize: parseFloat(aquavisit.areaSize),
                    biomass: parseFloat(aquavisit.biomass),
                    temperature: parseFloat(aquavisit.temperature),
                    speciesId: parseInt(aquavisit.speciesId),
                    diseaseId: aquavisit.disease.id,
                    medicines: prepareMedicines(aquavisit.medicineList),
                    veterinarianSSN: aquavisit.veterinarianSSN
                };
                vacimList.push(visitAquaCultureInputModelList);
            });
            setAquaCultureVisits(vacimList);
        }
    };

    const prepareMedicines = (incomingMed) => {
        var medList = [];
        for (var i = 0; i < incomingMed.length; i++) {
            medList.push({
                medicineId: incomingMed[i].id,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleCreateAquaCultureVisit, isProcessing };
};
export default useCreateAquaCultureVisit;
