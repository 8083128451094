import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category Visit
 * @component lol
 */

const VisitAnimalSelectionSelectedListItem = ({ animal }) => {
    return (
        <TableRow className="visit-animal-selection-selected-list-row">
            <TableCell className="visit-animal-selection-selected-list-item">
                <div className="visit-animal-selection-selected-list-item-upper">
                    <b>{animal.nafn ? animal.nafn : "Nafn vantar"}</b>
                </div>
                <div>
                    <i>
                        {animal.valnumer ? animal.valnumer : "Valnúmer vantar"}
                    </i>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default VisitAnimalSelectionSelectedListItem;
