export const getMedicineSelectData = (formulationTypes, strengthTypes, medicineTypes, packageUnitTypes, unitTypes) => [
    { title: "Lyfjaform", label: "Skrá lyfjaform", name: "formulationTypeId", types: formulationTypes },
    { title: "Styrkur", label: 'Veldu tegund styrks', name: "strengthTypeId", types: strengthTypes },
    { title: "Tegund", label: 'Veldu tegund lyfs', name: "medicineTypeId", types: medicineTypes },
    { title: "Pakkning", label: 'Veldu tegund pakkningar', name: "packageUnitTypeId", types: packageUnitTypes },
    { title: "Eining", label: 'Veldu tegund einingar', name: "unitTypeId", types: unitTypes }
];

export const getMedicineInputData = () => [
    { title: "Lyf", label: "Heiti lyfs", name: "name" },
    { title: "Norskt vörunúmer", label: "Auðkenni", name: "productId" },
    { title: "ACT vet", label: "Auðkenni ", name: "atcVet" },
    { title: "Eining", label: "Magn", name: "packageUnit" },
    { title: "Keppnisbann (dagar)", label: "Fjöldi daga", name: "competitionBanDays" },
    { title: "Mjólkurbann (dagar)", label: "Fjöldi daga", name: "milkBanDays" },
    { title: "Sláturbann (dagar)", label: "Fjöldi daga", name: "slaughterBanDays" },
    { title: "Styrkur", label: "Magn", name: "strength" }
];

export const getMedicineRadioData = () => [
    { title: "Sýklalyf", label: "Merkja sem sýklalyf", name: "isAntibiotic" },
    { title: "Bóluefni við garnaveiki", label: "Merkja sem bóluefni við garnaveiki", name: "isMycobacteriumVaccine" },
    { title: "D-merkt lyf", label: "Merkja sem D-merkt lyf", name: "isVetOnlyAdminister" },
    { title: "Óleyfileg efni í búfé", label: "Merkja sem óleyfileg efni í búfé", name: "isBannedForFarmedAnimal" },
    { title: "Ekki fyrir hross", label: "Merkja sem lyf sem er ekki fyrir hross", name: "isNotForHorses" }
];