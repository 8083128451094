import { useState, useEffect } from "react";
import rmlEstablishmentsService from "../services/rmlEstablishmentsService";

const useGetCattlesByFarmId = (farmId) => {
    const [cattleList, setCattleList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        setIsLoading(true);
        rmlEstablishmentsService
            .getCattlesByFarmId(farmId)
            .then((cattleList) => {
                cattleList.forEach((cattle) => {
                    cattle.isSelected = false;
                    cattle.searchKey = cattle.nafn + cattle.valnumer.toString();
                    const yearBorn = cattle.faedingardags.split("-");
                    cattle.isYoung = yearBorn[0] === currentYear.toString();
                });
                setCattleList(cattleList);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, [farmId, currentYear]);
    return { cattleList, isLoading, error };
};

export default useGetCattlesByFarmId;
