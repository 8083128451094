import React from "react";
import { Table, TableBody } from "@material-ui/core";
import DiseaseSelectionTableItem from "../DiseaseSelectionTableItem/DiseaseSelectionTableItem";

const DiseaseSelectionTable = ({ items, handleSelection }) => {
    return (
        <Table className="selection-table" size="small">
            <TableBody>
                {items.map((i) => (
                    <DiseaseSelectionTableItem
                        item={i}
                        key={i.id}
                        handleSelection={handleSelection}
                    />
                ))}
            </TableBody>
        </Table>
    );
};

export default DiseaseSelectionTable;
