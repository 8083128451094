import React from 'react';
import './SideBarContainer.css';
import SideBarList from '../SideBarList/SideBarList';

const SideBarContainer = ({ toggleSideBar }) => {
    return (
            <div className="sidebar-container">
                <SideBarList toggleSideBar={toggleSideBar} />
            </div>

  );
}

export default SideBarContainer;