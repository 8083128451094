import React from "react";

const AdminMedicineInfoModalText = ({ medicine }) => {
    return (
        <div>
            <p>Viltu afvirkja <b>{medicine.name}</b>?</p>
            <p>Tegund: {medicine.formulationTypeDescription}{"."}</p>
            <p>Styrkur: {medicine.strength}{" "} {medicine.strengthTypeIsName}{"."}</p>
            <p>Eining: {medicine.packageUnit}{" "} {medicine.packageUnitTypeIsName}{"."}</p>
            {medicine.active ? 
                <b>Eftir að lyf er afvirkjað er ekki hægt að nota það við skráningu.</b>
                :
                <b>Þegar lyf er gert virkt er hægt að nota það aftur við skráningar.</b>
            }
        </div>
    );
};
export default AdminMedicineInfoModalText;