import React from 'react';
import AdminInputFormItem from "../AdminInputFormItem/AdminInputFormItem";
import "./AdminInputForm.css";

const AdminInputForm = ({
    error,
    items,
    values,
    handleChange
}
) => {
    return (
        <>
            {items.map((items, index) =>
                <div className="add-medicine-item" key={index} >
                    <AdminInputFormItem error={error} item={items} values={values} handleChange={handleChange} />
                </div>
            )}
        </>
    );
}

export default AdminInputForm;