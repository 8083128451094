import React, { useState } from "react";
import "./VisitFurAnimalsUpdateView.css";
import { Paper, Button } from "@material-ui/core";
import { formatDate } from "../../../../helpers/formatDateHelper";
import CreateIcon from "@material-ui/icons/Create";
import VisitFurAnimalsUpdateDiagnosesList from "../VisitFurAnimalsUpdateDiagnosesList/VisitFurAnimalsUpdateDiagnosesList";
import useWindowSize from "../../../../hooks/useWindowSize";
import UpdateVisitDateModal from "../../../Visit/UpdateVisit/UpdateVisitDateModal/UpdateVisitDateModal";
import UpdateVisitArrangementeModal from "../../../Visit/UpdateVisit/UpdateVisitArrangementModal/UpdateVisitArrangementModal";
import { useHistory } from "react-router-dom";
import useUpdateFurAnimalVisit from "../../../../hooks/useUpdateFurAnimalVisit";
import { useSelector } from "react-redux";
import { visitType } from "../../../../types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category FurAnimalsVisit
 * @component holds all the visit modals and components for user to update a furAnimals visit
 */

const VisitFurAnimalsUpdateView = ({ visit }) => {
    /**
     * React history hook, used to redirect when back button is clicked
     */
    const history = useHistory();
    /**
     * Hook that gets user's current window size
     */
    const windowSize = useWindowSize();
    /**
     * Boolean which reads user's screen size and returns true if screen size
     * is less than or equal to 690px (standard screen size in 2021)
     */
    const isPhone = windowSize.width <= 690;
    /**
     * User information from the redux store
     */
    const user = useSelector((state) => state.user);
    /**
     * Toaster for confirmation message
     */
    const toasterTrigger = () =>
        toast.success(
            "Uppfærsla mótekin. Við færum þig aftur á þínar vitjanir ..."
        );
    /**
     * React useState hooks to open update modals
     */
    const [
        visitArrangementModalIsOpen,
        setArrangementVisitModalIsOpen
    ] = useState(false);
    const [visitDateModalIsOpen, setVisitDateModalIsOpen] = useState(false);
    const handleVisitDateModalClose = () => {
        setVisitDateModalIsOpen(false);
    };
    const handleVisitArrangementModalClose = () => {
        setArrangementVisitModalIsOpen(false);
    };
    /**
     * Create visit hook
     */
    const { handleUpdateFurAnimalVisit } = useUpdateFurAnimalVisit();
    /**
     * Update confirmation function
     */
    const handleUpdateConfirmation = () => {
        visit.veterinarianId = user.id;
        handleUpdateFurAnimalVisit(visit);
        toasterTrigger();
        setTimeout(() => {
            history.push("/uservisits");
        }, 2200);
    };

    return (
        <div className="user-visit-update-view-paper-container">
            <Paper className="user-visit-update-view-paper">
                <div className="user-visit-update-view-title">
                    <b>Vitjun hjá {visit.establishmentName}</b>
                </div>
                <div className="user-visit-update-visit-info">
                    <div
                        className="user-visit-update-text"
                        onClick={() => setVisitDateModalIsOpen(true)}
                    >
                        <b>Dagsetning:</b> {formatDate(visit.visitDate)}{" "}
                        {!isPhone && (
                            <CreateIcon className="user-visit-update-update-icon" />
                        )}
                    </div>
                    <div
                        className="user-visit-update-text"
                        onClick={() => setArrangementVisitModalIsOpen(true)}
                    >
                        <b>
                            {!isPhone
                                ? "Fyrirkomulag vitjunar:"
                                : "Fyrirkomulag: "}
                        </b>
                        {visit.arrangementIsName}
                        {!isPhone && (
                            <CreateIcon className="user-visit-update-update-icon" />
                        )}
                    </div>
                </div>
                <div className="user-visit-update-furanimals-visit">
                    <VisitFurAnimalsUpdateDiagnosesList
                        diagnosesList={visit.diagnosesList}
                        establishmentId={visit.establishmentId}
                    />
                </div>
                <div className="user-visit-update-furanimals-visit-button-area">
                    <Button
                        className="user-visit-update-furanimals-back-button"
                        onClick={() => history.push("/uservisits")}
                    >
                        <b>Til baka</b>
                    </Button>
                    <Button
                        className="user-visit-update-furanimals-confirm-button"
                        onClick={handleUpdateConfirmation}
                    >
                        <b>Vista</b>
                    </Button>
                </div>
            </Paper>
            <UpdateVisitDateModal
                visit={visit}
                open={visitDateModalIsOpen}
                handleClose={handleVisitDateModalClose}
                unhandledDate={visit.visitDate}
            />
            <UpdateVisitArrangementeModal
                visit={visit}
                open={visitArrangementModalIsOpen}
                handleClose={handleVisitArrangementModalClose}
                unhandledId={visit.arrangementTypeId}
            />
            <ToastContainer
                position="top-right"
                autoClose={2200}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};
/**
 * This React app has generalized Proptypes
 */
VisitFurAnimalsUpdateView.propTypes = {
    /**
     * FurAnimals visit object with diagnosesList and treatment object
     */
    visit: visitType
};

export default VisitFurAnimalsUpdateView;
