/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/rmlapi`;
const getAllEstablishments = async (searchKey, id) => {
    return await fetch(`${apiEndpoint}/farms?q=${searchKey}&type=${id}`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const getCattlesByFarmId = async (farmId) => {
    return await fetch(`${apiEndpoint}/farms/${farmId}/cattles`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const getSheepsByFarmId = async (farmId) => {
    return await fetch(`${apiEndpoint}/farms/${farmId}/sheeps`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const getGoatsByFarmId = async (farmId) => {
    return await fetch(`${apiEndpoint}/farms/${farmId}/goats`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const getHorsesByMicroMarkId = async (microMarkList) => {
    const horseList = microMarkList.split(" ");
    const list = horseList.filter((x) => x.length > 14);

    return await fetch(`${apiEndpoint}/horses`, {
        method: "POST",
        body: JSON.stringify(list),
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

export default {
    getAllEstablishments,
    getCattlesByFarmId,
    getSheepsByFarmId,
    getGoatsByFarmId,
    getHorsesByMicroMarkId
};
