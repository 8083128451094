import React from "react";
import VisitAquaCultureUpdateDiagnosesListItem from "../../../VisitAquaCulture/VisitAquaCultureUpdate/VisitAquaCultureUpdateDiagnosesListItem/VisitAquaCultureUpdateDiagnosesListItem";
import { Button, Modal, Paper } from "@material-ui/core";
import "./VisitAquaCultureUpdateModal.css";

const VisitAquaCultureUpdateModal = ({
    visitValues,
    updateModalIsOpen,
    handleCloseUpdateVisitModal,
    visitToUpdateCounter,
    handleUpdate
}) => {
    return (
        <Modal
            className="update-visit-aqua-culture-modal"
            open={updateModalIsOpen}
            onClose={handleCloseUpdateVisitModal}
        >
            <Paper className="update-visit-aqua-culture-paper">
                <div className="update-visit-aqua-culture-modal-header">
                    Uppfæra vitjun
                </div>
                <VisitAquaCultureUpdateDiagnosesListItem
                    diagnoses={visitValues.diagnosesList[visitToUpdateCounter]}
                    establishmentId={visitValues.establishmentId}
                />
                <div className="diagnoses-medicine-view-update-card-button-area">
                    <Button
                        className="diagnoses-medicine-view-update-card-cancel-button"
                        onClick={handleCloseUpdateVisitModal}
                    >
                        <b>Til baka</b>
                    </Button>
                    <Button
                        className="diagnoses-medicine-view-update-card-confirm-button"
                        onClick={handleUpdate}
                    >
                        <b>Vista</b>
                    </Button>
                </div>
            </Paper>
        </Modal>
    );
};

export default VisitAquaCultureUpdateModal;
