/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import rmlEndpoint from "./rmlEndpoint";

const apiEndpoint = `${rmlEndpoint}/auth/token`;

const rmlLogin = () => {
    return fetch(apiEndpoint, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        mode: "cors"
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

export default { rmlLogin };
