import React from "react";
import useForm from "../../../../../hooks/useForm";
import AddMedicineButton from "../AddMedicineButton/AddMedicineButton";
import AdminDropDownList from "../../AdminDropDownList/AdminDropDownList";
import AdminInputForm from "../../AdminInputForm/AdminInputForm";
import AdminRadio from "../../AdminRadio/AdminRadio";
import useGetAllStrengthTypes from "../../../../../hooks/useGetAllStrengthTypes";
import useGetAllFormulationTypes from "../../../../../hooks/useGetAllFormulationTypes";
import useGetAllMedicineTypes from "../../../../../hooks/useGetAllMedicineTypes";
import useGetAllPackageUnitTypes from "../../../../../hooks/useGetAllPackageUnitTypes";
import useGetAllUnitTypes from "../../../../../hooks/useGetAllUnitTypes";
import useCreateMedicine from "../../../../../hooks/useCreateMedicine";
import addMedicineValidation from "../../../../../validations/addMedicineValidation";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import {
    getMedicineSelectData,
    getMedicineInputData,
    getMedicineRadioData
} from "./MedicineData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AddMedicine.css";

const addMedicineInitState = {
    name: "",
    formulationTypeId: "",
    productId: "",
    atcVet: "",
    medicineTypeId: "",
    strengthTypeId: "",
    strength: "0",
    unitTypeId: "",
    packageUnitTypeId: "",
    packageUnit: "0",
    milkBanDays: 0,
    competitionBanDays: 0,
    slaughterBanDays: 0,
    isAntibiotic: null,
    isMycobacteriumVaccine: null,
    isVetOnlyAdminister: null,
    isBannedForFarmedAnimal: null,
    isNotForHorses: null
};

const AddMedicine = () => {
    const toasterTrigger = () => toast.success("Nýtt lyf hefur verið skráð");

    const { handleCreate } = useCreateMedicine();

    const medicineSubmitHandler = (values) => {
        handleCreate(values);
        toasterTrigger();
        resetFields();
    };

    const { values, handleChange, handleSubmit, errors, resetFields } = useForm(
        addMedicineInitState,
        addMedicineValidation,
        medicineSubmitHandler
    );

    const { strengthTypes } = useGetAllStrengthTypes();
    const { formulationTypes } = useGetAllFormulationTypes();
    const { medicineTypes } = useGetAllMedicineTypes();
    const { packageUnitTypes } = useGetAllPackageUnitTypes();
    const { unitTypes } = useGetAllUnitTypes();

    const medicineDropdownData = getMedicineSelectData(
        formulationTypes,
        strengthTypes,
        medicineTypes,
        packageUnitTypes,
        unitTypes
    );
    const medicineInputData = getMedicineInputData();
    const medicineRadioData = getMedicineRadioData();

    return (
        <div className="admin-add-medicine">
            <div>
                <h2>Skrá nýtt lyf</h2>
            </div>
            <div className="add-medicine-container">
                <>
                    <AdminDropDownList
                        items={medicineDropdownData}
                        values={values}
                        handleChange={handleChange}
                    />
                </>
                <>
                    <AdminInputForm
                        error={errors}
                        items={medicineInputData}
                        values={values}
                        handleChange={handleChange}
                    />
                </>
                <>
                    <AdminRadio
                        error={errors}
                        items={medicineRadioData}
                        values={values}
                        handleChange={handleChange}
                    />
                </>
            </div>
            <div className="admin-add-medicine-buttons">
                <div>
                    <Link to={`/admin/medicine`}>
                        <Button className="back-button">
                            <ArrowBackIcon className="back-icon" />
                            <b>Til baka</b>
                        </Button>
                    </Link>
                </div>
                <div>
                    <AddMedicineButton handleSubmit={handleSubmit} />
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default AddMedicine;
