export const adminManagementArray = [
    {
        itemType: "Lyf",
        english: "New Medicines",
        pathName: "medicine"
    },
    {
        itemType: "Notendur (ekki tilbúið)",
        english: "Users",
        pathName: "user"
    }
];
