import React from "react";
import "./VisitPorcineUpdateDiagnosesList.css";
import VisitPorcineUpdateDiagnosesListItem from "../VisitPorcineUpdateDiagnosesListItem/VisitPorcineUpdateDiagnosesListItem";
import { numberType, porcineUpdateDiagnosesList } from "../../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category PorcineVisit
 * @component maps through porcine diagnoses list and spreads it to list items
 */

const VisitPorcineUpdateDiagnosesList = ({
    diagnosesList,
    establishmentId
}) => {
    return (
        <div className="user-visit-update-view-diagnoses-list">
            {diagnosesList.map((diagnoses) => (
                <VisitPorcineUpdateDiagnosesListItem
                    diagnoses={diagnoses}
                    establishmentId={establishmentId}
                    key={diagnoses.id}
                />
            ))}
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitPorcineUpdateDiagnosesList.propTypes = {
    /**
     * Porcine visit object with diagnosesList and treatment object
     */
    diagnosesList: porcineUpdateDiagnosesList,
    establishmentId: numberType.isRequired
};

export default VisitPorcineUpdateDiagnosesList;
