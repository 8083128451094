import { useState, useEffect } from "react";
import cattleService from "../services/cattleService";

const useGetAllCattleDiseases = () => {
    const [cattleDiseases, setCattleDiseases] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        cattleService
            .getAllCattleDiseases()
            .then((cattleDiseases) => {
                setCattleDiseases(cattleDiseases);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { cattleDiseases, isLoading, error };
};
export default useGetAllCattleDiseases;
