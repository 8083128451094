/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import aquaCultureEndpoint from "./aquaCultureEndpoint";

const apiEndpoint = `${aquaCultureEndpoint}/api/licenses/heilsa/`;
const getAllFisheries = () => {
    return fetch(apiEndpoint, {
        method: "GET",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const getFisheryById = (id) => {
    return fetch(apiEndpoint + id, {
        method: "GET",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

export default { getAllFisheries, getFisheryById };
