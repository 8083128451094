const visitAquaCultureValidation = (values) => {
    const {
        animalCount,
        areaSize,
        biomass,
        species,
        disease,
        medicineList
    } = values;
    const errors = {};

    if (animalCount < 0) {
        errors.animalCount = "Fjöldi má ekki vera mínustala";
    }
    if (areaSize < 0) {
        errors.areaSize = "Rúmmál má ekki vera mínustala";
    }
    if (biomass < 0) {
        errors.biomass = "Lífmassi má ekki vera mínustala";
    }
    if (species === "") {
        errors.species = "Velja þarf fisktegund";
    }
    if (Object.keys(disease) < 1) {
        errors.disease = "Velja þarf sjúkdómsgreiningu";
    }
    if (medicineList.length < 1) {
        errors.medicineList = "Velja þarf lyf";
    }

    return errors;
};

export default visitAquaCultureValidation;
