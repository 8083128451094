import React, { useState } from "react";
import UserVisitList from "../UserVisitsList/UserVisitsList";
import { Paper, CircularProgress, Badge } from "@material-ui/core";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import "./UserVisitsContainer.css";
import UserVisitDateRangePicker from "../UserVisitDateRangePicker/UserVisitDateRangePicker";
import SearchBar from "../../SearchBar/SearchBar";
const UserVisitsContainer = ({
    title,
    isLoading,
    dateChangedCb,
    searchTerm,
    handleChange,
    visitsToShow,
    handleVisitClick,
    unRegisteredVisits
}) => {
    const [openRegisterVisits, setOpenRegisteredVisits] = useState(false);
    const handleOpenUnRegisteredVisits = () => {
        setOpenRegisteredVisits(!openRegisterVisits);
    };
    return (
        <>
            {isLoading ? (
                <div className="user-visit-view-loading">
                    <CircularProgress />
                </div>
            ) : (
                <div className="user-visit-view-container">
                    <Paper className="user-visit-view-paper">
                        <div className="user-visit-view-container-header-container">
                            {unRegisteredVisits.length > 0 && (
                                <div
                                    className="user-visit-view-notification-bell-container"
                                    onClick={handleOpenUnRegisteredVisits}
                                >
                                    <Badge
                                        badgeContent={unRegisteredVisits.length}
                                        overlap="rectangular"
                                    >
                                        <NotificationImportantIcon fontSize="large" />
                                    </Badge>
                                </div>
                            )}

                            <div className="user-visit-view-container-header">
                                <h2>{title}</h2>
                            </div>
                        </div>
                        <div>
                            <UserVisitDateRangePicker
                                dateChanged={dateChangedCb}
                            />
                        </div>
                        <div>
                            <SearchBar
                                searchTerm={searchTerm}
                                handleChange={handleChange}
                                placeHolder="Leita eftir tegund/starfst."
                            />
                        </div>
                        <div className="user-visit-view-list-container">
                            {!openRegisterVisits ? (
                                <UserVisitList
                                    visits={visitsToShow}
                                    handleVisitClick={handleVisitClick}
                                />
                            ) : (
                                <UserVisitList
                                    visits={unRegisteredVisits}
                                    handleVisitClick={handleVisitClick}
                                />
                            )}
                        </div>
                    </Paper>
                </div>
            )}
        </>
    );
};

export default UserVisitsContainer;
