import React, { useState } from "react";
import { InputLabel, Input, InputAdornment, Button } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "./LoginForm.css";
import useForm from "../../../hooks/useForm";
import useLogin from "../../../hooks/useLogin";
import userLoginValidation from "../../../validations/userLoginValidation";
import { Redirect } from "react-router-dom";
import useRmlLogin from "../../../hooks/useRmlLogin";

const loginInit = {
    username: "",
    password: ""
};
const LoginForm = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [successfulLogin, setSuccessfulLogin] = useState(false);
    const { handleRmlLogin } = useRmlLogin();
    const successLogin = () => {
        setSuccessfulLogin(true);
        handleRmlLogin(loginInit);
    };
    const { handleLogin, error, isProcessing } = useLogin(successLogin);
    const { values, handleChange, handleSubmit, errors } = useForm(
        loginInit,
        userLoginValidation,
        handleLogin
    );
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSubmit(e);
        }
    };
    return (
        <div className="login-form-container">
            {error && (
                <div className="login-form-error">Innskráning tókst ekki</div>
            )}
            {successfulLogin && !isProcessing && <Redirect to="/visit" />}
            <div className="login-item">
                <InputLabel className="input-form-label">
                    {errors.username ? errors.username : "Kennitala"}
                </InputLabel>
                <Input
                    className="login-form-item"
                    value={values.username}
                    error={errors.username !== undefined}
                    onChange={handleChange}
                    onKeyPress={(e) => handleKeyPress(e)}
                    name="username"
                    startAdornment={
                        <InputAdornment position="start">
                            <AccountCircleIcon />
                        </InputAdornment>
                    }
                />
            </div>
            <div className="login-item">
                <InputLabel className="input-form-label">
                    {errors.password ? errors.password : "Lykilorð"}
                </InputLabel>
                <Input
                    className="login-form-item"
                    values={values.password}
                    error={errors.password !== undefined}
                    onChange={handleChange}
                    onKeyPress={(e) => handleKeyPress(e)}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    startAdornment={
                        <InputAdornment
                            onClick={() => setShowPassword(!showPassword)}
                            position="start"
                        >
                            {showPassword ? (
                                <VisibilityIcon className="login-password-visibilty" />
                            ) : (
                                <VisibilityOffIcon className="login-password-visibilty" />
                            )}
                        </InputAdornment>
                    }
                />
            </div>
            <div className="login-button-container login-item">
                <Button className="login-button" onClick={handleSubmit}>
                    Skrá inn
                </Button>
            </div>
            {error === "Failed to fetch" && (
                <p className="login-error">
                    Ekki náðist tenging við gagnagrunn
                </p>
            )}
        </div>
    );
};

export default LoginForm;
