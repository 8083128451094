import React from "react";
import "./MedicineSelectionListItem.css";

const MedicineSelectionListItem = ({ medicine, handleSelection }) => {
    return (
        <div
            className="medicine-selection-list-item-container"
            onClick={(e) => handleSelection(e, medicine)}
        >
            <div className="medicine-selection-list-item-name">
                <b>{medicine.name}</b>
                <i> - {medicine.formulationTypeDescription}</i>
            </div>
            <div className="medicine-selection-list-item-info">
                <i>
                    Styrkur: {medicine.strength}
                    {", "}
                    {medicine.strengthTypeIsName}
                    {". "}
                    Eining: {medicine.packageUnit}
                    {", "}
                    {medicine.packageUnitTypeIsName}
                </i>
            </div>
        </div>
    );
};

export default MedicineSelectionListItem;
