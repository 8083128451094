import { useState, useEffect } from "react";
import sheepService from "../services/sheepService";

const useUpdateSheepVisit = () => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [sheepVisits, setSheepVisits] = useState(null);

    useEffect(() => {
        if (sheepVisits && !isProcessing) {
            setProcessing(true);
            sheepService
                .updateSheepVisit(sheepVisits)
                .then(() => {
                    setError(null);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setSheepVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, sheepVisits]);

    const handleUpdateSheepVisit = (incomingVisit) => {
        if (!isProcessing) {
            const outgoingVisit = {
                id: incomingVisit.id,
                arrangementTypeId: parseInt(incomingVisit.arrangementTypeId),
                establishmentId: incomingVisit.establishmentId,
                establishmentName: incomingVisit.establishmentName,
                establishmentSSN: incomingVisit.establishmentSSN,
                animalTypeId: incomingVisit.animalTypeId,
                veterinarianId: incomingVisit.veterinarianId,
                veterinarianSSN: incomingVisit.veterinarianSSN,
                visitDate: incomingVisit.visitDate,
                sheepVisits: prepareSheepVisits(incomingVisit)
            };
            setSheepVisits(outgoingVisit);
        }
    };

    const prepareSheepVisits = (incomingSheepVisits) => {
        var vsList = [];
        incomingSheepVisits.diagnosesList.forEach((sheepVisit) => {
            const visitSheepInputModelList = {
                id: sheepVisit.id,
                visitId: sheepVisit.visitId,
                sheeps: sheepVisit.animalIdList.map((animalId) =>
                    animalId.toString()
                ),
                diseaseId: sheepVisit.diseaseId,
                veterinarianSSN: sheepVisit.veterinarianSSN,
                medicines: prepareMedicines(sheepVisit.medicineList)
            };
            vsList.push(visitSheepInputModelList);
        });
        return vsList;
    };

    const prepareMedicines = (incomingMed) => {
        let medList = [];
        for (let i = 0; i < incomingMed.length; i++) {
            medList.push({
                id: incomingMed[i].id,
                medicineId: incomingMed[i].medicineId,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleUpdateSheepVisit, isProcessing };
};
export default useUpdateSheepVisit;
