import { useState, useEffect } from "react";
import cattleService from "../services/cattleService";

const useUpdateCattleVisit = () => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [cattleVisits, setCattleVisits] = useState(null);

    useEffect(() => {
        if (cattleVisits && !isProcessing) {
            setProcessing(true);
            cattleService
                .updateCattleVisit(cattleVisits)
                .then(() => {
                    setError(null);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setCattleVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, cattleVisits]);

    const handleUpdateCattleVisit = (incomingVisit) => {
        if (!isProcessing) {
            const outgoingVisit = {
                id: incomingVisit.id,
                arrangementTypeId: parseInt(incomingVisit.arrangementTypeId),
                establishmentId: incomingVisit.establishmentId,
                establishmentName: incomingVisit.establishmentName,
                establishmentSSN: incomingVisit.establishmentSSN,
                animalTypeId: incomingVisit.animalTypeId,
                veterinarianId: incomingVisit.veterinarianId,
                veterinarianSSN: incomingVisit.veterinarianSSN,
                visitDate: incomingVisit.visitDate,
                cattleVisits: prepareCattleVisits(incomingVisit)
            };
            setCattleVisits(outgoingVisit);
        }
    };

    const prepareCattleVisits = (incomingCattleVisits) => {
        var vcList = [];
        incomingCattleVisits.diagnosesList.forEach((cattleVisit) => {
            const visitCattleInputModelList = {
                id: cattleVisit.id,
                visitId: cattleVisit.visitId,
                cattles: cattleVisit.animalIdList.map((animalId) =>
                    animalId.toString()
                ),
                diseaseId: cattleVisit.diseaseId,
                veterinarianSSN: cattleVisit.veterinarianSSN,
                medicines: prepareMedicines(cattleVisit.medicineList)
            };
            vcList.push(visitCattleInputModelList);
        });
        return vcList;
    };

    const prepareMedicines = (incomingMed) => {
        let medList = [];
        for (let i = 0; i < incomingMed.length; i++) {
            medList.push({
                id: incomingMed[i].id,
                medicineId: incomingMed[i].medicineId,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleUpdateCattleVisit, isProcessing };
};
export default useUpdateCattleVisit;
