import React from "react";
import AdminCreateUserForm from "../AdminCreateUserForm/AdminCreateUserForm";
import "./AdminCreateUserView.css";

const AdminCreateUserView = () => {
    return (
        <div className="admin-create-user-view">
            <div>
                <h3 className="admin-create-user-view-header">
                    Skrá nýjan notanda
                </h3>
            </div>
            <AdminCreateUserForm />
        </div>
    );
};

export default AdminCreateUserView;
