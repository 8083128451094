import { useState, useEffect } from "react";
import poultryService from "../services/poultryService";

const useGetAllPoultryDiseases = () => {
    const [poultryDiseases, setPoultryDiseases] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        poultryService
            .getAllPoultryDiseases()
            .then((poultryDiseases) => {
                setPoultryDiseases(poultryDiseases);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { poultryDiseases, isLoading, error };
};
export default useGetAllPoultryDiseases;
