import React, { useState } from "react";
import AddDiagnosesButton from "../../Visit/AddDiagnosesButton/AddDiagnosesButton";
import { Modal } from "@material-ui/core";
import VisitTabContainer from "../../Visit/VisitTabContainer/VisitTabContainer";
import ConfirmationButton from "../../Visit/ConfirmationButton/ConfirmationButton";
import CancelButton from "../../Visit/CancelButton/CancelButton";
import DiseaseSelectionView from "../../Visit/DiseaseSelection/DiseaseSelectionView/DiseaseSelectionView";
import MedicineSelectionContainer from "../../Visit/MedicineSelection/MedicineSelectionContainer/MedicineSelectionContainer";
import AnimalSelectionHeader from "../../Visit/AnimalSelection/AnimalSelectionHeader/AnimalSelectionHeader";
import DiagnosesMedicineView from "../../Visit/DiagnosesMedicineSelection/DiagnosesMedicineView/DiagnosesMedicineView";
import useForm from "../../../hooks/useForm";
import { useSelector } from "react-redux";
import "./VisitPorcineDiagnosesContainer.css";
import visitPorcineValidation from "../../../validations/visitPorcineValidation";
import { visitType } from "../../../types";
import useGetAllPorcineDiseases from "../../../hooks/useGetAllPorcineDiseases";
import VisitPorcineUpdateModal from "../VisitPorcineUpdate/VisitPorcineUpdateModal/VisitPorcineUpdateModal";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category PorcineVisit
 * @component holds all the components for user to create a porcine visit
 * @param visitPorcineInitState is the init state for details about porcine visit
 */

const visitPorcineInitState = {
    veterinarianSSN: "",
    disease: {},
    medicineList: [],
    medicineTempList: []
};

const VisitPorcineDiagnosesContainer = ({ visitValues }) => {
    /**
     * React useState hook that keeps track of when the vist porcine modal is open or closed
     */
    const [modalIsOpen, setModalIsOpen] = useState(false);
    /**
     * React useState hook that keeps track of when the vist porcine update modal is open or closed
     */
    const [updatePorcineModalIsOpen, setUpdatePorcineModalIsOpen] = useState(
        false
    );
    /**
     * Counter that select which visit to update
     */
    const [visitToUpdateCounter, setVisitToUpdateCounter] = useState(0);
    /**
     * React useState hook that keeps track of which tab is currently set
     */
    const [tabValue, setTabValue] = useState(0);
    /**
     * React useState hook that keeps track of when the add medicine modal is open or closed
     */
    const [addMedicineOpen, setAddMedicineOpen] = useState(false);
    /**
     * User information from redux store
     */
    const user = useSelector((state) => state.user);
    /**
     * Gets all porcine diseases from database
     */
    const { porcineDiseases } = useGetAllPorcineDiseases();
    /**
     * The porcine visit object fixed for the hook that sends it to backend
     */
    const getDiagnosesObject = (values) => {
        const diagnosesObject = {
            animalCount: values.animalCount,
            disease: values.disease,
            diseaseId: values.disease.id,
            diseaseIsName: values.disease.isName,
            diseaseEnName: values.disease.enName,
            medicineList: values.medicineList,
            veterinarianSSN: user.username
        };

        return diagnosesObject;
    };
    /**
     * Function that handles open for the add medicine modal
     */
    const handleOpenModal = () => {
        setAddMedicineOpen(true);
    };
    /**
     * Function that handles close for the add medicine modal
     */
    const handleCloseModal = () => {
        setAddMedicineOpen(false);
    };
    /**
     * The submit handler pushes the the porcine visit details to the visit object,
     * closes the modal and clears up.
     */
    const visitPorcineSubmitHandler = (values) => {
        values.medicineList.forEach((medicine) => {
            medicine.medicineName = medicine.name;
        });
        const diagnosesObject = getDiagnosesObject(values);
        visitValues.diagnosesList.push(diagnosesObject);
        setModalIsOpen(false);
        visitPorcineInitState.disease = {};
        visitPorcineInitState.medicineList = [];
        visitPorcineInitState.medicineTempList = [];
        resetFields();
        setTabValue(0);
    };
    /**
     * onError function watches which error occurs, sending the user to
     * corresponding tab
     */
    const onError = () => {
        if (errors.disease) {
            setTabValue(0);
        } else {
            setTabValue(1);
        }
    };
    /**
     * Hook that keeps track of when user inserts information about porcine visit
     */
    const { values, errors, handleSubmit, resetFields } = useForm(
        visitPorcineInitState,
        visitPorcineValidation,
        visitPorcineSubmitHandler,
        onError
    );
    /**
     * Function that handles close for the update porcine visit modal
     */
    const handleCloseUpdateVisitModal = () => {
        setUpdatePorcineModalIsOpen(false);
    };
    const handleUpdate = () => {
        setUpdatePorcineModalIsOpen(false);
    };
    /**
     * Function that handles update porcine visit
     */
    const handleOpenUpdateVisitModal = (counter) => {
        setUpdatePorcineModalIsOpen(true);
        setVisitToUpdateCounter(counter);
    };
    /**
    /**
     * Function that handles open for the porcine visit modal
     */
    const handleOpenPorcineVisitModal = () => {
        setModalIsOpen(true);
    };
    /**
     * Function that handles close for the porcine visit modal
     */
    const handleClosePorcineVisitModal = () => {
        setModalIsOpen(false);
    };
    /**
     * Function that handles tab value change
     */
    const handleTabValueChange = (event, newValue) => {
        setAddMedicineOpen(false);
        setTabValue(newValue);
    };
    return (
        <div className="visit-porcine-diagnose-container">
            <div className="visit-porcine-diagnoses-body">
                <div className="visit-porcine-diagnoses-header">
                    <AnimalSelectionHeader values={visitValues} />
                </div>
                <div>
                    <DiagnosesMedicineView
                        visitValues={visitValues}
                        handleOpenUpdateVisitModal={handleOpenUpdateVisitModal}
                    />
                </div>
                <div className="visit-porcine-add-button-area">
                    <AddDiagnosesButton
                        handleClick={handleOpenPorcineVisitModal}
                    />
                </div>
            </div>
            <Modal
                className="visit-porcine-modal"
                open={modalIsOpen}
                onClose={handleClosePorcineVisitModal}
            >
                <div className="visit-porcine-modal-container">
                    <VisitTabContainer
                        handleTabValueChange={handleTabValueChange}
                        tabValue={tabValue}
                        animalSelectionTitle="Hópar"
                        hasSelectionArea={false}
                    />
                    {tabValue === 0 && (
                        <DiseaseSelectionView
                            diseases={porcineDiseases}
                            visitDetailValues={values}
                            errors={errors}
                        />
                    )}
                    {tabValue === 1 && (
                        <MedicineSelectionContainer
                            visitDetailValues={values}
                            visitDate={visitValues.visitDate}
                            isSlaughterBan={false}
                            isMilkBan={false}
                            hasDateLastGiven={false}
                            amountLabel="Heildarmagn í vitjun"
                            handleOpenModal={handleOpenModal}
                            handleCloseModal={handleCloseModal}
                            addMedicineOpen={addMedicineOpen}
                            errors={errors}
                        />
                    )}
                    <div className="visit-porcine-diagnoses-button-area">
                        <CancelButton
                            disabled={addMedicineOpen}
                            handleCancel={handleClosePorcineVisitModal}
                        />
                        <ConfirmationButton
                            disabled={addMedicineOpen}
                            handleSubmit={handleSubmit}
                            title="Staðfesta Meðhöndlun"
                        />
                    </div>
                </div>
            </Modal>
            <VisitPorcineUpdateModal
                visitValues={visitValues}
                updatePorcineModalIsOpen={updatePorcineModalIsOpen}
                handleCloseUpdateVisitModal={handleCloseUpdateVisitModal}
                visitToUpdateCounter={visitToUpdateCounter}
                handleUpdate={handleUpdate}
            />
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitPorcineDiagnosesContainer.propTypes = {
    visitValues: visitType
};

export default VisitPorcineDiagnosesContainer;
