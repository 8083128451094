import React from "react";
import { Card, CardContent, Typography, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import "./DiagnosesMedicineCard.css";

const DiagnosesMedicineCard = ({
    diagnoses,
    animalTypeId,
    counter,
    handleRemove,
    handleOpenUpdateVisitModal
}) => {
    var dateNow = new Date();
    var slaughterBanDate = new Date(diagnoses.slaughterBanDate);
    const slaughterBanDays = Math.floor(
        (slaughterBanDate - dateNow) / (1000 * 3600 * 24) + 1
    );
    slaughterBanDate = slaughterBanDate.toDateString();
    slaughterBanDate = slaughterBanDate.substring(slaughterBanDate.indexOf(" ") + 1);

    return (
        <Card>
            <CardContent className="diagnoses-medicine-card-container">
                <div className="diagnoses-medicine-button-container">
                    <IconButton
                        aria-label="delete"
                        className="diagnoses-medicine-card-update-container"
                        onClick={() => handleOpenUpdateVisitModal(counter)}
                    >
                        <CreateIcon className="diagnoses-medicine-card-update-icon" />
                        <span className="diagnoses-medicine-card-update-text">
                            Breyta
                        </span>
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        className="diagnoses-medicine-card-delete-container"
                        onClick={() => handleRemove(counter)}
                    >
                        <ClearIcon className="diagnoses-medicine-card-delete-icon" />
                        <span className="diagnoses-medicine-card-delete-text">
                            Eyða
                        </span>
                    </IconButton>
                </div>
                <Typography className="diagnoses-medicine-card-title">
                    <b>Meðhöndlun {counter + 1}.</b>
                </Typography>
                <Typography>
                    <b>{"Greining: "}</b>
                    {diagnoses.disease.isName}
                </Typography>
                <Typography>
                    <b>Lyf: </b>
                    {diagnoses.medicineList.map((med, i) => (
                        <span key={i}>
                            {`${med.name}, ${med.strength}${med.strengthTypeIsName}`}{" "}
                            <b>Heildarmagn:</b>{" "}
                            {`${med.medicineAmount}. ${med.packageUnitTypeIsName}`}
                            {i < diagnoses.medicineList.length - 1 ? "," : ""}{" "}
                        </span>
                    ))}
                </Typography>
                {animalTypeId !== 3 && (
                    <div className="diagnoses-medicine-card-bottom">
                        {animalTypeId < 3 ? (
                            <Typography>
                                <b>Dýrategund: </b>
                                {diagnoses.speciesIsName}
                            </Typography>
                        ) : (
                            <div></div>
                        )}
                        <Typography className="diagnoses-medicine-card-bottom-right">
                            <b>Fjöldi Dýra: </b>
                            {diagnoses.animalCount}
                        </Typography>
                    </div>
                )}
                {diagnoses.highestSlaughterBanDate && (
                    <b>
                        Lengsta sláturbann: {slaughterBanDate} ({slaughterBanDays} dagar)
                    </b>
                )}
            </CardContent>
        </Card>
    );
};

export default DiagnosesMedicineCard;
