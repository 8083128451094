const userLoginValidation = (values) => {
    const { username, password } = values;

    const errors = {};

    if (username === "") {
        errors.username = "Kennitölu vantar";
    }
    if (username.length !== 10) {
        errors.username = "Kennitala er 10 tölustafir";
    }
    if (password === "") {
        errors.password = "Lykilorð vantar";
    }
    if (password.length < 4) {
        errors.password = "Lykilorð er að lágmarki 8 stafir";
    }
    if (username !== "" && !/^\d+$/.test(username)) {
        errors.username = "Kennitala hefur ekki bókstafi";
    }

    return errors;
};

export default userLoginValidation;
