import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import useGetVisitsByUserId from "../../../hooks/useGetVisitsByUserId";
import "./UserVisitView.css";
import useSearchBar from "../../../hooks/useSearchBar";
import { Redirect } from "react-router-dom";
import UserVisitsContainer from "../UserVisitsContainer/UserVisitsContainer";
import useGetUnRegisteredVisitsByUserId from "../../../hooks/useGetUnRegisteredVisitsByUserId";

const UserVisitsView = () => {
    const user = useSelector((state) => state.user);
    const { visits, isLoading } = useGetVisitsByUserId(user.id);
    const { unRegisteredVisits } = useGetUnRegisteredVisitsByUserId(user.id);
    const [visitId, setVisitId] = useState(null);
    const [visitsToShow, setVisitsToShow] = useState([]);
    const { searchResult, searchTerm, handleChange } = useSearchBar(
        visits,
        "searchKey"
    );
    const handleVisitClick = (visit) => {
        setVisitId(visit.id);
    };
    const dateChangedCb = useCallback(
        (startDate, endDate) => {
            setVisitsToShow(
                searchResult.filter(
                    (x) =>
                        x.visitDate.split("T")[0] >= startDate &&
                        x.visitDate.split("T")[0] <= endDate
                )
            );
        },
        [searchResult]
    );
    return (
        <>
            {visitId && <Redirect to={`/uservisits/${visitId}`} />}
            <UserVisitsContainer
                title="Mínar Vitjanir"
                isLoading={isLoading}
                dateChangedCb={dateChangedCb}
                searchTerm={searchTerm}
                handleChange={handleChange}
                visitsToShow={visitsToShow}
                handleVisitClick={handleVisitClick}
                unRegisteredVisits={unRegisteredVisits}
            />
        </>
    );
};

export default UserVisitsView;
