import React, { useState } from "react";
import { Modal, Paper, CircularProgress } from "@material-ui/core";
import "./UpdateVisitPoultrySpeciesList.css";
import UpdateVisitButtons from "../../../Visit/UpdateVisit/UpdateVisitButtons/UpdateVisitButtons";
import useGetAllPoultrySpecies from "../../../../hooks/useGetAllPoultrySpecies";
import VisitPoultrySelectList from "../../VisitPoultrySelectList/VisitPoultrySelectList";
import {
    boolType,
    funcType,
    unhandledInputType,
    poultryUpdateDiagnoses
} from "../../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category PoultryVisit
 * @component that handles species change inside poultry visit
 */

const UpdateVisitPoultrySpeciesList = ({
    open,
    handleClose,
    unhandledSpeciesId,
    diagnoses
}) => {
    /**
     * React useState hook to reload component on change
     */
    const [reload, setReload] = useState(false);
    /**
     * Hook that gets all poultry species from the database
     */
    const { poultrySpecies, isLoading, error } = useGetAllPoultrySpecies();
    /**
     * Handle cancel function, resets input if user cancels change
     */
    const handleCancel = () => {
        diagnoses.speciesId = unhandledSpeciesId;
        diagnoses.speciesIsName = poultrySpecies[unhandledSpeciesId - 1].isName;
        handleClose();
    };
    /**
     * Handle change function
     */
    const handleChange = (e) => {
        diagnoses.speciesId = e.target.value;
        diagnoses.speciesIsName = poultrySpecies[e.target.value - 1].isName;
        setReload(!reload);
    };
    const errors = {
        siteId: undefined
    };
    return (
        <Modal
            className="update-visit-species-list-modal"
            open={open}
            onClose={handleClose}
        >
            {isLoading ? (
                <CircularProgress />
            ) : (
                <Paper className="update-visit-species-list-paper">
                    {error ? (
                        <div>Ekki tókst að sækja tegundir</div>
                    ) : (
                        <>
                            <VisitPoultrySelectList
                                values={diagnoses}
                                handleChange={handleChange}
                                species={poultrySpecies}
                                errors={errors}
                            />
                            <UpdateVisitButtons
                                handleClose={handleClose}
                                handleCancel={handleCancel}
                            />
                        </>
                    )}
                </Paper>
            )}
        </Modal>
    );
};

/**
 * This React app has generalized Proptypes
 */
UpdateVisitPoultrySpeciesList.propTypes = {
    /**
     * Boolean keeps track of is modal is open
     */
    open: boolType.isRequired,
    handleClose: funcType,
    /**
     * Saved unhandled input variable for when user cancels change
     */
    unhandledSpeciesId: unhandledInputType,
    /**
     * Poultry visit object with diagnosesList and treatment object
     */
    diagnoses: poultryUpdateDiagnoses
};

export default UpdateVisitPoultrySpeciesList;
