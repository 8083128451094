import React from "react";
import { FormControl, Select, MenuItem, FormLabel } from "@material-ui/core";
import "./VisitAquaCultureSelectList.css";
import {
    aquaCultureDiagnoses,
    funcType,
    errorsType,
    aquaCultureSpeciesList
} from "../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category AquaCultureVisit
 * @component holds selection list for aqua culture species
 */

const VisitAquaCultureSelectList = ({
    values,
    handleChange,
    species,
    errors
}) => {
    return (
        <div className="visit-aqua-culture-select-list">
            <FormControl
                variant="filled"
                className="visit-aqua-culture-form-control"
            >
                <FormLabel className="visit-aqua-culture-select-label">
                    {values.species !== "" || errors.species === undefined ? (
                        <b className="visit-aqua-species-label">
                            Veldu Fisktegund
                        </b>
                    ) : (
                        <b className="visit-aqua-species-label-error">
                            {errors.species}
                        </b>
                    )}
                </FormLabel>
                <Select
                    value={values.speciesId}
                    onChange={handleChange}
                    name="speciesId"
                >
                    {species.map((s) => (
                        <MenuItem value={s.id} key={s.id}>
                            {s.isName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitAquaCultureSelectList.propTypes = {
    /**
     * Aqua culture visit details object
     */
    values: aquaCultureDiagnoses,
    /**
     * Function that handles change when user changes species
     */
    handleChange: funcType,
    /**
     * The species list
     */
    species: aquaCultureSpeciesList,
    errors: errorsType
};

export default VisitAquaCultureSelectList;
