import React from "react";
import moment from "moment";
import "./CalendarSelection.css";
import MomentUtils from "@date-io/moment";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";

const CalendarSelection = ({
    values,
    label,
    valueName,
    disabled,
    error,
    errorMessage,
    dateGiven,
    getDateDiffCallBack
}) => {
    const getDateNow = () => {
        var dateNow = new Date();
        moment.locale("is");
        values[valueName] = moment(dateNow).format("YYYY-MM-DD");
        return moment(dateNow).format("YYYY-MM-DD");
    };
    const fillDateGiven = () => {
        values[valueName] = dateGiven;
        return dateGiven;
    };
    const [selectedDate, setSelectedDate] = React.useState(
        dateGiven ? fillDateGiven : getDateNow
    );
    const handleChangeDate = (e) => {
        setSelectedDate(e);
        values[valueName] = moment(e._d).format("YYYY-MM-DD");
        if (getDateDiffCallBack) {
            getDateDiffCallBack(valueName, "dateLastGiven");
        }
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="calendar-selection-container">
                <KeyboardDatePicker
                    disabled={disabled}
                    className="calendar-picker"
                    margin="normal"
                    label={label}
                    format="DD/MM/yyyy"
                    value={selectedDate}
                    onChange={handleChangeDate}
                    KeyboardButtonProps={{
                        "aria-label": "change date"
                    }}
                    error={error}
                    helperText={errorMessage}
                />
            </div>
        </MuiPickersUtilsProvider>
    );
};

export default CalendarSelection;
