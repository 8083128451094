import React from "react";
import { Modal, Paper } from "@material-ui/core";
import CalendarSelection from "../../CalendarSelection/CalendarSelection";
import "./UpdateVisitDateModal.css";
import UpdateVisitButtons from "../UpdateVisitButtons/UpdateVisitButtons";

const UpdateVisitDateModal = ({ visit, open, handleClose, unhandledDate }) => {
    const handleCancel = () => {
        visit.visitDate = unhandledDate;
        handleClose();
    };
    return (
        <Modal
            className="update-visit-date-modal"
            open={open}
            onClose={handleClose}
        >
            <Paper className="update-visit-date-paper">
                <CalendarSelection
                    values={visit}
                    label="Breyta Dagsetningu"
                    valueName="visitDate"
                    dateGiven={visit.visitDate}
                />
                <UpdateVisitButtons
                    handleClose={handleClose}
                    handleCancel={handleCancel}
                />
            </Paper>
        </Modal>
    );
};
export default UpdateVisitDateModal;
