import React, { useState } from "react";
import { Modal, Paper, TextField, InputAdornment } from "@material-ui/core";
import UpdateVisitButtons from "../UpdateVisitButtons/UpdateVisitButtons";
import "./UpdateVisitDiagnosesMedicineDetailsModal.css";
import { floatInputHelper } from "../../../../helpers/floatInputHelper";

const UpdateVisitDiagnosesMedicineDetailsModal = ({
    open,
    handleClose,
    medicine,
    unhandledAmount
}) => {
    const [medicineAmount, setMedicineAmount] = useState(
        medicine.medicineAmount
    );
    const [medicineAmountError, setMedicineAmountError] = useState(false);
    const handleCancel = () => {
        medicine.medicineAmount = unhandledAmount;
        handleClose();
    };
    const handleChange = (e) => {
        var input = floatInputHelper(e.target.value);
        if (input !== null) {
            setMedicineAmount(input);
            medicine.medicineAmount = parseFloat(input);
            setMedicineAmountError(false);
        }
        if (medicine.medicineAmount <= 0) {
            setMedicineAmountError(true);
        }
    };
    return (
        <Modal
            className="update-visit-diagnoses-medicine-details-modal"
            open={open}
            onClose={handleClose}
        >
            <Paper className="update-visit-diagnoses-medicine-details-paper">
                <TextField
                    className="outlined-input"
                    type="text"
                    label={
                        !medicineAmountError
                            ? "magn í meðferð"
                            : "magn má ekki vera 0"
                    }
                    size="small"
                    error={medicineAmountError}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {medicine.unitType}
                            </InputAdornment>
                        )
                    }}
                    value={medicineAmount}
                    name="medicineAmount"
                    onChange={handleChange}
                />
                <UpdateVisitButtons
                    handleClose={handleClose}
                    handleCancel={handleCancel}
                    disabled={medicineAmountError}
                />
            </Paper>
        </Modal>
    );
};
export default UpdateVisitDiagnosesMedicineDetailsModal;
