import { useState, useEffect } from "react";
import medicineTypeService from "../services/medicineTypeService";

const useGetAllMedicineTypes = () => {
    const [medicineTypes, setMedicineTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        medicineTypeService
            .getAllMedicineTypes()
            .then((medicineTypes) => {
                setMedicineTypes(medicineTypes);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { medicineTypes, isLoading, error };
};

export default useGetAllMedicineTypes;
