import React from "react";
import { TextField } from "@material-ui/core";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category PetVisit
 * @component is a general component for each input that user can change
 *            in create/update pet visit
 */

const VisitPetInput = ({ handleChange, values, type, name, label, errors }) => {
    return (
        <div>
            <TextField
                label={
                    values[name] > 0 || errors[name] === undefined
                        ? label
                        : errors[name]
                }
                type={type}
                name={name}
                onChange={handleChange}
                value={values[name]}
                error={values[name] < 1 && errors[name] !== undefined}
            />
        </div>
    );
};

export default VisitPetInput;
