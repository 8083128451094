import React from "react";
import CalendarSelection from "../CalendarSelection/CalendarSelection";
import ArrangementSelection from "../ArrangementSelection/ArrangementSelection";
import EstablishmentsSelectionView from "../EstablishmentsSelection/EstablishmentsSelectionView/EstablishmentsSelectionView";
import "./VisitInfoView.css";
import { CircularProgress } from "@material-ui/core";
import LiveStockEstablishmentsSelectionView from "../EstablishmentsSelection/LiveStockEstablishmentSelectionView/LiveStockEstablishmentSelectionView";

const VisitInfoView = ({
    values,
    handleChange,
    establishments,
    handleSubmit,
    errors,
    isLoading,
    establishmentHasType,
    isLiveStockAnimal
}) => {
    return (
        <div className="visit-info-view-container">
            <div className="visit-info-view-upper-area">
                <CalendarSelection
                    values={values}
                    handleChange={handleChange}
                    label="Dagsetning vitjunar"
                    valueName="visitDate"
                    disabled={false}
                />
                <ArrangementSelection
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                />
            </div>
            {isLiveStockAnimal ? (
                <div className="visit-info-view-lower-area">
                    <LiveStockEstablishmentsSelectionView
                        values={values}
                        handleSubmit={handleSubmit}
                        errors={errors}
                    />
                </div>
            ) : (
                <div className="visit-info-view-lower-area">
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <EstablishmentsSelectionView
                            establishments={establishments}
                            values={values}
                            handleSubmit={handleSubmit}
                            errors={errors}
                            hasType={establishmentHasType}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default VisitInfoView;
