/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/poultryvisits`;
const apiPoultryLicensesEndPoint = `${endpoint}/api/licenses/poultries`;

const getAllPoultries = () => {
    return fetch(apiPoultryLicensesEndPoint, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const getAllPoultrySpecies = () => {
    return fetch(`${apiEndpoint}/species`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const getAllPoultryDiseases = () => {
    return fetch(apiEndpoint + "/diseases", {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const createPoultryVisit = (poultryVisit) => {
    return fetch(apiEndpoint, {
        method: "POST",
        body: JSON.stringify(poultryVisit),
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const updatePoultryVisit = (poultryVisit) => {
    return fetch(apiEndpoint, {
        method: "PUT",
        body: JSON.stringify(poultryVisit),
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

export default {
    getAllPoultries,
    getAllPoultrySpecies,
    getAllPoultryDiseases,
    createPoultryVisit,
    updatePoultryVisit
};
