let envUrl = null;
let envPort = null;

if (process.env.NODE_ENV === "production") {
    envUrl = "https://heilsawebapi.mast.is";
    envPort = 443;
}

const url = envUrl || "http://localhost";
const port = envPort || 5000;
const endpoint = `${url}:${port}`;
module.exports = endpoint;
