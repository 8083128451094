import React from "react";
import VisitGoatUpdateDiagnosesListItem from "../../../VisitGoat/VisitGoatUpdate/VisitGoatUpdateDiagnosesListItem/VisitGoatUpdateDiagnosesListItem";
import { Button, Modal, Paper } from "@material-ui/core";
import "./VisitGoatUpdateModal.css";

const VisitGoatUpdateModal = ({
    visitValues,
    updateGoatModalIsOpen,
    handleCloseUpdateVisitModal,
    visitToUpdateCounter,
    handleUpdate,
    animals,
    errors
}) => {
    return (
        <Modal
            className="update-visit-goat-modal"
            open={updateGoatModalIsOpen}
            onClose={handleCloseUpdateVisitModal}
        >
            <Paper className="update-visit-goat-paper">
                <div className="update-visit-goat-modal-header">
                    Uppfæra vitjun
                </div>
                <VisitGoatUpdateDiagnosesListItem
                    diagnoses={visitValues.diagnosesList[visitToUpdateCounter]}
                    establishmentId={visitValues.establishmentId}
                    animals={animals}
                    errors={errors}
                    animalType={"geitur"}
                />
                <div className="diagnoses-medicine-view-update-card-button-area">
                    <Button
                        className="diagnoses-medicine-view-update-card-cancel-button"
                        onClick={handleCloseUpdateVisitModal}
                    >
                        <b>Til baka</b>
                    </Button>
                    <Button
                        className="diagnoses-medicine-view-update-card-confirm-button"
                        onClick={handleUpdate}
                    >
                        <b>Vista</b>
                    </Button>
                </div>
            </Paper>
        </Modal>
    );
};

export default VisitGoatUpdateModal;
