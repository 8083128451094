import sheep from "../../../images/sheep.png";
import cow from "../../../images/cow.png";
import horse from "../../../images/horse.png";
import chicken from "../../../images/chicken.png";
import fish from "../../../images/fish.png";
import pig from "../../../images/pig.png";
import goat from "../../../images/goat.png";
import mink from "../../../images/mink.png";
import pet from "../../../images/pet.png";

export const animalArray = [
    {
        animalType: "Sauðfé",
        image: sheep,
        english: "sheep"
    },
    {
        animalType: "Nautgripir",
        image: cow,
        english: "cattle"
    },
    {
        animalType: "Hestar",
        image: horse,
        english: "horse"
    },
    {
        animalType: "Alifuglar",
        image: chicken,
        english: "poultry"
    },
    {
        animalType: "Fiskeldi",
        image: fish,
        english: "aquaculture"
    },
    {
        animalType: "Svín",
        image: pig,
        english: "porcine"
    },
    {
        animalType: "Geitur",
        image: goat,
        english: "goat"
    },
    {
        animalType: "Loðdýr",
        image: mink,
        english: "furanimals"
    },
    {
        animalType: "Gæludýr",
        image: pet,
        english: "pet"
    }
];
