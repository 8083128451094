import React, { useState } from "react";
import { Modal, Paper, CircularProgress } from "@material-ui/core";
import "./UpdateVisitAquaCultureSpeciesList.css";
import UpdateVisitButtons from "../../../Visit/UpdateVisit/UpdateVisitButtons/UpdateVisitButtons";
import VisitAquaCultureSelectList from "../../VisitAquaCultureSelectList/VisitAquaCultureSelectList";
import useGetAllAquaCultureSpecies from "../../../../hooks/useGetAllAquaCultureSpecies";
import {
    boolType,
    funcType,
    unhandledInputType,
    aquaCultureUpdateDiagnoses
} from "../../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category AquaCultureVisit
 * @component that handles species change inside aqua culture visit
 */

const UpdateVisitAquaCultureSpeciesList = ({
    open,
    handleClose,
    unhandledSpeciesId,
    visit
}) => {
    /**
     * React useState hook to reload component on change
     */
    const [reload, setReload] = useState(false);
    /**
     * Hook that gets all poultry species from the database
     */
    const {
        isLoading,
        aquaCultureSpecies,
        error
    } = useGetAllAquaCultureSpecies();
    /**
     * Handle cancel function, resets input if user cancels change
     */
    const handleCancel = () => {
        visit.speciesId = unhandledSpeciesId;
        visit.speciesIsName = aquaCultureSpecies[unhandledSpeciesId - 1].isName;
        visit.speciesEnName = aquaCultureSpecies[unhandledSpeciesId - 1].enName;
        handleClose();
    };
    /**
     * Handle change function
     */
    const handleChange = (e) => {
        visit.speciesId = e.target.value;
        visit.speciesIsName = aquaCultureSpecies[e.target.value - 1].isName;
        visit.speciesEnName = aquaCultureSpecies[e.target.value - 1].enName;
        setReload(!reload);
    };
    const errors = {
        siteId: undefined
    };

    return (
        <Modal
            className="update-visit-species-list-modal"
            open={open}
            onClose={handleClose}
        >
            {isLoading ? (
                <CircularProgress />
            ) : (
                <Paper className="update-visit-species-list-paper">
                    {error ? (
                        <div>Ekki tókst að sækja tegundir</div>
                    ) : (
                        <>
                            <VisitAquaCultureSelectList
                                values={visit}
                                handleChange={handleChange}
                                species={aquaCultureSpecies}
                                errors={errors}
                            />
                            <UpdateVisitButtons
                                handleClose={handleClose}
                                handleCancel={handleCancel}
                            />
                        </>
                    )}
                </Paper>
            )}
        </Modal>
    );
};

/**
 * This React app has generalized Proptypes
 */
UpdateVisitAquaCultureSpeciesList.propTypes = {
    /**
     * Boolean keeps track of is modal is open
     */
    open: boolType.isRequired,
    handleClose: funcType,
    /**
     * Saved unhandled input variable for when user cancels change
     */
    unhandledSpeciesId: unhandledInputType,
    /**
     * Poultry visit object with diagnosesList and treatment object
     */
    diagnoses: aquaCultureUpdateDiagnoses
};

export default UpdateVisitAquaCultureSpeciesList;
