import React from "react";
import {
    FormControl,
    InputLabel,
    Input,
    InputAdornment,
    IconButton
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const SearchBar = ({ searchTerm, handleChange, placeHolder, handleClick }) => {
    return (
        <div className="search-bar">
            <FormControl>
                <InputLabel className="placeholder">{placeHolder}</InputLabel>
                <Input
                    type="text"
                    autoComplete="off"
                    value={searchTerm}
                    onChange={handleChange}
                    autoFocus
                    rows={2}
                    endAdornment={
                        <InputAdornment position="end">
                            {handleClick ? (
                                <IconButton onClick={handleClick}>
                                    <SearchIcon />
                                </IconButton>
                            ) : (
                                <SearchIcon />
                            )}
                        </InputAdornment>
                    }
                />
            </FormControl>
        </div>
    );
};
export default SearchBar;
