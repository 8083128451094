import { useState, useEffect } from "react";
import loginRmlService from "../services/loginRmlService";
import { useDispatch } from "react-redux";
import { setTokenInfo } from "../redux/actions/rmlTokenActions";

const useRmlLogin = () => {
    const [rmlError, setRmlError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [loginInput, setLoginInput] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (loginInput && !isProcessing) {
            setProcessing(true);
            loginRmlService
                .rmlLogin()
                .then((data) => {
                    dispatch(setTokenInfo(data));
                    setRmlError(null);
                })
                .catch((error) => setRmlError(error.message))
                .finally(() => {
                    setLoginInput(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, loginInput, dispatch]);

    const handleRmlLogin = (incomingLogin) => {
        if (!isProcessing) {
            setLoginInput(incomingLogin);
        }
    };
    return { rmlError, handleRmlLogin, isProcessing };
};
export default useRmlLogin;
