import React from "react";
import "./AdminMedicineInfo.css";

const AdminMedicineInfo = ({ medicine }) => {
    return (
        <div className="admin-medicine-selection-list-item-name">
            <b>{medicine.name}</b> - {medicine.medicineTypeIsName} - {medicine.formulationTypeDescription} 
            <div className="admin-medicine-selection-list-item-info">
                <div>
                    Styrkur: {medicine.strength}
                    {" "}
                    {medicine.strengthTypeIsName}
                    {". "}
                    Eining: {medicine.packageUnit} 
                    {" "}
                    {medicine.packageUnitTypeIsName}
                    {"."}
                </div>
            </div>
        </div>
    );
};
export default AdminMedicineInfo;