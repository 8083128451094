
//import isNumeric from "./validationHelperFunctions";

const addMedicineValidation = (values) => {
    const {
        name,
        formulationTypeId,
        productId,
        atcVet,
        medicineTypeId,
        strengthTypeId,
        strength,
        unitTypeId,
        packageUnitTypeId,
        packageUnit,
        milkBanDays,
        competitionBanDays,
        slaughterBanDays,
        isAntibiotic,
        isMycobacteriumVaccine,
        isVetOnlyAdminister,
        isBannedForFarmedAnimal,
        isNotForHorses
    } = values;

    const errors = {};

    if (name === "") { errors.name = "Nafn vantar" }
    if (name.length > 99) { errors.name = "Nafn má ekki innihalda fleiri en 100 stafi" }

    if (formulationTypeId < 1) { errors.formulation = "Þessi tegund lyfjaforms er ekki til" }

    if (productId.length > 19) { errors.productId = "Norsk vörunúmer má ekki innihalda fleiri en 20 stafi" }

    if (atcVet.length > 19) { errors.atcVet = "AtcVet má ekki innihalda fleiri en 20 stafi" }

    if (medicineTypeId < 1) { errors.medicineTypeId = "Þessi tegund lyfs er ekki til" }

    if (isNaN(strength) && typeof (strength) !== "number") { errors.strength = "Styrkur lyfs verður að vera tala" }
    if (strength <= 0) { errors.strength = "Styrkleiki lyfs má ekki vera núll eða minni en núll tala" }

    if (strengthTypeId < 1) { errors.strengthTypeId = "Þessi tegund styrkleika er ekki til" }
    if (unitTypeId < 1) { errors.unitTypeId = "Þessi tegund einingar er ekki til" }

    if (isNaN(packageUnit) && typeof (packageUnit) !== "number") { errors.packageUnit = "Eining lyfs verður að vera tala" }
    if (packageUnit <= 0) { errors.packageUnit = "Eining lyfs má ekki vera núll eða minni en núll tala" }

    if (packageUnitTypeId < 1) { errors.packageUnitTypeId = "Þessi tegund pakkningar er ekki til" }

    if (milkBanDays === "") { errors.milkBanDays = "Mjólkurbann verður að vera núll eða fleiri dagar" }
    if ((isNaN(milkBanDays) && typeof (milkBanDays) !== "number")) { errors.milkBanDays = "Mjólkurbann verður að vera núll eða fleiri dagar" }
    if (milkBanDays < 0) { errors.milkBanDays = "Mjólkurbann verður að vera núll eða fleiri dagar" }

    if (competitionBanDays === "") { errors.competitionBanDays = "Keppnisbann verður að vera núll eða fleiri dagar" }
    if ((isNaN(competitionBanDays) && typeof (competitionBanDays) !== "number")) { errors.competitionBanDays = "Keppnisbann verður að vera núll eða fleiri dagar" }
    if (competitionBanDays < 0) { errors.competitionBanDays = "Keppnisbann verður að vera núll eða fleiri dagar" }

    if (slaughterBanDays === "") { errors.slaughterBanDays = "Sláturbann verður að vera núll eða fleiri dagar" }
    if ((isNaN(slaughterBanDays) && typeof (slaughterBanDays) !== "number")) { errors.slaughterBanDays = "Sláturbann verður að vera núll eða fleiri dagar" }
    if (slaughterBanDays < 0) { errors.slaughterBanDays = "Sláturbann verður að vera núll eða fleiri dagar" }

    if (isAntibiotic === null || isAntibiotic === undefined) { errors.isAntibiotic = "Það er krafa að velja já eða nei" }
    if (isMycobacteriumVaccine === null || isMycobacteriumVaccine === undefined) { errors.isMycobacteriumVaccine = "Það er krafa að velja já eða nei" }
    if (isVetOnlyAdminister === null || isVetOnlyAdminister === undefined) { errors.isVetOnlyAdminister = "Það er krafa að velja já eða nei" }
    if (isBannedForFarmedAnimal === null || isBannedForFarmedAnimal === undefined) { errors.isBannedForFarmedAnimal = "Það er krafa að velja já eða nei" }
    if (isNotForHorses === null || isNotForHorses === undefined) { errors.isNotForHorses = "Það er krafa að velja já eða nei" }
    return errors;
}

export default addMedicineValidation;