import React from "react";
import { Modal, Paper } from "@material-ui/core";
import UpdateVisitButtons from "../UpdateVisitButtons/UpdateVisitButtons";
import "./UpdateVisitAnimalSelectionModal.css";
import VisitAnimalSelection from "../../VisitAnimalSelection/VisitAnimalSelection";

const UpdateVisitAnimalSelectionModal = ({
    open,
    handleClose,
    animals,
    values,
    errors,
    animalType
}) => {
    const handleCancel = () => {
        handleClose();
    };
    animals.forEach((animal) => {
        values.animalIdList.forEach((animalId) => {
            if (animal.gripur_numer.toString() === animalId.toString()) {
                animal.isSelected = true;
            }
        });
    });
    return (
        <Modal
            className="update-visit-animal-selection-modal"
            open={open}
            onClose={handleClose}
        >
            <Paper className="update-visit-animal-selection-paper">
                <div className="update-visit-animal-selection-list">
                    <VisitAnimalSelection
                        values={values}
                        animals={animals}
                        errors={errors}
                        animalType={animalType}
                    />
                </div>
                <UpdateVisitButtons
                    handleClose={handleClose}
                    handleCancel={handleCancel}
                />
            </Paper>
        </Modal>
    );
};
export default UpdateVisitAnimalSelectionModal;
