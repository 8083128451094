import { useState, useEffect } from "react";
import rmlEstablishmentService from "../services/rmlEstablishmentsService";

const useGetAllEstablishmentsFromRml = (searchTerm, id, timeToCall) => {
    const [establishments, setEstablishments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        if (timeToCall && searchTerm.length > 1) {
            rmlEstablishmentService
                .getAllEstablishments(searchTerm, id)
                .then((data) => {
                    setEstablishments(data);
                    setError(null);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [searchTerm, id, timeToCall]);
    return { establishments, isLoading, error };
};

export default useGetAllEstablishmentsFromRml;
