import React from "react";
import "./VisitAreaSelection.css";
import VisitAquaCultureInput from "../VisitAquaCultureInput/VisitAquaCultureInput";
import VisitAquaCultureRadio from "../VisitAquaCultureRadio/VisitAquaCultureRadio";
import { Paper } from "@material-ui/core";
import useGetAllAquaCultureSpecies from "../../../hooks/useGetAllAquaCultureSpecies";
import VisitAquaCultureSelectList from "../VisitAquaCultureSelectList/VisitAquaCultureSelectList";
import {
    visitType,
    funcType,
    errorsType,
    aquaCultureDiagnoses
} from "../../../types";
/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category AquaCultureVisit
 * @component holds all the selection components that user can fill in for aqua culture visit details.
 *            User has 6 options, 2 of them are required:
 *            If the user is visiting a sea based aquaculture, the user needs to select site
 *            The user needs to select species
 *            4 of the options are optional:
 *            The animal count, fish count in this case
 *            The area size - size of the area they are treating in
 *            The biomass - avg weight of 1 fish * fish count
 *            and finally the temperature of the water
 */

const VisitAreaSelection = ({ visitValues, values, handleChange, errors }) => {
    const { aquaCultureSpecies } = useGetAllAquaCultureSpecies();
    values.speciesList = aquaCultureSpecies;
    return (
        <Paper className="area-selection-container-paper">
            <div className="area-selection-container">
                <div className="area-selection">
                    <VisitAquaCultureRadio
                        visitValues={visitValues}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                    />
                    <VisitAquaCultureSelectList
                        values={values}
                        handleChange={handleChange}
                        species={aquaCultureSpecies}
                        errors={errors}
                    />
                </div>
                <div className="area-selection-items-container">
                    <div className="area-selection-item">
                        <VisitAquaCultureInput
                            label="Fiskafjöldi í meðferð"
                            name="animalCount"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                        />
                    </div>
                    <div className="area-selection-item">
                        <VisitAquaCultureInput
                            label="Rúmmetrar í meðferð"
                            name="areaSize"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                        />
                    </div>
                    <div className="area-selection-item">
                        <VisitAquaCultureInput
                            label="Meðalþyngd fiska (kg)"
                            name="biomass"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                        />
                    </div>
                    <div className="area-selection-item">
                        <VisitAquaCultureInput
                            label={
                                visitValues.establishmentType === "Sjókvíaeldi"
                                    ? "Sjóhitastig"
                                    : "Vatnshitastig"
                            }
                            name="temperature"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                        />
                    </div>
                </div>
            </div>
        </Paper>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitAreaSelection.propTypes = {
    /**
     * Aqua culture visit object with diagnosesList and treatment object
     */
    visitValues: visitType,
    /**
     * Aqua culture visit details object
     */
    values: aquaCultureDiagnoses,
    /**
     * Function that handles change when user changes species
     */
    handleChange: funcType,
    errors: errorsType
};

export default VisitAreaSelection;
