import { useState, useEffect } from "react";
import porcineService from "../services/porcineService";

const useGetAllPorcines = () => {
    const [porcines, setPorcines] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        porcineService
            .getAllPorcines()
            .then((porcines) => {
                porcines.map(
                    (porcines) =>
                        (porcines.searchKey =
                            porcines.companyIsName +
                            " " +
                            porcines.registrationCode)
                );
                setPorcines(porcines);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { porcines, isLoading, error };
};
export default useGetAllPorcines;
