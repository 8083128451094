import { useState, useEffect } from "react";
import unitTypeService from "../services/unitTypeService";

const useGetAllUnitTypes = () => {
    const [unitTypes, setUnitTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        unitTypeService
            .getAllUnitTypes()
            .then((unitTypes) => {
                setUnitTypes(unitTypes);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { unitTypes, isLoading, error };
};

export default useGetAllUnitTypes;
