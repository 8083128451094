import React, { useState } from "react";
import CreateIcon from "@material-ui/icons/Create";
import "./VisitPoultryUpdateDiagnosesListItem.css";
import useWindowSize from "../../../../hooks/useWindowSize";
import UpdateVisitDiseaseModal from "../../../Visit/UpdateVisit/UpdateVisitDiseaseModal/UpdateVisitDiseaseModal";
import UpdateVisitDiagnosesMedicineList from "../../../Visit/UpdateVisit/UpdateVisitDiagnosesMedicineList/UpdateVisitDiagnosesMedicineList";
import UpdatePoultryInputModal from "../UpdatePoultryInputModal/UpdatePoultryInputModal";
import UpdateVisitPoultrySpeciesList from "../UpdateVisitPoultrySpeciesList/UpdateVisitPoultrySpeciesList";
import { poultryUpdateDiagnoses } from "../../../../types";
import useGetAllPoultryDiseases from "../../../../hooks/useGetAllPoultryDiseases";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category PoultryVisit
 * @component holds all the items that you can update in poultry visit.
 *            Main purpose is to handle clicks from user and open and close modals
 */

const VisitPoultryUpdateDiagnosesListItem = ({ diagnoses }) => {
    /**
     * Hook that gets user's current window size
     */
    const windowSize = useWindowSize();
    /**
     * Boolean which reads user's screen size and returns true if screen size
     * is less than or equal to 690px (standard screen size in 2021)
     */
    const isPhone = windowSize.width <= 690;
    /**
     * Gets all poultry diseases from database
     */
    const { poultryDiseases } = useGetAllPoultryDiseases();
    const [visitDiseaseModalIsOpen, setVisitDiseaseModalIsOpen] = useState(
        false
    );
    const [
        visitAnimalCountModalIsOpen,
        setVisitAnimalCountModalIsOpen
    ] = useState(false);
    const [
        visitPoultrySpeciesModalIsOpen,
        setVisitPoultrySpeciesModalIsOpen
    ] = useState(false);
    const [
        visitTraceabilityNumberModalIsOpen,
        setVisitTraceabilityNumberModalIsOpen
    ] = useState(false);
    return (
        <div className="user-visit-update-view-diagnoses-info">
            <div className="user-visit-update-view-diagnoses-site-info">
                <div
                    className="user-visit-update-text"
                    onClick={() => setVisitDiseaseModalIsOpen(true)}
                >
                    <b>Greining:</b> {diagnoses.diseaseIsName}
                    {!isPhone && (
                        <CreateIcon className="user-visit-update-update-icon" />
                    )}
                </div>
            </div>
            <div className="user-visit-update-view-diagnoses-area-info">
                <div
                    className="user-visit-update-text user-visit-update-area-text"
                    onClick={() => setVisitPoultrySpeciesModalIsOpen(true)}
                >
                    <b>Fuglategund:</b> {diagnoses.speciesIsName}
                    {!isPhone && (
                        <CreateIcon className="user-visit-update-update-icon" />
                    )}
                </div>
                <div
                    className="user-visit-update-text user-visit-update-area-text"
                    onClick={() => setVisitAnimalCountModalIsOpen(true)}
                >
                    <b>Fuglafjöldi:</b> {diagnoses.animalCount}
                    {!isPhone && (
                        <CreateIcon className="user-visit-update-update-icon" />
                    )}
                </div>
                <div
                    className="user-visit-update-text user-visit-update-area-text"
                    onClick={() => setVisitTraceabilityNumberModalIsOpen(true)}
                >
                    <b>Rekjanleikanúmer:</b> {diagnoses.traceabilityNumber}
                    {!isPhone && (
                        <CreateIcon className="user-visit-update-update-icon" />
                    )}
                </div>
            </div>
            <div className="user-visit-update-view-diagnoses-medicine-info">
                <UpdateVisitDiagnosesMedicineList
                    medicineList={diagnoses.medicineList}
                />
            </div>
            <UpdateVisitDiseaseModal
                open={visitDiseaseModalIsOpen}
                handleClose={() => setVisitDiseaseModalIsOpen(false)}
                diagnoses={diagnoses}
                unhandledDiseaseId={diagnoses.diseaseId}
                unhandledDiseaseIsName={diagnoses.diseaseIsName}
                unhandledDiseaseEnName={diagnoses.diseaseEnName}
                diseases={poultryDiseases}
            />
            <UpdatePoultryInputModal
                diagnoses={diagnoses}
                open={visitAnimalCountModalIsOpen}
                handleClose={() => setVisitAnimalCountModalIsOpen(false)}
                unhandledInput={diagnoses.animalCount}
                label="Fjöldi fugla"
                name="animalCount"
                type="number"
            />
            <UpdateVisitPoultrySpeciesList
                open={visitPoultrySpeciesModalIsOpen}
                handleClose={() => setVisitPoultrySpeciesModalIsOpen(false)}
                unhandledSpeciesId={diagnoses.speciesId}
                diagnoses={diagnoses}
            />
            <UpdatePoultryInputModal
                diagnoses={diagnoses}
                open={visitTraceabilityNumberModalIsOpen}
                handleClose={() => setVisitTraceabilityNumberModalIsOpen(false)}
                unhandledInput={diagnoses.traceabilityNumber}
                label="Rekjanleikanúmer"
                name="traceabilityNumber"
                type="text"
            />
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitPoultryUpdateDiagnosesListItem.propTypes = {
    /**
     * Poultry visit object with diagnosesList and treatment object
     */
    diagnoses: poultryUpdateDiagnoses
};

export default VisitPoultryUpdateDiagnosesListItem;
