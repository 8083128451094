import { useState, useEffect } from "react";
import userService from "../services/userService";
import { useDispatch } from "react-redux";
import { updateLoggedInUser } from "../redux/actions/userActions";

const useCheckLoginStatus = (children) => {
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        userService
            .checkLoginStatus()
            .then((data) => {
                dispatch(updateLoggedInUser(data));
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {});
    }, [error, dispatch, children]);

    return { error };
};
export default useCheckLoginStatus;
