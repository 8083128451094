/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import { SET_ICELANDIC, SET_ENGLISH } from "../types";

const initState = {
    isIcelandic: true
};

export default (state = initState, action) => {
    const { type } = action;

    switch (type) {
        case SET_ICELANDIC: {
            return { ...state, isIcelandic: true };
        }
        case SET_ENGLISH: {
            return { ...state, isIcelandic: false };
        }
        default:
            return state;
    }
};
