import React from "react";
import "./VisitSheepUpdateDiagnosesList.css";
import VisitSheepUpdateDiagnosesListItem from "../VisitSheepUpdateDiagnosesListItem/VisitSheepUpdateDiagnosesListItem";
import { numberType, sheepUpdateDiagnosesList } from "../../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category SheepVisit
 * @component maps through sheep diagnoses list and spreads it to list items
 */

const VisitSheepUpdateDiagnosesList = ({
    diagnosesList,
    establishmentId,
    animals
}) => {
    return (
        <div className="user-visit-update-view-diagnoses-list">
            {diagnosesList.map((diagnoses) => (
                <VisitSheepUpdateDiagnosesListItem
                    diagnoses={diagnoses}
                    establishmentId={establishmentId}
                    key={diagnoses.id}
                    animals={animals}
                    errors={{}}
                    animalType={"sauðfé"}
                />
            ))}
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitSheepUpdateDiagnosesList.propTypes = {
    /**
     * Sheep visit object with diagnosesList and treatment object
     */
    diagnosesList: sheepUpdateDiagnosesList,
    establishmentId: numberType.isRequired
};

export default VisitSheepUpdateDiagnosesList;
