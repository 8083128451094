export const getMonths = [
    {
        isName: "Jan",
        enName: "Jan"
    },
    {
        isName: "Feb",
        enName: "Feb"
    },
    {
        isName: "Mar",
        enName: "Mar"
    },
    {
        isName: "Apr",
        enName: "Apr"
    },
    {
        isName: "Maí",
        enName: "May"
    },
    {
        isName: "Jún",
        enName: "Jun"
    },
    {
        isName: "Júl",
        enName: "Jul"
    },
    {
        isName: "Ágú",
        enName: "Aug"
    },
    {
        isName: "Sep",
        enName: "Sep"
    },
    {
        isName: "Okt",
        enName: "Oct"
    },
    {
        isName: "Nóv",
        enName: "Nov"
    },
    {
        isName: "Des",
        enName: "Dec"
    }
];
