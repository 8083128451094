import { useState, useEffect } from "react";
import horseService from "../services/horseService";

const useGetAllHorseDiseases = () => {
    const [horseDiseases, setHorseDiseases] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        horseService
            .getAllHorseDiseases()
            .then((horseDiseases) => {
                setHorseDiseases(horseDiseases);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { horseDiseases, isLoading, error };
};
export default useGetAllHorseDiseases;
