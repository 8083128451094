import React from "react";
import "./VisitGoatUpdateDiagnosesList.css";
import VisitGoatUpdateDiagnosesListItem from "../VisitGoatUpdateDiagnosesListItem/VisitGoatUpdateDiagnosesListItem";
import { numberType, goatUpdateDiagnosesList } from "../../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category GoatVisit
 * @component maps through goat diagnoses list and spreads it to list items
 */

const VisitGoatUpdateDiagnosesList = ({
    diagnosesList,
    establishmentId,
    animals
}) => {
    return (
        <div className="user-visit-update-view-diagnoses-list">
            {diagnosesList.map((diagnoses) => (
                <VisitGoatUpdateDiagnosesListItem
                    diagnoses={diagnoses}
                    establishmentId={establishmentId}
                    key={diagnoses.id}
                    animals={animals}
                    errors={{}}
                    animalType={"geitur"}
                />
            ))}
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitGoatUpdateDiagnosesList.propTypes = {
    /**
     * Goat visit object with diagnosesList and treatment object
     */
    diagnosesList: goatUpdateDiagnosesList,
    establishmentId: numberType.isRequired
};

export default VisitGoatUpdateDiagnosesList;
