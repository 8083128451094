import { useState, useEffect } from "react";
import userService from "../services/userService";

const useGetVisitsByUserId = (userId) => {
    const [visits, setVisits] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        userService
            .getVisitsByUserId(userId)
            .then((visits) => {
                visits.forEach(visit => {
                    visit.searchKey = visit.establishmentName + " " + visit.animalType;
                });
                setVisits(visits);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, [userId]);

    return { visits, isLoading, error };
};
export default useGetVisitsByUserId;
