import React, { useState } from "react";
import SearchBar from "../../../SearchBar/SearchBar";
import useSearchBar from "../../../../hooks/useSearchBar";
import DiseaseSelectedItem from "../DiseaseSelectedItem/DiseaseSelectedItem";
import DiseaseSelectionTable from "../DiseaseSelectionTable/DiseaseSelectionTable";
import { Paper, Switch, FormControlLabel } from "@material-ui/core";
import "./DiseaseSelectionView.css";

const DiseaseSelectionView = ({
    visitDetailValues,
    diseases,
    errors,
    updateCallBack
}) => {
    const [diseaseSelected, setDiseaseSelected] = useState(null);
    const [showNotifiableDiseases, setShowNotifiableDiseases] = useState(false);

    const { searchResult, searchTerm, handleChange } = useSearchBar(
        diseases,
        "isName"
    );
    const diseasesToShow = searchResult
        .filter((x) => x.isNotifiable === showNotifiableDiseases)
        .slice(0, 4);
    const handleSelection = (e, disease) => {
        if (updateCallBack) {
            updateCallBack(disease);
        }
        visitDetailValues.disease.id = disease.id;
        visitDetailValues.disease.isName = disease.isName;
        visitDetailValues.disease.enName = disease.enName;
        setDiseaseSelected(disease.id);
    };
    const handleRemoveDiagnoses = () => {
        setDiseaseSelected(null);
        visitDetailValues.disease = {};
    };
    const handleNotifiableDiseasesChange = () => {
        setShowNotifiableDiseases(!showNotifiableDiseases);
    };
    return (
        <Paper className="disease-selection-view-container">
            <div className="diagnoses-selection-selected-container">
                <div className="disease-selection-selected-header">
                    <i>
                        <>
                            {!diseaseSelected ? (
                                <div>Vinsamlegast veljið greiningu ...</div>
                            ) : (
                                <div>Valin greining:</div>
                            )}
                        </>
                        <b className="disease-selection-header-error">
                            {!diseaseSelected && errors.disease}
                        </b>
                    </i>
                </div>
                {visitDetailValues.disease.id && (
                    <DiseaseSelectedItem
                        visitDetailValues={visitDetailValues}
                        handleRemoveDiagnoses={handleRemoveDiagnoses}
                    />
                )}
            </div>
            <div className="disease-selection-view-switch-button-container">
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            checked={showNotifiableDiseases}
                            onChange={() => handleNotifiableDiseasesChange()}
                        />
                    }
                    label="Sýna tilkynningaskylda sjúkdóma"
                />
            </div>
            <div className="disease-selection-view-searchbar">
                <SearchBar
                    searchTerm={searchTerm}
                    handleChange={handleChange}
                    placeHolder="Leita að greiningu"
                />
            </div>
            <div>
                <DiseaseSelectionTable
                    items={diseasesToShow}
                    handleSelection={handleSelection}
                />
            </div>
        </Paper>
    );
};

export default DiseaseSelectionView;
