import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import "./VisitHorseSelectionListItem.css";

const VisitHorseSelectionListItem = ({ horse }) => {
    return (
        <TableRow className="visit-horse-table-row">
            <TableCell>{horse.nafn ? horse.nafn : "Nafn vantar"}</TableCell>
            <TableCell>{horse.ormerki}</TableCell>
        </TableRow>
    );
};

export default VisitHorseSelectionListItem;
