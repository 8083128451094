import React from "react";
import EstablishmentsSelectionList from "../EstablishmentsSelectionList/EstablishmentsSelectionList";
import useSearchBar from "../../../../hooks/useSearchBar";
import SearchBar from "../../../SearchBar/SearchBar";
import "./EstablishmentsSelectionView.css";

const EstablishmentsSelectionView = ({
    establishments,
    values,
    handleSubmit,
    errors,
    hasType
}) => {
    const handleEstablishmentSelection = (e, establishment) => {
        values.establishmentId = establishment.registrationId;
        values.establishmentName = establishment.companyIsName;
        values.establishmentSSN = establishment.companySSN;
        values.registrationCode = establishment.registrationCode;
        values.establishmentAreas = establishment.sites;
        values.establishmentType = establishment.typeName;
        handleSubmit(e);
    };

    const { searchResult, handleChange, searchTerm } = useSearchBar(
        establishments,
        "searchKey"
    );
    return (
        <div className="establishments-selection-container">
            <div className="establishments-selection-searchbar">
                <SearchBar
                    searchTerm={searchTerm}
                    handleChange={handleChange}
                    placeHolder="Leita eftir nafni"
                />
            </div>
            <div className="establishments-selection-list-container">
                {errors.establishmentId !== undefined && (
                    <span className="establishment-error">
                        Velja þarf fyrirtæki
                    </span>
                )}
                <EstablishmentsSelectionList
                    establishments={searchResult}
                    handleEstablishmentSelection={handleEstablishmentSelection}
                    errors={errors}
                    hasType={hasType}
                />
            </div>
        </div>
    );
};

export default EstablishmentsSelectionView;
