import { useState, useEffect } from "react";
import visitService from "../services/visitService";

const useGetAllArrangements = () => {
    const [arrangements, setArrangements] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        visitService
            .getAllArrangements()
            .then((arrangements) => {
                setArrangements(arrangements);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { arrangements, isLoading, error };
};
export default useGetAllArrangements;
