import React from "react";
import AdminMedicineSelectionListItem from "../AdminMedicineSelectionListItem/AdminMedicineSelectionListItem";
import "./AdminMedicineSelectionList.css"

const AdminMedicineSelectionList = ({ medicines, handleSelection }) => {
    return (
        <div className="admin-medicine-selection-list-container">
            {medicines.map((med, i) => (
                <AdminMedicineSelectionListItem
                    medicine={med}
                    handleSelection={handleSelection}
                    key={i}
                />
            ))}
        </div>
    );
};
export default AdminMedicineSelectionList;
