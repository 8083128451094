import React from "react";
import "./VisitFurAnimalsUpdateDiagnosesList.css";
import VisitFurAnimalsUpdateDiagnosesListItem from "../VisitFurAnimalsUpdateDiagnosesListItem/VisitFurAnimalsUpdateDiagnosesListItem";
import { numberType, furAnimalsUpdateDiagnosesList } from "../../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category FurAnimalsVisit
 * @component maps through furAnimals diagnoses list and spreads it to list items
 */

const VisitFurAnimalsUpdateDiagnosesList = ({
    diagnosesList,
    establishmentId
}) => {
    return (
        <div className="user-visit-update-view-diagnoses-list">
            {diagnosesList.map((diagnoses) => (
                <VisitFurAnimalsUpdateDiagnosesListItem
                    diagnoses={diagnoses}
                    establishmentId={establishmentId}
                    key={diagnoses.id}
                />
            ))}
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitFurAnimalsUpdateDiagnosesList.propTypes = {
    /**
     * FurAnimals visit object with diagnosesList and treatment object
     */
    diagnosesList: furAnimalsUpdateDiagnosesList,
    establishmentId: numberType.isRequired
};

export default VisitFurAnimalsUpdateDiagnosesList;
