import { useState, useEffect } from "react";
import medicineService from "../services/medicineService";

const useGetAllMedicines = () => {
    const [medicines, setMedicines] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        medicineService
            .getAllMedicines()
            .then((medicines) => {
                setMedicines(medicines);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { medicines, isLoading, error };
};

export default useGetAllMedicines;
