import React, { useState } from "react";
import { Modal, Paper } from "@material-ui/core";
import "./UpdatePoultryInputModal.css";
import UpdateVisitButtons from "../../../Visit/UpdateVisit/UpdateVisitButtons/UpdateVisitButtons";
import VisitPoultryInput from "../../VisitPoultryInput/VisitPoultryInput";
import {
    boolType,
    funcType,
    stringType,
    unhandledInputType,
    poultryUpdateDiagnoses
} from "../../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category PoultryVisit
 * @component is a general component for each input that user can change
 *            in update poultry visit
 */

const UpdatePoultryInputModal = ({
    diagnoses,
    open,
    handleClose,
    unhandledInput,
    label,
    name,
    type
}) => {
    /**
     * React useState hook to reload component on change
     */
    const [reload, setReload] = useState(false);
    /**
     * Handle cancel function, resets input if user cancels change
     */
    const handleCancel = () => {
        diagnoses[name] = unhandledInput;
        handleClose();
    };
    /**
     * Handle change function and reloads component
     */
    const handleChange = (e) => {
        diagnoses[e.target.name] = e.target.value;
        setReload(!reload);
    };
    const errors = {
        animalCount: undefined,
        traceabilityNumber: undefined,
        speciesId: undefined
    };
    return (
        <Modal
            className="update-visit-input-modal"
            open={open}
            onClose={handleClose}
        >
            <Paper className="update-visit-input-paper">
                <VisitPoultryInput
                    label={label}
                    type={type}
                    name={name}
                    handleChange={handleChange}
                    values={diagnoses}
                    errors={errors}
                />
                <UpdateVisitButtons
                    handleClose={handleClose}
                    handleCancel={handleCancel}
                />
            </Paper>
        </Modal>
    );
};

/**
 * This React app has generalized Proptypes
 */
UpdatePoultryInputModal.propTypes = {
    /**
     * Poultry visit object with diagnosesList and treatment object
     */
    diagnoses: poultryUpdateDiagnoses,
    /**
     * Boolean keeps track of is modal is open
     */
    open: boolType.isRequired,
    handleClose: funcType,
    /**
     * Saved unhandled input variable for when user cancels change
     */
    unhandledInput: unhandledInputType,
    /**
     * Label prop for reusability
     */
    label: stringType.isRequired,
    /**
     * Name prop for reusability
     */
    name: stringType.isRequired,
    /**
     * Type prop for reusability
     */
    type: stringType.isRequired
};

export default UpdatePoultryInputModal;
