/* Centralized PropTypes Declaration */
import PropTypes from "prop-types";

const {
    func,
    bool,
    string,
    number,
    shape,
    arrayOf,
    oneOfType,
    array,
    object
} = PropTypes;

export const poultrySpecies = shape({
    id: number,
    isName: string
});
export const poultrySpeciesList = arrayOf(poultrySpecies);

export const aquaCultureSpecies = shape({
    id: number,
    isName: string,
    enName: string
});
export const aquaCultureSpeciesList = arrayOf(aquaCultureSpecies);

export const disease = shape({
    id: number,
    isName: string,
    enName: string
});

export const treatment = shape({
    id: number,
    medicineId: number,
    medicineAmount: oneOfType([number, string]),
    medicineName: string,
    unitType: string
});

export const poultryUpdateDiagnoses = shape({
    id: number,
    visitId: number,
    diseaseId: number,
    diseaseIsName: string,
    diseaseEnName: string,
    disease: disease,
    animalCount: oneOfType([number, string]),
    speciesId: number,
    speciesIsName: string,
    medicineList: arrayOf(treatment),
    slaughterBanUntil: string,
    traceabilityNumber: string,
    veterinarianSSN: string
});

export const porcineUpdateDiagnoses = shape({
    id: number,
    visitId: number,
    diseaseId: number,
    diseaseIsName: string,
    diseaseEnName: string,
    disease: disease,
    medicineList: arrayOf(treatment),
    slaughterBanUntil: string,
    veterinarianSSN: string
});

export const furAnimalsUpdateDiagnoses = shape({
    id: number,
    visitId: number,
    diseaseId: number,
    diseaseIsName: string,
    diseaseEnName: string,
    disease: disease,
    medicineList: arrayOf(treatment),
    slaughterBanUntil: string,
    veterinarianSSN: string
});

export const porcineDiagnoses = shape({
    disease: disease,
    medicineList: array,
    medicineTempList: array,
    veterinarianSSN: string
});

export const furAnimalUpdateDiagnoses = shape({
    disease: disease,
    medicineList: array,
    medicineTempList: array,
    veterinarianSSN: string
});

export const poultryDiagnoses = shape({
    animalCount: oneOfType([number, string]),
    disease: disease,
    medicineList: array,
    medicineTempList: array,
    speciesId: oneOfType([number, string]),
    poultrySpeciesList: poultrySpeciesList,
    poultryType: string,
    traceabilityNumber: string,
    veterinarianSSN: string
});

export const aquaCultureDiagnoses = shape({
    animalCount: oneOfType([number, string]),
    areaSize: oneOfType([number, string]),
    biomass: oneOfType([number, string]),
    temperature: oneOfType([number, string]),
    disease: disease,
    medicineList: array,
    medicineTempList: array,
    siteId: oneOfType([number, string]),
    siteName: string,
    speciesEnName: string,
    speciesId: oneOfType([number, string]),
    speciesIsName: string,
    speciesList: array,
    veterinarianSSN: string
});

export const aquaCultureUpdateDiagnoses = shape({
    id: number,
    visitId: number,
    animalCount: oneOfType([number, string]),
    areaSize: oneOfType([number, string]),
    biomass: oneOfType([number, string]),
    temperature: oneOfType([number, string]),
    disease: disease,
    diseaseId: oneOfType([number, string]),
    diseaseIsName: string,
    diseaseEnName: string,
    medicineList: array,
    medicineTempList: array,
    siteId: oneOfType([number, string]),
    siteName: string,
    speciesEnName: string,
    speciesId: oneOfType([number, string]),
    speciesIsName: string,
    speciesList: array,
    veterinarianSSN: string,
    slaughterBanUntil: string
});

export const sheepUpdateDiagnoses = shape({
    id: number,
    visitId: number,
    diseaseId: number,
    diseaseIsName: string,
    diseaseEnName: string,
    disease: disease,
    medicineList: arrayOf(treatment),
    veterinarianSSN: string,
    animalIdList: array,
    animalList: array,
    slaughterBanDate: string,
    milkBanDate: string,
    medicineTempList: array
});

export const cattleUpdateDiagnoses = shape({
    id: number,
    visitId: number,
    diseaseId: number,
    diseaseIsName: string,
    diseaseEnName: string,
    disease: disease,
    medicineList: arrayOf(treatment),
    veterinarianSSN: string,
    animalIdList: array,
    animalList: array,
    slaughterBanDate: string,
    milkBanDate: string,
    medicineTempList: array
});

export const goatUpdateDiagnoses = shape({
    id: number,
    visitId: number,
    diseaseId: number,
    diseaseIsName: string,
    diseaseEnName: string,
    disease: disease,
    medicineList: arrayOf(treatment),
    veterinarianSSN: string,
    animalIdList: array,
    animalList: array,
    slaughterBanDate: string,
    milkBanDate: string,
    medicineTempList: array
});

export const horseUpdateDiagnoses = shape({
    id: number,
    visitId: number,
    diseaseId: number,
    diseaseIsName: string,
    diseaseEnName: string,
    disease: disease,
    medicineList: arrayOf(treatment),
    veterinarianSSN: string,
    animalIdList: array,
    animalList: array,
    slaughterBanDate: string,
    milkBanDate: string,
    medicineTempList: array
});

export const poultryUpdateDiagnosesList = arrayOf(poultryUpdateDiagnoses);
export const porcineUpdateDiagnosesList = arrayOf(porcineUpdateDiagnoses);
export const sheepUpdateDiagnosesList = arrayOf(sheepUpdateDiagnoses);
export const cattleUpdateDiagnosesList = arrayOf(cattleUpdateDiagnoses);
export const goatUpdateDiagnosesList = arrayOf(goatUpdateDiagnoses);
export const furAnimalsUpdateDiagnosesList = arrayOf(furAnimalUpdateDiagnoses);
export const horseUpdateDiagnosesList = arrayOf(horseUpdateDiagnoses);

export const visitType = shape({
    id: number,
    animalTypeId: number,
    animalIsName: string,
    animalEnName: string,
    arrangementTypeId: string,
    arrangementIsName: string,
    arrangementEnName: string,
    establishmentId: number,
    establishmentName: string,
    establishmentSSN: string,
    veterinarianSSN: string,
    visitDate: string,
    diagnosesList: poultryUpdateDiagnosesList
});

export const numberType = number;
export const funcType = func;
export const boolType = bool;
export const stringType = string;
export const unhandledInputType = oneOfType([stringType, numberType]);
export const errorsType = object;
