import React from "react";
import "./VisitHorseSelectionView.css";
import VisitHorseSelectionList from "../VisitHorseSelectionList/VisitHorseSelectionList";
import SearchBar from "../../SearchBar/SearchBar";

const VisitHorseSelectionView = ({
    searchTerm,
    handleSearchBarChange,
    horses,
    errors
}) => {
    return (
        <div className="visit-horse-selection-view">
            <div className="visit-horse-selection-searchbar">
                <SearchBar
                    searchTerm={searchTerm}
                    handleChange={handleSearchBarChange}
                    placeHolder="Leita eftir örmerki"
                />
            </div>
            <div className="visit-horse-selection-list-container">
                <span className="horse-selection-list-container-header">
                    Valdir gripir ({horses.length})
                </span>
                <VisitHorseSelectionList
                    horses={horses}
                    errors={errors}
                    searchTerm={searchTerm}
                />
            </div>
        </div>
    );
};

export default VisitHorseSelectionView;
