import React from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import "./AddMedicineButton.css";

const AddMedicineButton = ({ handleClick, text }) => {
    return (
        <Button
            className="add-medicine-button"
            variant="contained"
            size="large"
            onClick={handleClick}
        >
            <AddIcon className="add-medicine-icon" />
            <b>{text}</b>
        </Button>
    );
};

export default AddMedicineButton;
