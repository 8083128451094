import { useState, useEffect } from "react";
import poultryService from "../services/poultryService";

const useGetAllPoultrySpecies = () => {
    const [poultrySpecies, setPoultrySpecies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        poultryService
            .getAllPoultrySpecies()
            .then((ps) => {
                setPoultrySpecies(ps);
                setError(null);
            })
            .catch((error) => setError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { poultrySpecies, isLoading, error };
};
export default useGetAllPoultrySpecies;
