import React from "react";
import "./VisitHorseUpdateDiagnosesList.css";
import VisitHorseUpdateDiagnosesListItem from "../VisitHorseUpdateDiagnosesListItem/VisitHorseUpdateDiagnosesListItem";
import { numberType, horseUpdateDiagnosesList } from "../../../../types";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category HorseVisit
 * @component maps through horse diagnoses list and spreads it to list items
 */

const VisitHorseUpdateDiagnosesList = ({ diagnosesList, establishmentId }) => {
    return (
        <div className="user-visit-update-view-diagnoses-list">
            {diagnosesList.map((diagnoses) => (
                <VisitHorseUpdateDiagnosesListItem
                    diagnoses={diagnoses}
                    establishmentId={establishmentId}
                    key={diagnoses.id}
                />
            ))}
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitHorseUpdateDiagnosesList.propTypes = {
    /**
     * Horse visit object with diagnosesList and treatment object
     */
    diagnosesList: horseUpdateDiagnosesList,
    establishmentId: numberType.isRequired
};

export default VisitHorseUpdateDiagnosesList;
