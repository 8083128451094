import React, { useState } from "react";
import CreateIcon from "@material-ui/icons/Create";
import "./VisitAquaCultureUpdateDiagnosesListItem.css";
import useWindowSize from "../../../../hooks/useWindowSize";
import UpdateAquaCultureInputModal from "../UpdateAquaCultureInputModal/UpdateAquaCultureInputModal";
import UpdateVisitAquaCultureSiteRadio from "../UpdateVisitAquaCultureSiteRadio/UpdateVisitAquaCultureSiteRadio";
import UpdateVisitAquaCultureSpeciesList from "../UpdateVisitAquaCultureSpeciesList/UpdateVisitAquaCultureSpeciesList";
import UpdateVisitDiseaseModal from "../../../Visit/UpdateVisit/UpdateVisitDiseaseModal/UpdateVisitDiseaseModal";
import UpdateVisitDiagnosesMedicineList from "../../../Visit/UpdateVisit/UpdateVisitDiagnosesMedicineList/UpdateVisitDiagnosesMedicineList";
import { aquaCultureDiagnoses } from "../../../../types";
import useGetAllAquaCultureDiseases from "../../../../hooks/useGetAllAquaCultureDiseases";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category AquaCultureVisit
 * @component holds all the items that you can update in aqua culture visit.
 *            Main purpose is to handle clicks from user and open and close modals
 */

const VisitAquaCultureUpdateDiagnosesListItem = ({
    diagnoses,
    establishmentId
}) => {
    /**
     * Hook that gets user's current window size
     */
    const windowSize = useWindowSize();
    /**
     * Boolean which reads user's screen size and returns true if screen size
     * is less than or equal to 690px (standard screen size in 2021)
     */
    const isPhone = windowSize.width <= 690;
    /**
     * React useState hooks, which handle open and close modal
     */
    /**
     * Gets all aqua culture diseases from database
     */
    const { aquaCultureDiseases } = useGetAllAquaCultureDiseases();
    const [visitFishCountModalIsOpen, setVisitFishCountModalIsOpen] = useState(
        false
    );
    const [visitAreaSizeModalIsOpen, setVisitAreaSizeModalIsOpen] = useState(
        false
    );
    const [
        visitTemperatureModalIsOpen,
        setVisitTemperatureModalIsOpen
    ] = useState(false);
    const [visitBiomassModalIsOpen, setVisitBiomassModalIsOpen] = useState(
        false
    );
    const [visitSiteModalIsOpen, setVisitSiteModalIsOpen] = useState(false);
    const [visitSpeciesModalIsOpen, setVisitSpeciesModalIsOpen] = useState(
        false
    );
    const [visitDiseaseModalIsOpen, setVisitDiseaseModalIsOpen] = useState(
        false
    );
    return (
        <div className="user-visit-update-view-diagnoses-info">
            <div className="user-visit-update-view-diagnoses-site-info">
                <div
                    className="user-visit-update-text"
                    onClick={() => setVisitSiteModalIsOpen(true)}
                >
                    <b>Svæði:</b> {diagnoses.siteName}
                    {!isPhone && (
                        <CreateIcon className="user-visit-update-update-icon" />
                    )}
                </div>
                <div
                    className="user-visit-update-text user-visit-update-species-phone"
                    onClick={() => setVisitSpeciesModalIsOpen(true)}
                >
                    <b className="user-visit-update-species-phone-text">
                        Fisktegund:
                    </b>{" "}
                    {diagnoses.speciesIsName}
                    {!isPhone && (
                        <CreateIcon className="user-visit-update-update-icon" />
                    )}
                </div>
                <div
                    className="user-visit-update-text"
                    onClick={() => setVisitDiseaseModalIsOpen(true)}
                >
                    <b>Greining:</b> {diagnoses.diseaseIsName}
                    {!isPhone && (
                        <CreateIcon className="user-visit-update-update-icon" />
                    )}
                </div>
            </div>
            <div className="user-visit-update-view-diagnoses-area-info">
                <div
                    className="user-visit-update-text user-visit-update-area-text"
                    onClick={() => setVisitFishCountModalIsOpen(true)}
                >
                    <b>Fiskafjöldi:</b> {diagnoses.animalCount}
                    {!isPhone && (
                        <CreateIcon className="user-visit-update-update-icon" />
                    )}
                </div>
                <div
                    className="user-visit-update-text user-visit-update-area-text"
                    onClick={() => setVisitAreaSizeModalIsOpen(true)}
                >
                    <b>Rúmmetrar:</b> {diagnoses.areaSize}
                    {!isPhone && (
                        <CreateIcon className="user-visit-update-update-icon" />
                    )}
                </div>
                <div
                    className="user-visit-update-text user-visit-update-area-text"
                    onClick={() => setVisitBiomassModalIsOpen(true)}
                >
                    <b>Meðalþyngd:</b> {diagnoses.biomass}
                    {!isPhone && (
                        <CreateIcon className="user-visit-update-update-icon" />
                    )}
                </div>
                <div
                    className="user-visit-update-text user-visit-update-area-text"
                    onClick={() => setVisitTemperatureModalIsOpen(true)}
                >
                    <b>Sjóhitastig:</b> {diagnoses.temperature}
                    {!isPhone && (
                        <CreateIcon className="user-visit-update-update-icon" />
                    )}
                </div>
            </div>
            <div className="user-visit-update-view-diagnoses-medicine-info">
                <UpdateVisitDiagnosesMedicineList
                    medicineList={diagnoses.medicineList}
                />
            </div>
            <UpdateVisitAquaCultureSiteRadio
                open={visitSiteModalIsOpen}
                establishmentId={establishmentId}
                handleClose={() => setVisitSiteModalIsOpen(false)}
                unhandledSiteId={diagnoses.siteId}
                visit={diagnoses}
            />
            <UpdateVisitAquaCultureSpeciesList
                open={visitSpeciesModalIsOpen}
                handleClose={() => setVisitSpeciesModalIsOpen(false)}
                visit={diagnoses}
                unhandledSpeciesId={diagnoses.speciesId}
            />
            <UpdateVisitDiseaseModal
                open={visitDiseaseModalIsOpen}
                handleClose={() => setVisitDiseaseModalIsOpen(false)}
                diagnoses={diagnoses}
                unhandledDiseaseId={diagnoses.diseaseId}
                unhandledDiseaseIsName={diagnoses.diseaseIsName}
                unhandledDiseaseEnName={diagnoses.diseaseEnName}
                diseases={aquaCultureDiseases}
            />
            <UpdateAquaCultureInputModal
                open={visitFishCountModalIsOpen}
                handleClose={() => setVisitFishCountModalIsOpen(false)}
                unhandledInput={diagnoses.animalCount}
                visit={diagnoses}
                label="Breyta fiskafjölda"
                name="animalCount"
            />
            <UpdateAquaCultureInputModal
                open={visitAreaSizeModalIsOpen}
                handleClose={() => setVisitAreaSizeModalIsOpen(false)}
                unhandledInput={diagnoses.areaSize}
                visit={diagnoses}
                label="Breyta rúmmetrum"
                name="areaSize"
            />
            <UpdateAquaCultureInputModal
                open={visitBiomassModalIsOpen}
                handleClose={() => setVisitBiomassModalIsOpen(false)}
                unhandledInput={diagnoses.biomass}
                visit={diagnoses}
                label="Breyta meðalþyngd"
                name="biomass"
            />
            <UpdateAquaCultureInputModal
                open={visitTemperatureModalIsOpen}
                handleClose={() => setVisitTemperatureModalIsOpen(false)}
                unhandledInput={diagnoses.temperature}
                visit={diagnoses}
                label="Breyta sjóhitastigi"
                name="temperature"
            />
        </div>
    );
};

/**
 * This React app has generalized Proptypes
 */
VisitAquaCultureUpdateDiagnosesListItem.propTypes = {
    /**
     * Poultry visit object with diagnosesList and treatment object
     */
    diagnoses: aquaCultureDiagnoses
};

export default VisitAquaCultureUpdateDiagnosesListItem;
