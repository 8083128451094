import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import "./DiseaseSelectionTableItem.css";

const DiseaseSelectionTableItem = ({ item, handleSelection }) => {
    return (
        <TableRow
            className="selection-table-row"
            onClick={(e) => handleSelection(e, item)}
        >
            <TableCell className="selection-table-item">
                <i>{item.isName}</i>
            </TableCell>
        </TableRow>
    );
};

export default DiseaseSelectionTableItem;
