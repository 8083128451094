import React from "react";
import "./MilkBanSelection.css";
import AnimalBanCalendar from "../../AnimalBanCalendar/AnimalBanCalendar";

const MilkBanSelection = ({
    error,
    errorMessage,
    dateGiven,
    visitDetailValues,
    getDateDiffCallBack
}) => {
    return (
        <div className="milkban-container">
            <div className="milkban-calendar">
                <AnimalBanCalendar
                    visitDetailValues={visitDetailValues}
                    label="Mjólkurbann"
                    valueName="milkBanDate"
                    daysType="milkBanDays"
                    disabled={false}
                    error={error}
                    errorMessage={errorMessage}
                    dateGiven={dateGiven}
                    daysTitle="Mjólkurbann"
                    getDateDiffCallBack={getDateDiffCallBack}
                />
            </div>
        </div>
    );
};

export default MilkBanSelection;
