import React, { useState } from "react";
import useGetAllMedicines from "../../../../hooks/useGetAllMedicines";
import useSearchBar from "../../../../hooks/useSearchBar";
import SearchBar from "../../../SearchBar/SearchBar";
import "./MedicineSelectionView.css";
import MedicineSelectionList from "../MedicineSelectionList/MedicineSelectionList";

const MedicineSelectionView = ({ visitDetailValues, selectedCallBack }) => {
    const { medicines } = useGetAllMedicines();
    const [alreadySelected, setAlreadySelected] = useState(false);
    const { searchResult, searchTerm, handleChange } = useSearchBar(
        medicines,
        "name"
    );
    const medicinesToShow = searchResult.slice(0, 4);
    const handleSelection = (e, medicine) => {
        if (visitDetailValues.medicineList.some((e) => e.id === medicine.id)) {
            setAlreadySelected(true);
        } else {
            visitDetailValues.medicineTempList.push(medicine);
            setAlreadySelected(false);
            selectedCallBack(medicine);
        }
    };
    return (
        <div className="medicine-selection-view-container">
            <div className="medicine-selection-view-searchbar">
                <SearchBar
                    searchTerm={searchTerm}
                    handleChange={handleChange}
                    placeHolder="Leita að lyfi"
                />
            </div>
            <div>
                <MedicineSelectionList
                    medicines={medicinesToShow}
                    handleSelection={handleSelection}
                />
            </div>
            {alreadySelected && (
                <span className="error">
                    <b>Lyf er nú þegar valið.</b>
                </span>
            )}
        </div>
    );
};

export default MedicineSelectionView;
