const VisitPetValidation = (values) => {
    const { animalCount, medicineList, speciesId } = values;
    const errors = {};

    if (animalCount < 1) {
        errors.animalCount = "Velja þarf fjölda dýra";
    }
    if (speciesId === "") {
        errors.speciesId = "Velja þarf dýrategund";
    }
    if (medicineList.length < 1) {
        errors.medicineList = "Velja þarf lyf";
    }

    return errors;
};

export default VisitPetValidation;
