import React from "react";
import { Route, Redirect } from "react-router-dom";
import useCheckLoginStatus from "../../hooks/useCheckLoginStatus";
import { useSelector } from "react-redux";
import NotAdminView from "../AdminManagement/NotAdminView/NotAdminView";

const AdminRoute = ({ children, ...rest }) => {
    const user = useSelector((state) => state.user);
    const { error } = useCheckLoginStatus(children);

    return error ? (
        <Redirect to="/login" />
    ) : user.isAdmin ? (
        <Route
            {...rest}
            render={() => {
                return user.loggedIn && children;
            }}
        />
    ) : (
        <NotAdminView />
    );
};
export default AdminRoute;
