/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/furanimalvisits`;
const apiFurAnimalLicensesEndPoint = `${endpoint}/api/licenses/furAnimals`;

const getAllFurAnimals = () => {
    return fetch(apiFurAnimalLicensesEndPoint, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const getAllFurAnimalDiseases = () => {
    return fetch(apiEndpoint + "/diseases", {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const createFurAnimalVisit = (furAnimalVisit) => {
    return fetch(apiEndpoint, {
        method: "POST",
        body: JSON.stringify(furAnimalVisit),
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

const updateFurAnimalVisit = (furAnimalVisit) => {
    return fetch(apiEndpoint, {
        method: "PUT",
        body: JSON.stringify(furAnimalVisit),
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(handleData)
        .catch((error) => Promise.reject(error));
};

export default {
    getAllFurAnimals,
    getAllFurAnimalDiseases,
    createFurAnimalVisit,
    updateFurAnimalVisit
};
