import { useState, useEffect } from "react";
import poultryService from "../services/poultryService";

const useUpdatePoultryVisit = () => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [poultryVisits, setPoultryVisits] = useState(null);

    useEffect(() => {
        if (poultryVisits && !isProcessing) {
            setProcessing(true);
            poultryService
                .updatePoultryVisit(poultryVisits)
                .then(() => {
                    setError(null);
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setPoultryVisits(null);
                    setProcessing(false);
                });
        }
    }, [isProcessing, poultryVisits]);

    const handleUpdatePoultryVisit = (incomingVisit) => {
        if (!isProcessing) {
            const outgoingVisit = {
                id: incomingVisit.id,
                arrangementTypeId: parseInt(incomingVisit.arrangementTypeId),
                establishmentId: incomingVisit.establishmentId,
                establishmentName: incomingVisit.establishmentName,
                establishmentSSN: incomingVisit.establishmentSSN,
                animalTypeId: incomingVisit.animalTypeId,
                veterinarianId: incomingVisit.veterinarianId,
                veterinarianSSN: incomingVisit.veterinarianSSN,
                visitDate: incomingVisit.visitDate,
                poultryVisits: preparePoultryVisits(incomingVisit)
            };
            setPoultryVisits(outgoingVisit);
        }
    };

    const preparePoultryVisits = (incomingPoultryVisits) => {
        var vpList = [];
        incomingPoultryVisits.diagnosesList.forEach((poultry) => {
            const visitPoultryInputModelList = {
                id: poultry.id,
                visitId: poultry.visitId,
                diseaseId: poultry.diseaseId,
                traceabilityNumber: poultry.traceabilityNumber,
                speciesId: poultry.speciesId,
                animalCount: parseFloat(poultry.animalCount),
                veterinarianSSN: poultry.veterinarianSSN,
                medicines: prepareMedicines(poultry.medicineList)
            };
            vpList.push(visitPoultryInputModelList);
        });
        return vpList;
    };

    const prepareMedicines = (incomingMed) => {
        let medList = [];
        for (let i = 0; i < incomingMed.length; i++) {
            medList.push({
                id: incomingMed[i].id,
                medicineId: incomingMed[i].medicineId,
                medicineAmount: parseFloat(incomingMed[i].medicineAmount)
            });
        }
        return medList;
    };
    return { error, handleUpdatePoultryVisit, isProcessing };
};
export default useUpdatePoultryVisit;
