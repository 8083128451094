import React from "react";
import "./NotAdminView.css";

const NotAdminView = () => {
    return (
        <div className="not-admin-view-text">
            Þú ert ekki skráður Kraftnotandi, ef þú telur þig vera Kraftnotanda
            endilega hafðu samband við Árna, ekki Sævald
        </div>
    );
};

export default NotAdminView;
