import React from "react";
import { Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import "./AddMedicineButton.css";

const AddMedicineButton = ({ handleSubmit }) => {
    return (
        <Button
            className="medicine-confirm-button"
            onClick={handleSubmit}
        >
            <CheckIcon className="medicine-confirm-icon" />
            <b>Staðfesta</b>
        </Button>
    );
};

export default AddMedicineButton;
