import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import SideBarContainer from "./components/SideBar/SideBarContainer/SideBarContainer";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import VisitView from "./components/Visit/VisitView/VisitView";
import VisitAquaCultureView from "./components/VisitAquaCulture/VisitAquaCultureView/VisitAquaCultureView";
import AdminManagementView from "./components/AdminManagement/AdminManagementView/AdminManagementView";
import AddMedicine from "./components/AdminManagement/AdminInput/AdminMedicine/AddMedicine/AddMedicine";
import AdminMedicineView from "./components/AdminManagement/AdminInput/AdminMedicine/AdminMedicineView/AdminMedicineView";

import LoginLandingPage from "./components/Login/LoginLandingPage/LoginLandingPage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import AdminRoute from "./components/AdminRoute/AdminRoute";
import AdminCreateUserView from "./components/AdminManagement/AdminCreateUser/AdminCreateUserView/AdminCreateUserView";
import UserVisitsView from "./components/UserVisits/UserVisitsView/UserVisitsView";
import UserVisitDetail from "./components/UserVisits/UserVisitDetail/UserVisitDetail";
import VisitPoultryView from "./components/VisitPoultry/VisitPoultryView/VisitPoultryView";
import VisitAquaCultureUpdateView from "./components/VisitAquaCulture/VisitAquaCultureUpdate/VisitAquaCultureUpdateView/VisitAquaCultureUpdateView";
import useWindowSize from "./hooks/useWindowSize";
import VisitPorcineView from "./components/VisitPorcine/VisitPorcineView/VisitPorcineView";
import VisitPetView from "./components/VisitPet/VisitPetView/VisitPetView";
import VisitCattleView from "./components/VisitCattle/VisitCattleView/VisitCattleView";
import VisitSheepView from "./components/VisitSheep/VisitSheepView/VisitSheepView";
import VisitGoatView from "./components/VisitGoat/VisitGoatView/VisitGoatView";
import VisitFurAnimalsView from "./components/VisitFurAnimals/VisitFurAnimalsView/VisitFurAnimalsView";
import VisitHorseView from "./components/VisitHorse/VisitHorseView/VisitHorseView";

function App() {
    const windowSize = useWindowSize();
    const isPhone = windowSize.width <= 690;
    document.title = "Heilsa";
    const [showSideBar, setShowSideBar] = useState(isPhone ? false : true);
    const toggleSideBar = () => {
        if (isPhone) {
            setShowSideBar(!showSideBar);
        }
    };

    const showSideBarWhenOnPhone = () => {
        if (isPhone) {
            if (showSideBar) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    };
    const showContentWhenOnPhone = () => {
        if (isPhone) {
            if (showSideBar) {
                return false;
            } else {
                return true;
            }
        }
        return true;
    };

    return (
        <div className="App">
            {isPhone && (
                <div className="sidebar-toggler" onClick={toggleSideBar}>
                    <FormatAlignJustifyIcon />
                </div>
            )}
            {showSideBarWhenOnPhone() && (
                <div className="sidebar">
                    <SideBarContainer toggleSideBar={toggleSideBar} />
                </div>
            )}
            {showContentWhenOnPhone() && (
                <div id="main" className="main sidebar-hide">
                    <Switch>
                        <Route
                            exact
                            path="/login"
                            component={LoginLandingPage}
                        />
                        <PrivateRoute exact path="/visit">
                            <VisitView />
                        </PrivateRoute>
                        <PrivateRoute exact path="/uservisits">
                            <UserVisitsView />
                        </PrivateRoute>
                        <PrivateRoute exact path="/uservisits/:id">
                            <UserVisitDetail />
                        </PrivateRoute>
                        <PrivateRoute exact path="/visit/aquaculture">
                            <VisitAquaCultureView />
                        </PrivateRoute>
                        <PrivateRoute exact path="/visit/poultry">
                            <VisitPoultryView />
                        </PrivateRoute>
                        <PrivateRoute exact path="/visit/porcine">
                            <VisitPorcineView />
                        </PrivateRoute>
                        <PrivateRoute exact path="/visit/cattle">
                            <VisitCattleView />
                        </PrivateRoute>
                        <PrivateRoute exact path="/visit/sheep">
                            <VisitSheepView />
                        </PrivateRoute>
                        <PrivateRoute exact path="/visit/goat">
                            <VisitGoatView />
                        </PrivateRoute>
                        <PrivateRoute exact path="/visit/horse">
                            <VisitHorseView />
                        </PrivateRoute>
                        <PrivateRoute exact path="/visit/furanimals">
                            <VisitFurAnimalsView />
                        </PrivateRoute>
                        <PrivateRoute exact path="/visit/pet">
                            <VisitPetView />
                        </PrivateRoute>
                        <PrivateRoute exact path="/admin">
                            <AdminManagementView />
                        </PrivateRoute>
                        <PrivateRoute exact path="/admin/medicine">
                            <AdminMedicineView />
                        </PrivateRoute>
                        <PrivateRoute
                            exact
                            path="/admin/medicine/medicinecreate"
                        >
                            <AddMedicine />
                        </PrivateRoute>
                        <PrivateRoute
                            exact
                            path="/uservisits/aquaculture/update"
                        >
                            <VisitAquaCultureUpdateView />
                        </PrivateRoute>
                        <AdminRoute exact path="/admin/createuser">
                            <AdminCreateUserView />
                        </AdminRoute>
                        <PrivateRoute path="/*">
                            <div></div>
                        </PrivateRoute>
                    </Switch>
                </div>
            )}
        </div>
    );
}

export default App;
