import React from "react";
import moment from "moment";
import "./AnimalBanCalendar.css";
import MomentUtils from "@date-io/moment";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";

const AnimalBanCalendar = ({
    visitDetailValues,
    label,
    valueName,
    daysType,
    daysTitle,
    disabled,
    error,
    errorMessage,
    getDateDiffCallBack
}) => {
    const handleChangeDate = (e) => {
        visitDetailValues[valueName] = moment(e._d).format("YYYY-MM-DD");
        getDateDiffCallBack(valueName, daysType);
    };
    return (
        <>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div className="calendar-selection-container">
                    <KeyboardDatePicker
                        disabled={disabled}
                        className="calendar-picker"
                        margin="normal"
                        label={label}
                        format="DD/MM/yyyy"
                        value={visitDetailValues[valueName]}
                        onChange={handleChangeDate}
                        KeyboardButtonProps={{
                            "aria-label": "change date"
                        }}
                        error={error}
                        helperText={errorMessage}
                    />
                </div>
            </MuiPickersUtilsProvider>
            <i>
                {daysTitle} {disabled ? "\u221e" : visitDetailValues[daysType]}{" "}
                dagar
            </i>
        </>
    );
};

export default AnimalBanCalendar;
