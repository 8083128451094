import React, { useState } from "react";
import { Modal, Paper } from "@material-ui/core";
import "./UpdateVisitArrangementModal.css";
import ArrangementSelection from "../../ArrangementSelection/ArrangementSelection";
import useGetAllArrangements from "../../../../hooks/useGetAllArrangements";
import UpdateVisitButtons from "../UpdateVisitButtons/UpdateVisitButtons";

const UpdateVisitArrangementModal = ({
    visit,
    open,
    handleClose,
    unhandledId
}) => {
    const [reload, setReload] = useState(false);
    const { arrangements } = useGetAllArrangements();
    const handleCancel = () => {
        visit.arrangementTypeId = unhandledId;
        visit.arrangementIsName = arrangements[unhandledId - 1].isName;
        visit.arrangementEnName = arrangements[unhandledId - 1].enName;
        handleClose();
    };
    const handleChange = (e) => {
        visit.arrangementTypeId = e.target.value;
        visit.arrangementIsName = arrangements[e.target.value - 1].isName;
        visit.arrangementEnName = arrangements[e.target.value - 1].enName;
        setReload(!reload);
    };
    const errors = { arrangementTypeId: undefined };
    return (
        <Modal
            className="update-visit-arrangement-modal"
            open={open}
            onClose={handleClose}
        >
            <Paper className="update-visit-arrangement-paper">
                <ArrangementSelection
                    values={visit}
                    handleChange={handleChange}
                    errors={errors}
                />
                <UpdateVisitButtons
                    handleClose={handleClose}
                    handleCancel={handleCancel}
                />
            </Paper>
        </Modal>
    );
};
export default UpdateVisitArrangementModal;
