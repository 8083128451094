import React from "react";

const useStepper = (submitVisit, values) => {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = (e) => {
        if (activeStep === 0) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        if (activeStep === 1) {
            if (values.diagnosesList.length > 0) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                submitVisit();
            }
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const backText = () => {
        var text = "Til Baka";
        if (activeStep === 2) {
            text = "";
        }
        return text;
    };

    const forwardText = () => {
        var text = "Áfram";
        if (activeStep === 1) {
            text = "Staðfesta";
        }
        if (activeStep === 2) {
            text = "";
        }
        return text;
    };

    return {
        activeStep,
        handleBack,
        handleNext,
        handleReset,
        backText,
        forwardText
    };
};

export default useStepper;
