const medicineGivenValidation = (values) => {
    const {
        medicineAmount,
        dateLastGiven,
        slaughterBanDays,
        milkBanDays,
        visitDate,
        isSlaughterBan,
        isMilkBan
    } = values;

    const errors = {};

    if (medicineAmount === "") {
        errors.medicineAmount = "Magn má ekki vera tómt";
    }
    if (medicineAmount <= 0) {
        errors.medicineAmount = "Magn verður að vera stærra en 0";
    }
    if (dateLastGiven) {
        if (dateLastGiven === null) {
            errors.dateLastGiven = "Veldu dagsetningu";
        }
        if (dateLastGiven < visitDate) {
            errors.dateLastGiven =
                "Síðast gefið má ekki vera fyr en Dagsetning vitjunar";
        }
    }
    if (isSlaughterBan) {
        if (slaughterBanDays < 0) {
            errors.slaughterBanDate =
                "Sláturbann má ekki vera styttra en síðasta lyfjagjöf";
        }
    }
    if (isMilkBan) {
        if (milkBanDays < 0) {
            errors.milkBanDate =
                "Mjólkurbann má ekki vera styttra en síðasta lyfjagjöf";
        }
    }

    return errors;
};

export default medicineGivenValidation;
