import React, { useState } from "react";
import useStepper from "../../../hooks/useStepper";
import VisitStepperView from "../../VisitStepper/VisitStepperView/VisitStepperView";
import useWindowSize from "../../../hooks/useWindowSize";
import useForm from "../../../hooks/useForm";
import VisitInfoView from "../../Visit/VisitInfoView/VisitInfoView";
import visitInfoValidation from "../../../validations/visitInfoValidation";
import useCreateVisit from "../../../hooks/useCreateVisit";
import { useSelector } from "react-redux";
import "./VisitPoultryView.css";
import VisitPoultryDiagnosesContainer from "../VisitPoultryDiagnosesContainer/VisitPoultryDiagnosesContainer";
import VisitPoultryConfirmedInfo from "../VisitPoultryConfirmedInfo/VisitPoultryConfirmedInfo";
import useGetAllPoultries from "../../../hooks/useGetAllPoultries";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category PoultryVisit
 * @component holds all the components for user to create a poultry visit
 * @param visitInitState is an object for the general visit
 */

const visitInitState = {
    arrangementTypeId: null,
    establishmentId: null,
    establishmentSSN: null,
    animalTypeId: 2,
    veterinarianId: null,
    veterinarianSSN: null,
    visitDate: null,
    establishmentName: null,
    establishmentAreas: null,
    diagnosesList: []
};

const VisitPoultryView = () => {
    /**
     * Hook that gets user's current window size
     */
    const windowSize = useWindowSize();
    /**
     * Boolean which reads user's screen size and returns true if screen size
     * is less than or equal to 690px (standard screen size in 2021)
     */
    const isPhone = windowSize.width < 690;
    /**
     * User information from redux store
     */
    const user = useSelector((state) => state.user);
    /**
     * React useState hook that sets in the submit callback
     */
    const [
        visitObjectForConfirmation,
        setvisitObjectForConfirmation
    ] = useState(null);

    const submitFirstStep = () => {
        handleNext();
    };
    /**
     * Hook that keeps track of when user inserts information about visit
     */
    const { handleSubmit, handleChange, values, errors, resetFields } = useForm(
        visitInitState,
        visitInfoValidation,
        submitFirstStep
    );
    /**
     * The submitCallback function is a call back that gets called inside the
     * useCreateVisit hook. Creates a object from the values object en clears up.
     */
    const submitCallback = (id) => {
        const objectForConfirm = {
            visitId: id,
            visitDate: values.visitDate,
            establishmentName: values.establishmentName,
            diagnosesCount: values.diagnosesList.length
        };
        setvisitObjectForConfirmation(objectForConfirm);
        resetFields();
        values.diagnosesList = [];
        visitInitState.diagnosesList = [];
    };
    /**
     * Create visit hook
     */
    const { handleCreateVisit } = useCreateVisit(submitCallback);
    /**
     * Called inside the useStepper where user confirms to next step
     */
    const submitVisit = () => {
        values.veterinarianId = user.id;
        values.veterinarianSSN = user.username;
        handleCreateVisit(values);
    };
    const {
        activeStep,
        handleBack,
        handleNext,
        forwardText,
        backText
    } = useStepper(submitVisit, values);
    /**
     * Hook that gets all poultry establishments from the database
     */
    const { poultries, isLoading } = useGetAllPoultries();
    return (
        <div className="visit-poultry-container">
            <div className="poultry-selection-container">
                {activeStep === 0 && (
                    <VisitInfoView
                        values={values}
                        handleChange={handleChange}
                        establishments={poultries}
                        handleSubmit={handleSubmit}
                        errors={errors}
                        isLoading={isLoading}
                        establishmentHasType={false}
                    />
                )}
                {activeStep === 1 && (
                    <div className="poultry-diagnoses-container">
                        <VisitPoultryDiagnosesContainer visitValues={values} />
                    </div>
                )}
                {activeStep === 2 && (
                    <div className="poultry-confirmed-container">
                        <VisitPoultryConfirmedInfo
                            visit={visitObjectForConfirmation}
                        />
                    </div>
                )}
            </div>
            <div className="poultry-stepper-container">
                <VisitStepperView
                    handleSubmit={handleSubmit}
                    activeStep={activeStep}
                    handleBack={handleBack}
                    isPhone={isPhone}
                    forwardText={forwardText}
                    backText={backText}
                />
            </div>
        </div>
    );
};

export default VisitPoultryView;
